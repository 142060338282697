import React, { useEffect, useMemo, useRef, useState } from "react"
import UploadKdfModal from "../../../Modals/UploadKdfModal";
import KDFSettingsPageListing from "./KDFSettingsPageListing";
import noImage from '../../../../../assets/images/no-image.png';
import Button from "../../../Elements/Button";
import Input from "../../../Elements/Input";
import apiGETRequest, { apiPostRequest } from "../../../../utis/apiRequest";
import { csrfToken } from "../../../../utis/csrfTokenUtils";
import CommonModal from "../../../Modals/CommonModal";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faFloppyDisk, faRectangleXmark, faQuestion } from '@fortawesome/free-solid-svg-icons';
import './KdfSettings.css';
import utilsUploadFormData from "../../../../utis/upoadFormData";
import customAPIRequest from "../../../../utis/customAPIRequest";
import CustomTooltip from "../../../Elements/Tooltip";
import { InputTags } from "react-bootstrap-tagsinput";
import CustomTags from "../../../Elements/CustomTags";
import { networkService } from "../../../config/axios";

const KdfSettings = ({ docs }) => {
    // const [openUploadModal, setOpenUploadModal] = useState(docs?.doc_files_languages?.length == 0)

    const settingsNoteRef = useRef(null);
    const [uploadPDFModalStatus, setUploadPDFModalStatus] = useState(false);

    // const [engagementThresholdEditStatus, setEngagementThresholdEditStatus] = useState(false);
    // const [engagementThresholdEditRecord, setEngagementThresholdEditRecord] = useState(0);

    // const [settingsNotesEditStatus, setSettingsNotesEditStatus] = useState(false);
    // const [settingsNotesEditRecord, setSettingsNotesEditRecord] = useState('');

    const [docSettingsData, setDocSettingsData] = useState(
        {
            engagementThreshold : {
                status:false,
                value: 0
            },
            notes : {
                status:false,
                value: ''
            }
        }
    );



    const [deletePDFActiveState, setDeletePDFActiveState] = useState(null);
    const [privatePDFActiveState, setPrivatePDFActiveState] = useState(null);
    const [replaceSelectedPDFState, setReplaceSelectedPDFState] = useState(null);

    const [kdfsTagsEditStatus, setKdfsTagsEditStatus] = useState(false);
    
    // const [privateFlag, setPrivateFlag] = useState(false);


    const handleCancelModalEvent = (status) => {
        if(status == false && docUploadData.pdfList?.length == 0){
            window.open('/kdfs', '_self');
        }
    }
    const [imageThumb, setImageThumb] = useState(docs?.thumb != "" ? (docs?.thumb != undefined ? docs?.thumb : noImage) :noImage);
    // const imageThumb = docs?.thumb != "" ? (docs?.thumb != undefined ? docs?.thumb : noImage) :noImage; 
    const [docUploadData, setDocUploadData] = useState({"image": imageThumb, 'active': false, 'pdfList': JSON.parse(JSON.stringify(docs)).doc_files_languages, "docData": {} });
    const updateDocSettings = (dataGet) => {
        let docSettingsDataCopy = JSON.parse(JSON.stringify(docSettingsData));
        docSettingsDataCopy.engagementThreshold.value = dataGet.page_viewed_sec ?? 0;
        docSettingsDataCopy.notes.value = dataGet.note ?? '';
        setDocSettingsData({...docSettingsDataCopy});
    }
    const updateDocUploadData = async (status = null) => {        
        try{
            let docUploadDataCopy = JSON.parse(JSON.stringify(docUploadData));
            let fetchUploadData = await apiGETRequest(`docs/${docs.id}/files.json`);
            if(status == 'mount' && docs?.doc_files_languages?.length == docs?.count_active_files){
                let fetchDocData = await apiGETRequest(`kdfs/${docs.id}.json`);
                docUploadDataCopy.active = true;
                if(fetchDocData?.id != undefined){
                    docUploadDataCopy.docData = fetchDocData;
                }
            }
            else if(status == 'update'){
                if(fetchUploadData[0].active == true){
                    let fetchDocData = await apiGETRequest(`kdfs/${docs.id}.json`);
                    setImageThumb(fetchDocData.thumb);
                    if(fetchDocData?.id != undefined){
                        docUploadDataCopy.docData = fetchDocData;
                    }
                }
                setReplaceSelectedPDFState(null);
            }
            if(fetchUploadData?.error == undefined && Array.isArray(fetchUploadData)){
                if(fetchUploadData?.filter(pdf => pdf.active == false)?.length > 0){
                    setTimeout(() => {
                        updateDocUploadData('update');
                    },3000);
                    // return "";
                }else {
                    docUploadDataCopy.active = true;
                }
            }else {
                setTimeout(() => {
                    updateDocUploadData('update');
                },3000);
                docUploadDataCopy.active = true;
            }
            if(fetchUploadData?.error == undefined){
                if(fetchUploadData?.length == 0){
                    
                }
                docUploadDataCopy.pdfList = fetchUploadData;
            }        
            setDocUploadData(docUploadDataCopy);
            // return "";
        }catch(error) {
            console.warn('Error: ', error);
        }
    }
    useEffect(() => {
        updateDocSettings(docUploadData.docData);
    },[docUploadData])
    const handleUpdateDocData = async (data, type = null) => {
        try{
            let dataToUpdate = {};
            if(type != null){
                dataToUpdate = {doc:{[type]:data}};
            }else {
                dataToUpdate = data;
            }
            let uploadData = await utilsUploadFormData(`kdfs/${docs.id}.json`,dataToUpdate, 'PATCH');
            if(uploadData?.data != undefined){
                if(type == 'note'){
                    setDocSettingsData(prevState => {return {...prevState, notes : {...prevState.notes, value: uploadData.data.note, status: false}}})
                }else if(type == 'page_viewed_sec'){
                    setDocSettingsData(prevState => {return {...prevState, engagementThreshold : {...prevState.engagementThreshold, value: uploadData.data.page_viewed_sec}}})
                }
                setDocUploadData({ ...docUploadData, 'docData': uploadData.data });
            }
        }catch(error) {
            console.warn(error);
        }
    }
    const handleUpdateEngagementData = () => {
        handleUpdateDocData(docSettingsData.engagementThreshold.value, 'page_viewed_sec');
        setDocSettingsData(prevState => {return {...prevState, engagementThreshold : {...prevState.engagementThreshold, status: false}}});
    }

    function outsideOfSettingsNote(event) {
        if(event.target != settingsNoteRef.current){
            document.removeEventListener('click',outsideOfSettingsNote, true);
            handleUpdateDocData(settingsNoteRef.current.value, 'note');
            // setSettingsNotesEditStatus(false);
            // setDocSettingsData(prevState => {return {...prevState, notes : {...prevState.notes, status: false}}})
        }
    }
    const handleSettingsNoteEvent = (noteStatus) => {
        if(noteStatus){
            document.addEventListener('click', outsideOfSettingsNote, true);
        }
        // setSettingsNotesEditStatus(status)
        setDocSettingsData(prevState => {return {...prevState, notes : {...prevState.notes, status: noteStatus}}})
    }

    // const handeDocPrivateDataEvent = () => {
    //     handleUpdateDocData({"doc_id": docs.id,"private_flag": docUploadData.docData.private_flag});
    // }

    const handeAddKDFTagsEvent = async (value) => {
        // utilsUploadFormData(`kdfs/${docs.id}.json`,dataToUpdate, 'PATCH');
        if(value.length > docUploadData.docData?.tags.length){
            let updateTagsToDoc = await utilsUploadFormData(`tags.json`, {'name':value[value.length -1]}, 'POST');
            if(updateTagsToDoc?.data != undefined){
                handleUpdateDocData([...docUploadData.docData?.tags?.map(item => item.id),updateTagsToDoc.data.id ],'tag_ids');
            }
        }else {
            handleUpdateDocData(docUploadData.docData?.tags.filter(item => value.includes(item.name))?.map(item => item.id),'tag_ids');
        }
        // updateDocUploadData('refresh');
    }
    const handlePDFStatusEventTrigger = async () => {
        let recordActionData;
        if(deletePDFActiveState != null){
            recordActionData = await customAPIRequest(`docs/${docs.id}/files/${deletePDFActiveState}.json`, 'DELETE', {});
            if(recordActionData?.data != undefined){
                updateDocUploadData();
            }
            setDeletePDFActiveState(null);
        }else if(privatePDFActiveState != null){
            handleUpdateDocData({"doc_id": docs.id,"private_flag": docUploadData.docData.private_flag});
            setPrivatePDFActiveState(null);
        }
    }

    useEffect(() => {
        updateDocUploadData('mount');
        return () => {
            setUploadPDFModalStatus(false);
        }
    },[])
    


    const shouldDocUdatePDFModal = useMemo(()=> {
        return docUploadData.pdfList?.length == 0
    },[docUploadData])
    const uploadModalRef = useRef(null);
    const uploadModalButtonRef = useRef(null);

    useEffect(() => {
        if (shouldDocUdatePDFModal && !uploadPDFModalStatus && docUploadData.active == false) {
            if (uploadModalRef.current) {
                clearTimeout(uploadModalRef.current);
            }
            const timer = setTimeout(() => {
                if (!uploadPDFModalStatus) {
                    uploadModalButtonRef.current.click();
                }
            }, 1000);
            uploadModalRef.current = timer;
        }

        return () => {
            if (uploadModalRef.current) {
                clearTimeout(uploadModalRef.current);
                uploadModalRef.current = null;
            }
        };
    }, [shouldDocUdatePDFModal, uploadPDFModalStatus]);

    const handleReplaceWithDialogTrigger = (itemID = null) => {
        if(itemID != null){
            setReplaceSelectedPDFState(itemID);
            setUploadPDFModalStatus(true);
        }
    }

    const updateKDFData = async (data, pdfID) => {        
        let updateRequest = await networkService(`docs/${docs.id}/files/${pdfID}.json`, 'PATCH', {docs_file:data});
        setDocUploadData(prevData => ({ ...prevData, 'pdfList': prevData.pdfList.map(pdfItem => pdfItem.id == updateRequest.data.id ? {...updateRequest.data} : {...pdfItem})}));
    }

    return (
        <>
            <CommonModal
                title={'Add a PDF To Your Kinetizine Account'}
                modalStatus={uploadPDFModalStatus}
                changeActiveStatus={(status) => {setUploadPDFModalStatus(status); handleCancelModalEvent(status); setReplaceSelectedPDFState(null);}}
                centered
                classList={'upload-kdf-modal-wrapper'}
            >
                <UploadKdfModal
                    refreshPDFList={(status) => {status && updateDocUploadData('update')}} 
                    closeModal={setUploadPDFModalStatus}
                    userId={docs?.user?.id} 
                    docId={docs?.id}
                    selectedFileID={replaceSelectedPDFState}
                />
            </CommonModal>
            <CommonModal 
                title={deletePDFActiveState != null ? 'Delete File' : ''}
                modalStatus={deletePDFActiveState != null || privatePDFActiveState != null}
                changeActiveStatus={() => {setDeletePDFActiveState(null); setPrivatePDFActiveState(null)}}
                centered
                classList={'upload-kdf-modal-wrapper alert-modal'}
                footer={
                    <div className="d-flex m-auto">
                        <Button text={'No'} classList={'alert delete-btn'} addonClass={'my-2 mx-2'} onClick={() => {setDeletePDFActiveState(null); setPrivatePDFActiveState(null)}} />
                        <Button text={'Yes'} classList={'success delete-btn'} addonClass={'my-2 mx-2'} onClick={handlePDFStatusEventTrigger} />
                    </div>
                }
            >
                <p style={{fontSize:'14px'}}>
                    {   
                        deletePDFActiveState != null ? 
                        'Are you sure you want to delete this file? This action cannot be undone.' 
                        :
                        ( docUploadData.docData.private_flag ? 'Make this KDF available to all users of this kinetizine account?' : 'Want to set this KDF to PRIVATE? This setting will hide this KDF from other kinetizine users within your organization but your recipients will still be able to view it via the KDF Link(s) you share with them.')
                    }
                </p>
            </CommonModal>
            {/* kdf-setting-main row */}
            <div className="container kdf-settings-wrapper m-0">
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col md={3} className="text-center">
                                    <img src={imageThumb} alt="" />
                                    <p onClick={() => setUploadPDFModalStatus(true)} ref={uploadModalButtonRef} ></p>
                                    {
                                       (docUploadData?.pdfList?.filter(pdf => (pdf.status == 'processing'))?.length > 0) ?
                                            <Button text={'Processing...'} classList={'processing'} addonClass={'my-2'} />
                                        : 
                                            <Button text={'Edit KDF'} classList={`success`} addonClass={'my-2'} onClick={() => docUploadData?.pdfList?.length > 0 && window.open(`/editor/${docs?.id}`,'_blank') } />

                                    }
                            </Col>  
                            <Col md={9}>
                                <Row>
                                    <Col md={12} className="kdf-content-wrapper">
                                        <p><span>Title: </span><strong>{docs?.title}</strong></p>
                                        <p><span>Created by: </span>{docs.current_user?.name || 'Name'}</p>
                                        <p><span>Last Modified by: </span>{docs.modifier ?? '-'}</p>
                                        <div className="custom-tag-wrapper" onClick={() => !kdfsTagsEditStatus && setKdfsTagsEditStatus(true)}>
                                            {
                                                kdfsTagsEditStatus ? 
                                                <CustomTags 
                                                    updateValue={handeAddKDFTagsEvent}
                                                    tags={docUploadData.docData?.tags}
                                                    handleCancelEvent={(status) => setKdfsTagsEditStatus(!status)}
                                                />
                                                :
                                                (
                                                    docUploadData.docData?.tags?.length > 0 ?
                                                    docUploadData.docData?.tags?.map((tag, tagInd) => <span key={tagInd} className="tag-primary text-white mx-1 rounded">{tag.name}</span>)
                                                    :
                                                    <span className="d-block custom-tag-element">Add KDF Tags here</span>
                                                )
                                            }
                                        </div>
                                        <p>
                                            <CustomTooltip 
                                                title="Minimum viewing time before a page is considered viewed"
                                            >
                                                <span>Engagement Threshold: </span>
                                            </CustomTooltip>
                                            {
                                                !docSettingsData.engagementThreshold.status ? 
                                                <span className="edit-box" onClick={() => setDocSettingsData(prevState => {return {...prevState, engagementThreshold : {...prevState.engagementThreshold, status: true}}})}>{docSettingsData.engagementThreshold.value}</span>
                                                :
                                                <>
                                                    <input className="edit-box-input" type="number" value={docSettingsData.engagementThreshold.value} onChange={(e) => setDocSettingsData(prevState => {return {...prevState, engagementThreshold : {...prevState.engagementThreshold, value: e.target.value}}})} />
                                                    <CustomTooltip 
                                                        title="Save Changes"
                                                    >
                                                        <FontAwesomeIcon 
                                                            icon={faFloppyDisk} 
                                                            onClick={handleUpdateEngagementData}
                                                            className="active mx-2 edit-icon"
                                                        />
                                                    </CustomTooltip>
                                                    <CustomTooltip 
                                                        title="Cancel"
                                                    >    
                                                        <FontAwesomeIcon 
                                                            icon={faRectangleXmark} 
                                                            onClick={() => {
                                                                setDocSettingsData(prevState => {return {...prevState, engagementThreshold : {...prevState.engagementThreshold, status: false, value: docUploadData.docData.page_viewed_sec}}});
                                                            }}
                                                            className="cancel mx-2 edit-icon"
                                                        />
                                                    </CustomTooltip>
                                                </>
                                            }
                                            <span> seconds</span>
                                        </p>
                                        <Form.Check 
                                            type="switch"
                                            id="custom-switch"
                                            label="Private" 
                                            className="d-inline-block"
                                            checked={docUploadData?.docData?.private_flag != undefined ? docUploadData?.docData?.private_flag : false}
                                            onChange={() => setPrivatePDFActiveState(docs.id)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <p><span className="notes-kdf">Notes:</span></p>
                        {
                            !docSettingsData.notes.status ?
                            <>
                                <span className="color-blue">{docSettingsData.notes.value}</span>
                                <FontAwesomeIcon icon={faPencil} className="active mx-2" style={{cursor:'pointer'}} onClick={()=> handleSettingsNoteEvent(true)} />
                            </>
                            :
                            <Form.Control
                                as="textarea"
                                placeholder=""
                                style={{ height: '54px' }}
                                value={docSettingsData.notes.value}
                                onChange={(e) => setDocSettingsData(prevState => {return {...prevState, notes : {...prevState.notes, value: e.target.value}}})}
                                ref={settingsNoteRef}
                            />
                        }
                    </Col>
                    
                </Row>
            </div>
            <Container className="m-0">
                <KDFSettingsPageListing 
                    PdfList={Array.isArray(docUploadData.pdfList) ? docUploadData.pdfList : docUploadData.docData.doc_files_languages} 
                    handleUploadPDFModalStatus={(status) => setUploadPDFModalStatus(status)} 
                    docs={docs} 
                    handleSetReplaceWith={handleReplaceWithDialogTrigger}
                    handleDeletePDFEvent={setDeletePDFActiveState}
                    handleUpdateDocStatus={updateKDFData}
                />
                
            </Container>

        </>
    )
}
export default React.memo(KdfSettings)