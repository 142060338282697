import React, { useRef, useEffect } from 'react';

const CanvasComponent = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Example drawing code
    context.fillStyle = 'blue';
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.strokeStyle = 'white';
    context.lineWidth = 5;
    context.beginPath();
    context.moveTo(50, 50);
    context.lineTo(200, 200);
    context.stroke();
  }, []);

  return (
    <canvas
      ref={canvasRef}
      width={500}
      height={500}
      style={{ border: '1px solid black' }}
    />
  );
};

export default CanvasComponent;
