import React from "react";
import Breadcrumb from "../../CommonComponents/BreadCrumb/BreadCrumb";
const AdminCustomisationLogPage = () => {
    return (
        <>
            <Breadcrumb string={'Admin/Customization Logs'} />
            <div className="container">
                <table className="table-main">
                    <tr>
                        <th>User Name</th>
                        <th>Message</th>
                        <th>Approved</th>
                    </tr>
                    <tr>
                        <td>Ted Box</td>
                        <td>I'd like you to accept the use of ALL COOKIES please.</td>
                        <td>
                            <button className="custo-rej">Rejected</button>
                        </td>
                    </tr>
                    <tr>
                        <td>Shivam2 Kumar</td>
                        <td>I'd like to replace the default consent pop-up with one or more of the following: our own text, our own link to our cookie policy, and our image.</td>
                        <td>
                            <button className="custo-rej">Rejected</button>
                        </td>
                    </tr>
                    <tr>
                        <td>Shivam2 Kumar</td>
                        <td>I'd like to replace the default consent pop-up with one or more of the following: our own text, our own link to our cookie policy, and our image.</td>
                        <td>
                            <button className="custo-rej">Rejected</button>
                        </td>
                    </tr> <tr>
                        <td>Shivam2 Kumar</td>
                        <td>I'd like to replace the default consent pop-up with one or more of the following: our own text, our own link to our cookie policy, and our image.</td>
                        <td>
                            <button className="custo-rej">Rejected</button>
                        </td>
                    </tr> <tr>
                        <td>Shivam2 Kumar</td>
                        <td>I'd like to replace the default consent pop-up with one or more of the following: our own text, our own link to our cookie policy, and our image.</td>
                        <td>
                            <button className="custo-rej">Rejected</button>
                        </td>
                    </tr> <tr>
                        <td>Shivam2 Kumar</td>
                        <td>I'd like to replace the default consent pop-up with one or more of the following: our own text, our own link to our cookie policy, and our image.</td>
                        <td>
                            <button className="custo-rej">Rejected</button>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
}
export default AdminCustomisationLogPage;
