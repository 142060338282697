import React, { useEffect, useState } from "react";
import AddOrganisation from "../../Modals/AddOrganisationModal";
import SearchComponent from "../../CommonComponents/SearchComponent/SearchComponent";
import DataPerPage from "../../CommonComponents/Pagination/DataPerPage";
import Breadcrumb from "../../CommonComponents/BreadCrumb/BreadCrumb";
import { networkService } from "../../config/axios";

const Organizations = () => {
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    const fetchOrgs = async () => {
        const response = await networkService('admin/orgs.json', 'GET');
        setOrgs([...response.data])
    }
    fetchOrgs();
  }, []);

  return (
    <>
      {openOrgModal && (
        <AddOrganisation
          openOrgModal={openOrgModal}
          setOpenOrgModal={setOpenOrgModal}
        />
      )}
      <Breadcrumb string={"Admin/Organizations"} />
      <div style={{width: "1440px"}}className="container">
        <div className="show-select1 d-flex ">
          <DataPerPage />
          <SearchComponent />
        </div>
        <div className="top-parent">
          <table className="Subscription-table mt-4">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>trial</th>
                <th className="hov-clr">Last Login</th>
                <th className="hov-clr">Date Time</th>
                <th className="hov-clr">Users</th>
                <th className="hov-clr">Banned Users</th>
                <th>
                  <button
                    className="add-sub org-btn"
                    onClick={() => {
                      setOpenOrgModal(true);
                    }}
                  >
                    {" "}
                    <i className="fa-solid fa-building me-2"></i> Add
                    Organization
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
                {orgs.map((org, index) => (
                    <tr key={index}>
                        <td>
                                {org.name} <i className="fa-solid fa-circle-info"></i> 
                        </td>
                        <td>
                            {org.type_name} <i className="fa-solid fa-circle-info"></i>
                            <i className="fa-solid fa-envelope ms-2"></i>
                            
                        </td>
                        <td>{org.trial} Days</td>
                        <td className="hov-clr">{org.last_signed_username}</td>
                        <td className="hov-clr">{org.last_signed_time}</td>
                        <td className="hov-clr">
                            <i className="fa-solid fa-users me-2"></i>{org.num_users} User{org.num_users > 1 ? 's' : ''}{org.num_online_users > 0 && <span>{org.num_online_users} User{org.num_online_users > 1 ? 's' : ''} Online</span>}
                        </td>
                        <td className="hov-clr">
                            <i className="fa-solid fa-user-slash me-2"></i> {org.num_banned_users} Banned User{org.num_banned_users > 1 ? 's' : ''}
                        </td>
                        <td className="hov-clr org-icon">
                            <a href="#">
                                <i className="fa-solid fa-pen me-2"></i>
                            </a>
                            <a href="#">
                                <i className="fa-solid fa-envelope me-2"></i>
                            </a>
                            <a href="#">
                                <i className="fa-solid fa-coins me-2"></i>
                            </a>
                            <a href="#">
                            <i class="fa-solid fa-trash-can"></i>
                            </a>
                        </td>
                    </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="showing-main d-flex justify-content-between mt-3">
          <div className="showing-para">
            <p>Showing 1 to 2 of 2 entries</p>
          </div>
          <div>
            <button className="previous-btn me-2">Previous</button>
            <span className="count-btn me-2">1</span>
            <button className="previous-btn">Next</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organizations;
