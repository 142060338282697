import React from 'react';
const Element = ({ elements }) => {
    const renderElement = (element, itemKey) => {

        if (element?.element_type_name?.toLowerCase() == 'image') {
            return (
                <img
                    key={itemKey}
                    src={element?.file_url ?? element?.value?.gif_link}
                    alt=""
                    style={{
                        height: `${element?.file_details?.dimensions?.height ?? 488}px`,
                        width: `${element?.file_details?.dimensions?.width ?? 488}px`,
                    }}
                />
            );
        } else if (element?.element_type_name?.toLowerCase() == 'video' || element?.element_type_name?.toLowerCase() === "web url") {
            return (
                <iframe
                    key={itemKey}
                    frameborder="0"
                    allowfullscreen=""
                    allow="autoplay; fullscreen"
                    style={{
                        height: `${element?.geometry?.height ?? 300}px`,
                        width: `${element?.geometry?.width ?? 300}px`,
                    }}
                    src={element?.value?.video_link}
                    title="url">
                </iframe>
            );
        } else if (element?.element_type_name?.toLowerCase() == 'html text') {
            return <div className='bg-white' style={{ width: '300px', height: '300px' }} dangerouslySetInnerHTML={{ __html: element.value.html_text }} />
        }
        return "Not an Element";
    }
    return (
        <>
            {
                Object.keys(elements)?.map((elementKey, itemKey) => renderElement(elements[elementKey], itemKey))
            }
        </>
    )
}
export default Element;