import React, { useContext } from "react";
import CommonModal from "../CommonModal";
import { EditorInfoContext } from "../../../utis/userInfoContext";
import { networkService } from "../../config/axios";
import useEditorHook from "../../Pages/Kdf/EditKdf/editorHook/useEditorHook";

const DeleteElementModal = ({ openDeleteModal, closeModal, elemId }) => {
    const { setElements, toolType, lightBoxList, setLightBoxList } = useContext(EditorInfoContext)
    const { setTool } = useEditorHook()

    const handleDeleteElement = async (elemId) => {
        if (toolType === 'LightboxesToolbox') {
            await networkService(`docs/lightboxes/${elemId}.json`, 'DELETE');
            closeModal(false)
            setTool({})
            setLightBoxList(lightBoxList.filter(element => element.id !== elemId))
        } else {
            await networkService(`docs/elements/${elemId}.json`, 'DELETE');
            setElements(prevElements => {
                const { [elemId]: _, ...newElements } = prevElements;
                return newElements;
            });
            closeModal(false)
        }
    }

    return (
        <CommonModal
            title={"Remove Element"}
            modalStatus={openDeleteModal}
            changeActiveStatus={closeModal}
            classList="header-profile-modal-wrapper"
            centered
        >
            <div>
                <p className="mb-2">Are you sure you want to delete this ${toolType === 'LightboxesToolbox' ? 'Lightbox' : 'Element'}?</p>
                <div className="kdf-btn mt-3">
                    <button className="continue-btn"
                        onClick={() => handleDeleteElement(elemId)}
                    >
                        Yes
                    </button>
                </div>
            </div>
        </CommonModal>
    )
}
export default DeleteElementModal