import React, { useState } from "react";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import rotate_icon from "../../../../../../../../assets/images/rotate_icon.svg";
import rotate_90_icon from "../../../../../../../../assets/images/rotate_90_icon.svg";
import flip_icon from "../../../../../../../../assets/images/flip_icon.svg";

const RotateSection = ({tool, setTool}) => {
  const handleRotation = (value, type) => {
    if (type === "rotate") {
      setTool({
        ...tool,
        rotate: (tool?.rotate ?? 0) + value,
      });
    } else {
      setTool({
        ...tool,
        flip: tool?.flip === 1 ? -1 : 1,
      });
    }
  };
  return (
    <>
      <div className="transparency-section-flip row">
        <div className="col-6">
          <p>ROTATE AND FLIP</p>
        </div>
        <div className="col-6 d-flex rotate-flip-icon">
          <div className="d-flex align-items-center">
            <p>Rotate:</p>
            <img
              className="me-2"
              src={rotate_icon}
              alt="tenor logo"
              height="20px"
              onClick={() => handleRotation(45, "rotate")}
            />
            <img
              src={rotate_90_icon}
              alt="tenor logo"
              height="20px"
              onClick={() => handleRotation(90, "rotate")}
            />
          </div>
          <div className="d-flex align-items-center">
            <p>Flip:</p>
            <img
              src={flip_icon}
              alt="tenor logo"
              height="20px"
              onClick={() => handleRotation(-1, "flip")}
            />
          </div>
          {/* <div className="rotate45degree"></div> */}
        </div>
      </div>
    </>
  );
};
export default RotateSection;