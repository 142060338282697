import React, { useEffect } from "react";
import Kinetizine from '../../../../../assets/images/Kinetizine.png';
import Button from "../../../Elements/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faEye, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Table } from "react-bootstrap";

const KDFSettingsPageListing = ({ PdfList, handleUploadPDFModalStatus, docs, handleSetReplaceWith, handleDeletePDFEvent, handleUpdateDocStatus }) => {
    const handlePDFUpdateStatus = (e, fileID) => {
        handleUpdateDocStatus({status: e.target.value, switch_active: true}, fileID)
    }
    return (
        <>
            <div className="Language-Editions-main">
                <div className="setting-table">
                    <h6 className="text-start">Language Editions</h6>
                    <Table className="kdf-set-table" bordered>
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>Language</th>
                                <th>Date Added / Updated</th>
                                <th style={{width:'280px'}}>Status</th>
                                {/* <th></th> */}
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                PdfList?.map((pdf, index) => {
                                    return (
                                            <tr key={index}>
                                                <td title="FASTEST LOADING">
                                                    {pdf?.title}
                                                </td>
                                                <td>
                                                {/* <img src={Kinetizine} alt="" /> {pdf?.language?.name ?? 'English (US)'} */}
                                                {/* <img src={`assets/countries/${pdf?.language?.locale?.split('-')[1]}.png`} alt="" /> {pdf?.language?.name ?? 'English (US)'} */}
                                                <img src={`/assets/countries/${(pdf.language.locale.includes('-') ? pdf.language.locale.toLowerCase().split('-')[1] : pdf.language.locale) ?? 'en'}.png`} alt={pdf?.language.name} width='22px' /> {pdf?.language?.name ?? 'English (US)'}
                                                </td>
                                                <td title={pdf?.date_tooltip} >
                                                    {pdf?.updated_date ?? ''}
                                                </td>
                                                <td>
                                                    {
                                                        pdf?.show_switch_to_active &&
                                                        <span className="d-flex justify-content-between">
                                                            <select id="mySelect" onChange={(e) => handlePDFUpdateStatus(e,pdf.id)} value={pdf.status}>
                                                                <option value={'active'}>Ready</option>
                                                                <option value={'inactive'}>Edit Only</option>
                                                                <option value={'offline'}>Offline</option>
                                                            </select>
                                                            <u><span className="setting-link ml-2" onClick={() => handleSetReplaceWith(pdf.id)} >Replace PDF</span></u>
                                                        </span>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        pdf?.show_switch_to_active && 
                                                        <FontAwesomeIcon icon={faPencil} className="" onClick={()=> {window.open(`/editor/${docs?.id}`, '_blank')}} />
                                                    }    
                                                </td>
                                                <td>
                                                    {
                                                        pdf?.show_switch_to_active && 
                                                        <FontAwesomeIcon icon={faEye} onClick={()=> {window.open(`/editor/${docs?.id}`, '_blank')}} />
                                                    }    
                                                </td>
                                                <td>
                                                    {
                                                        pdf?.show_switch_to_active && 
                                                        <FontAwesomeIcon icon={faTrashCan} className="cancel" onClick={() => handleDeletePDFEvent(pdf.id)}/>
                                                    }        
                                                </td>
                                            </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
                <div className="add-lang-btn mt-5">
                    {/* <button>
                        Add Language
                    </button> */}
                    <Button 
                        disabled={docs?.doc_files_languages?.length == 0} 
                        onClick={() => handleUploadPDFModalStatus(true)} 
                        text={'Add Language'} 
                        addonClass={'rounded-0'}
                    />
                </div>
            </div>
        </>
    )
}
export default KDFSettingsPageListing
