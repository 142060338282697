import React, { useContext, useEffect, useState } from "react";
import { networkService } from "../components/config/axios";
import UserInfoContext from "./userInfoContext";
const useGetUserProfile = () => {
    const [userProfile, setUserProfile] = useState({});
    const { profileData
    } = useContext(UserInfoContext);
    
    const udpateUserProfile = async () => {
        let { data } = await networkService(`profiles/${profileData.id}.json`);
        setUserProfile(data);
    }
    useEffect(() => {
        if(profileData?.id != undefined){
            udpateUserProfile();
        }
    },[profileData])
    return userProfile;
}
export default useGetUserProfile;