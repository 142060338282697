import React from "react";
const alphaCharacters = 'abcdefghijklmnopqrstuvwzyx';
const getRandomString = (limit = 5, type = null) => {
    let alpha = type != null ? type : alphaCharacters;
    let result = '';
    for(let a = 0;a < limit; a++){
        let randomString = Math.round(Math.random() * 100).toString();
        result += alpha.split('')[`${randomString <= 26 ? 
            randomString : 
            (Number(randomString[0]) + Number(randomString[1]))}`];
    }
    return result;
}
export default getRandomString;