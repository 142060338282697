import React, { useEffect } from "react"
import Button from "../../../../../../Elements/Button"
const HotspotSection = ({ tool, setTool, uploadElement }) => {

    useEffect(() => {
        setTool({
            ...tool,
            element_type: '9',
        })
    }, [])

    return (
        <div className="text-end">
            <Button
                classList='success'
                addonClass='me-2'
                text='Save'
                onClick={(e) => uploadElement(e)}
            />
        </div>
    )
}
export default HotspotSection