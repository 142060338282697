import React from "react";
import Breadcrumb from "../../CommonComponents/BreadCrumb/BreadCrumb";
const AdminPotentialBotsPage = () => {
    return (
        <>
            <Breadcrumb string={'Admin/Potential Bots'} />
            <div className="container">
                <div className="show-select1 d-flex ">
                    <div className="d-flex align-items-center">
                        <p>Show</p>
                        <div className="custom-select-container me-2 ms-2 ali">
                            {/* <!-- <label htmlFor="custom-select">Choose an option:</label> --> */}
                            <select id="custom-select" className="custom-select">
                                <option value="option1"> 10</option>
                                <option value="option2"> 25</option>
                                <option value="option3"> 50</option>
                                <option value="option4"> 100</option>
                            </select>
                        </div>
                        <p>entries</p>
                    </div>

                    <div className="Search-inp">
                        <div>
                            <label>
                                Search :
                            </label>
                            <input placeholder="Search..." />
                        </div>
                    </div>
                </div>
                <div className="top-parent">
                    <table className="Subscription-table mt-4">
                        <tr>
                            <th>
                                IP Address
                            </th>
                            <th>
                                Users
                            </th>
                            <th>
                                Counts
                            </th>
                            <th className="hov-clr">
                                Confirm
                            </th>
                            <th className="hov-clr">
                                Reject
                            </th>
                            <th>
                                <button className="add-sub"><a href="/admin/potential_bots/new">Add Bot</a></button>
                            </th>
                        </tr>
                        <tr>
                            <td className="info">
                                20.169.168.224 <i className="fa-solid fa-circle-info"></i>
                            </td>
                            <td>
                                ["Ewing Gillaspy"]
                            </td>
                            <td>
                                1
                            </td>
                            <td className="hov-clr">
                                Confirm
                            </td>
                            <td className="hov-clr">
                                Reject
                            </td>
                            <td>
                                <a href="#"><i className="fa-solid fa-trash-can del-icon"></i></a>
                            </td>

                        </tr>
                        <tr>
                            <td className="info">
                                52.165.149.97 <i className="fa-solid fa-circle-info "></i>
                            </td>
                            <td>
                                ["Ted Box"]
                            </td>
                            <td>
                                1
                            </td>
                            <td className="hov-clr">
                                Confirm
                            </td>
                            <td className="hov-clr">
                                Reject
                            </td>
                            <td>
                                <a href="#"><i className="fa-solid fa-trash-can del-icon"></i></a>
                            </td>

                        </tr>

                    </table>
                </div>
                <div className="showing-main d-flex justify-content-between mt-3">
                    <div className="showing-para">
                        <p>Showing 1 to 2 of 2 entries</p>
                    </div>
                    <div>
                        <button className="previous-btn me-2">Previous</button>
                        <span className="count-btn me-2">1</span>
                        <button className="previous-btn">Next</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminPotentialBotsPage;
