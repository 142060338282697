import React from "react";
import Image from "../../../../CommonComponents/Image";
import IFrame from "../../../../CommonComponents/IFrame";
import HtmlText from "../../../../CommonComponents/HtmlText";

const Element = ({ element, dimensions, resizeDimensions, zoom, clips }) => {
  switch (element?.element_type_name) {
    case "Image":
      // to be done
      // console.log('clips>>>>>>',
      //   element?.geometry?.hide_beyond_page_edges ? clips : "false>>>>",
      //   `inset(${0}px ${clips?.rightClip}px ${0}px ${0}px)`);
      return (
        <Image
          src={element?.file_url ?? element?.value?.gif_link}
          alt="Uploaded image"
          onDragStart={(e) => e.preventDefault()}
          style={{
            height: `${resizeDimensions[element.id]?.height ?? dimensions?.height}px`,
            width: `${resizeDimensions[element.id]?.width ?? dimensions?.width}px`,
            objectFit: "cover",
            transform: `scale(${parseFloat(zoom?.value)})`,
            opacity: `${element?.transparency ?? 10}`,
            // clipPath: `inset(${0}px ${105}px ${0}px ${0}px)`
          }}
        />
      );
    case "Video" || "Web URL":
      return (
        <IFrame
          style={{
            height: `${resizeDimensions[element.id]?.height}px`,
            width: `${resizeDimensions[element.id]?.width}px`,
          }}
          src={element?.value?.video_link}
          title="url"
        />
      );
    case "Web URL":
      return (
        <IFrame
          style={{
            height: `${resizeDimensions[element.id]?.height}px`,
            width: `${resizeDimensions[element.id]?.width}px`,
          }}
          scrolling={element?.value?.no_scroll ? 'no' : 'yes'}
          src={element?.value?.video_link}
          title="url"
        />
      );
    case "HTML Text":
      return <HtmlText htmlContent={element?.value?.html_text} />;
    default:
      return <></>;
  }
};

export default Element;
