import React, { useEffect } from "react"
import '../../../assets/stylesheets/modal.css'

const ModalLayout = ({ openModal, title, children, closeModal, modalWidth }) => {

    useEffect(() => {
        if (openModal) {
            document.body.classList.add('Background');
        } else {
            document.body.classList.remove('Background');
        }
        return () => {
            document.body.classList.remove('Background');
        };
    }, [openModal]);

    return (
        <div className="modal-layout">
            <div className="modal-layout-body" style={{ width: modalWidth }}>
                <div className="hdng-wrap">
                    <h6>{title}</h6>
                    <div className="cross-icon">
                        <a href="#"><i className="fa-solid fa-xmark" onClick={() => {closeModal(false);}} /></a>
                    </div>
                </div>
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default ModalLayout