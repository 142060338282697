import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import './elementModal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpDownLeftRight, faXmark } from '@fortawesome/free-solid-svg-icons';

const ElementModal = ({ heading, children, openModal, handleCloseModal, z_index }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsOpen(openModal);
        setTimeout(() => {
            setIsAnimating(true);
        }, 10);
        return () => {
            setIsOpen(false);
            setIsAnimating(true);
        }
    }, [openModal]);

    const closeModal = () => {
        setIsAnimating(false);
        setTimeout(() => {
            setIsOpen(false);
            handleCloseModal(false)
        }, 300);
    };

    // const handleDrag = (e, data) => {
    //     console.log(`Dragging: x: ${data.x}, y: ${data.y}`);
    // };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('Background');
        } else {
            document.body.classList.remove('Background');
        }
        return () => {
            document.body.classList.remove('Background');
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <Draggable
                    handle=".handle-drag"
                    position={null}
                // onDrag={handleDrag}
                >
                    <div className={` elementModal ${isAnimating ? 'fade-in' : 'fade-out'}`}
                        style={{ zIndex: z_index ?? '102' }}>
                        <div className={`elementModal-content ${isAnimating ? 'slide-in' : 'slide-out'}`} onClick={(e) => e.stopPropagation()}>
                            <div className='handle-drag'>
                                <div className="elementModal-header">
                                    <div className='heading'>{heading}</div>
                                    <div className='d-flex'>
                                        <FontAwesomeIcon
                                            icon={faArrowsUpDownLeftRight}
                                            className='elementModal-action-btn'
                                        />
                                        <div className="spacer" />
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            className='elementModal-action-btn cursor-pointer'
                                            onClick={() => closeModal()}
                                        />
                                    </div>
                                </div>
                            </div>
                            {children}
                            <div className='elementModal-footer' />
                        </div>
                    </div>
                </Draggable>
            )}
        </>
    );
};

export default ElementModal;