import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import AddSubscriptionPage from "./Pages/Admin/AddSubscriptionPage";

function AddAdminSubscription() {
    return (
        <>
            <Layout>
                <AddSubscriptionPage />
            </Layout>
        </>
    )
}
export default AddAdminSubscription;