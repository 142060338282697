import React from "react";
const RecepientsPage = () => {
    return (
        <>
            <div className="header-content-main">
                <div className="doc-filter">
                    <h4 className="filter-recipients mb-0">Filters</h4>
                    <form className="d-flex">
                        <div className="d-flex form-group">
                            <input className="kdf-Tags1 ms-0" type="email" placeholder="Email" />
                        </div>
                        <div className="d-flex form-group">
                            <input className="kdf-Tags1" type="text" placeholder="Name" />
                        </div>
                        <div className="d-flex form-group">
                            <input className="kdf-Tags1" type="text" placeholder="KDF Title" />
                        </div>
                        <button className="btn btn-info margin-left-15 reset">
                            Apply Filters
                        </button>
                        <button className="reset-btn">
                            Reset Filters
                        </button>
                    </form>
                </div>
                <div className=" doc-filter">
                    <table className="Recipients-table">
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <th>KDFs</th>
                            <th>Visits</th>
                            <th>Pages</th>
                            <th>Top Viewed KDFs</th>
                            <th>Most Visited KDF</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>
                                nitinsaini@97200.com
                            </td>
                            <td>
                                nitin
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                43
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                <i className="fa-solid fa-pen me-3 rec-edit"></i>
                                <i className="fa-solid fa-trash-can rec-del"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                nitinsaini@97200.com
                            </td>
                            <td>
                                nitin
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                43
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                <i className="fa-solid fa-pen me-3 rec-edit"></i>
                                <i className="fa-solid fa-trash-can rec-del"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                nitinsaini@97200.com
                            </td>
                            <td>
                                nitin
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                43
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                <i className="fa-solid fa-pen me-3 rec-edit"></i>
                                <i className="fa-solid fa-trash-can rec-del"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                nitinsaini@97200.com
                            </td>
                            <td>
                                nitin
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                43
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                <i className="fa-solid fa-pen me-3 rec-edit"></i>
                                <i className="fa-solid fa-trash-can rec-del"></i>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
        </>
    )
}
export default RecepientsPage;
