import React from "react";
import '../../assets/stylesheets/editorPage.css'
import EditKdfPage from "./Pages/Kdf/EditKdf/EditKdfPage";

function KdfEditorPage(props) {
    return (
        <>
            <EditKdfPage props={props} />
        </>
    )
}
export default KdfEditorPage;