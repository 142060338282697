import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomTooltip from "../../Elements/Tooltip";
import Select from "../../Elements/Select";
import CustomTags from "../../Elements/CustomTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import styles from "./links.module.css";
import Input from "../../Elements/Input";
import noImage from '../../../../assets/images/icon_upload.svg';
import { networkService } from "../../config/axios";
const LaserLINKSettingsTab = ({
  editLaserLinkItem, 
  handleOnchangeEvent, 
  updateEditLaserLinkData, 
  getDomains, 
  getDocFiles, 
  validationTrigger, 
  updateErrorStatus, 
  errorListData
}) => {
  const receipientTypeViewNotify = useRef(null);
  const receipientTypeViewNotifyPage = useRef(null);
  // console.log('getDocFiles ', getDocFiles);
  // useEffect(() => {console.log('editLaserLinkItem ', editLaserLinkItem, ' getDocFiles ', getDocFiles)},[editLaserLinkItem, getDocFiles]);
  const [laserLinkType, setLaserLinkType] = useState(null);
  useEffect(() => {
    if(editLaserLinkItem?.campaign_id != null) {
      setLaserLinkType('public');
    }
    // updateEditLaserLinkData({...editLaserLinkItem, 'landing_page' : getDocFiles?.length > 0 ? '1' : ''})
  },[])

  const [formErrorData, setFormErrorData] = useState([]);

  const handleUpdateCampaign = async (value) => {
    if(value?.length > 0) {
      let campaignPayload = {'name': value[0]};
      let {data} = await networkService(`campaigns.json`, 'POST', campaignPayload);
      updateEditLaserLinkData({...editLaserLinkItem, 'campaign' : data, 'campaign_id':data.id})
      updateErrorStatus(errorListData?.filter(it => it != 'campaign'));
    }else {
      updateEditLaserLinkData({...editLaserLinkItem, 'campaign' : null, 'campaign_id': null})
    }
  }
  const validateInnerData = () => {
    let errorToUpdate = JSON.parse(JSON.stringify(errorListData));
    if(laserLinkType == 'public' && editLaserLinkItem?.campaign_id == null){
      if(!errorToUpdate?.includes('campaign')){
        errorToUpdate.push('campaign');
      }
    }else {
      errorToUpdate = errorListData?.filter(it => it != 'campaign');
    }
    if(getDocFiles[0]?.num_pages < editLaserLinkItem?.notify_for_page || getDocFiles[0]?.num_pages < editLaserLinkItem?.notify_page_after){
      if(!errorListData.includes('notify_page')){
        errorToUpdate.push('notify_page');
      }
    }else{
      errorToUpdate = errorListData?.filter(it => it != 'notify_page');
    }
    updateErrorStatus([...errorToUpdate]);
  }
  useEffect(()=> {
    // console.log('validationTrigger ', validationTrigger);
    
    if(validationTrigger == true){
      validateInnerData();
    }
  },[validationTrigger])
  return (
    <Row >
      <Col md={7}>
        <Row className="mb-3">
          <Col md={4} className="laser-imgs-center">
          <a href={`${editLaserLinkItem?.viewer}`} target="_blank" className="laser-img-popup">
            <img
              src={editLaserLinkItem?.thumb ?? noImage}
              alt={editLaserLinkItem?.name}
              width="100%"
            />
          </a>
          </Col>
          <Col md={8}>
            <Form.Check
              className="laser-setting-popup"
              inline
              label="Create an individual LaserLINK"
              name="link_type"
              type={"radio"}
              id={`laserLINK-individual-radio`}
              value="individual"
              checked={laserLinkType == 'individual'}
              onClick={() => {
                setLaserLinkType('individual'); 
                updateEditLaserLinkData({...editLaserLinkItem, 'campaign' : null, 'campaign_id': null})
              }}
            />
            <Form.Check
              className="laser-setting-popup"
              inline
              label="Create a public LaserLINK"
              name="link_type"
              type={"radio"}
              id={`laserLINK-public-radio`}
              value="public"
              checked={laserLinkType == 'public'}
              title="This type of LaserLINK is designed to be shared via social media."
              onClick={() => setLaserLinkType('public')}
            />
            {laserLinkType == 'public' && <div className="custom-tags">  
              <CustomTags 
                tags={editLaserLinkItem?.campaign != undefined ? [editLaserLinkItem?.campaign] : []}
                hideCross={true}
                hideIcon={true}
                dataPlaceholder="Campaign..."
                targetKey={'name'}
                limit={1}
                errorStatus={errorListData?.includes('campaign')}
                updateValue={(value) => handleUpdateCampaign(value)}
              />
            </div>}

            <Form.Check
              className="laser-setting-popup"
              inline
              label="As Template"
              name="template"
              type={"checkbox"}
              id={`laserLINK-template-checkbox`}
            />
            <CustomTooltip
              title={"To create KDF to KDF linking use this checkbox"}
            >
              <Form.Check
                className="laser-setting-popup"
                inline
                label="KDF to KDF Link"
                name="kdf_action_link"
                type={"checkbox"}
                id={`laserLINK-action-checkbox`}
              />
            </CustomTooltip>
          </Col>
        </Row>
        <div className="ms-5">
          <div className="d-flex mb-2 Domain-select">
            <label className="me-3">Domain</label>
            <Select
              classList=""
              name="domain"
              onChange={(e) => handleOnchangeEvent(e)}
              defaultValue={
                editLaserLinkItem?.domain != ""
                  ? editLaserLinkItem?.domain
                  : getDomains?.domains?.filter(
                      (item) => item.is_default == true
                    )
              }
              optionList={getDomains?.domains?.map((item) => {
                return { ...item, title: item?.name };
              })}
            />
          </div>
          <div className="d-flex align-items-center hook-second-inp">
            <label className="me-2">Tags</label>
            <CustomTags
              updateValue={(value) => console.log("custom tags value ", value)}
              tags={editLaserLinkItem?.tags}
              addOnClass={"flex-row-reverse"}
              hideCross={true}
            />
          </div>

          <div
            className={`d-flex align-items-center hook-inp ${styles.inputIconWrapper}`}
          >
            <label>Hook</label>
            <CustomTooltip
              title={
                "Insert text here that will be displayed along with the KDF title when you share LaserLINX via social media. Use this text to encourage recipients to click the LaserLINK you shared with them."
              }
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className="custom-tag-icon mx-2"
              />
            </CustomTooltip>
            <Input
              name="seo_description"
              value={editLaserLinkItem?.seo_description}
              onChange={(e) => handleOnchangeEvent(e)}
            />
          </div>
        </div>

        {/* {JSON.stringify(editLaserLinkItem?.send_engagement_notif)} */}
        <div className="d-flex mb-2 mt-3">
          <label className="send-mail-notify">Send email notification</label>
          <Form.Check
            className="laser-setting-popup d-inline-block"
            type="switch"
            id="email-notification-switch"
            name="send_engagement_notif"
            checked={
              editLaserLinkItem?.send_engagement_notif != undefined &&
              editLaserLinkItem?.send_engagement_notif == true
                ? editLaserLinkItem?.send_engagement_notif
                : false
            }
            onChange={(element) =>
              updateEditLaserLinkData({
                ...editLaserLinkItem,
                send_engagement_notif:
                  !editLaserLinkItem?.send_engagement_notif,
              })
            }
          />
        </div>
        {!!editLaserLinkItem?.send_engagement_notif && (
          <>
            <div className="d-flex align-items-center custom-tags TO-mail">
                <label className="mx-2">to </label>
                <CustomTags
                    label="test label"
                    updateValue={(value) => updateEditLaserLinkData({
                        ...editLaserLinkItem,
                        "notification_emails" : value.map(tagsEmail => (editLaserLinkItem?.notification_emails.find(em => em.email == tagsEmail) ?? {"email" : tagsEmail}))
                    })}
                    tags={editLaserLinkItem?.notification_emails ?? []}
                    addOnClass={'flex-row-reverse'}
                    hideCross={true}
                    hideIcon={true}
                    dataPlaceholder="Email..."
                    targetKey={'email'}
                />
            </div>
            <div>
            <p className="mt-4 mb-2">when the recipient:</p>
                <div className="d-flex cursor-pointer">
                    <Form.Check
                      className="laser-setting-popup"
                        inline
                        name="notify_when"
                        type={'radio'}
                        id={`notify-kdf-radio-notify_when`}
                        value="notify_when"
                        checked={editLaserLinkItem?.receipient_type == 'notify_when'}
                        onChange={element => {
                            updateEditLaserLinkData({
                                    ...editLaserLinkItem,
                                    "receipient_type": element.target.value
                                });
                            if (element.target.value == 'notify_when') {
                                receipientTypeViewNotify.current.focus();
                            }
                        }
                        }
                        label={
                            <span className="cursor-pointer">
                                views this KDF for more than
                                <CustomTooltip title={'Please enter the time in seconds'}>
                                    <input
                                        className="edit-box-input"
                                        type="number"
                                        value={editLaserLinkItem?.notify_after}
                                        onChange={
                                            (e) =>
                                                updateEditLaserLinkData(
                                                    { ...editLaserLinkItem, "notify_after": e.target.value }
                                                    
                                                )
                                        }
                                        ref={receipientTypeViewNotify}
                                    />
                                </CustomTooltip>
                                seconds
                                <CustomTooltip
                                    placement="left"
                                    title={
                                        <p>
                                            Time on page is measured while the recipient is actually on the page.
                                            <br />The timer is stopped when:
                                            <br />- the recipient switches tabs
                                            <br />- the recipient switches windows
                                            <br />- the window is not active
                                            <br />- the window is active, but the screen lock is on
                                        </p>
                                    }
                                    light={true}
                                >
                                    <FontAwesomeIcon
                                        icon={faQuestion}
                                        className="custom-tag-icon mx-2"
                                    />
                                </CustomTooltip>
                            </span>
                        }
                    />
                </div>
                <div className="d-flex cursor-pointer">
                    <Form.Check
                      className="laser-setting-popup"
                        inline
                        name="notify_when"
                        type={'radio'}
                        id={`notify-kdf-radio-notify_when_page`}
                        value="notify_when_page"
                        checked={editLaserLinkItem?.receipient_type == 'notify_when_page'}
                        onChange={
                            element => {
                                updateEditLaserLinkData({
                                        ...editLaserLinkItem,
                                        "receipient_type": element.target.value
                                    });
                                if (element.target.value == 'notify_when_page') {
                                    receipientTypeViewNotifyPage.current.focus();
                                }
                            }
                        }
                        label={
                            <span className="cursor-pointer">
                                views page
                                <CustomTooltip title={`Please enter a page between 1 and ${getDocFiles[0]?.num_pages ?? 0}`}>
                                    <input
                                    //${(editLaserLinkItem?.notify_for_page != '' && editLaserLinkItem?.notify_for_page > '0' && editLaserLinkItem?.notify_for_page <= getDocFiles[0]?.num_pages) ? 'has-error' : ''}
                                        className={`edit-box-input ${formErrorData?.includes('notify_page') ? 'box-input-error' : ''}`}
                                        type="number"
                                        // min={1}
                                        // max={getDocFiles[0]?.num_pages ?? 0}
                                        value={editLaserLinkItem?.notify_for_page ?? ''}
                                        ref={receipientTypeViewNotifyPage}
                                        onChange={
                                          (e) =>
                                            {
                                              if(e.target.value > getDocFiles[0]?.num_pages){
                                                if(!formErrorData?.includes('notify_page')){
                                                  setFormErrorData(prevState => ([...prevState,'notify_page']));
                                                }
                                              }else {
                                                setFormErrorData(prevState => ([prevState?.filter(it => it != 'notify_page')]));
                                              }
                                                updateEditLaserLinkData(
                                                { ...editLaserLinkItem, "notify_for_page": e.target.value }
                                              )
                                            }
                                        }
                                    />
                                </CustomTooltip>
                                for more than
                                <input
                                    className={`edit-box-input ${formErrorData?.includes('notify_page') ? 'box-input-error' : ''}`}
                                    type="number"
                                    // min={1}
                                    // max={getDocFiles[0]?.num_pages ?? 0}
                                    value={editLaserLinkItem?.notify_page_after ?? ''}
                                    // ref={receipientTypeView}
                                    onChange={
                                      (e) =>
                                          {
                                            if(e.target.value > getDocFiles[0]?.num_pages){
                                              if(!formErrorData?.includes('notify_page')){
                                                setFormErrorData(prevState => ([...prevState,'notify_page']));
                                              }
                                            }else {
                                              setFormErrorData(prevState => ([prevState?.filter(it => it != 'notify_page')]));
                                            }
                                            updateEditLaserLinkData(
                                              { ...editLaserLinkItem, "notify_page_after": e.target.value }
                                          )
                                        }
                                    }
                                />
                                seconds

                                <CustomTooltip
                                    placement="left"
                                    title={
                                        <p>
                                            Time on page is measured while the recipient is actually on the page.
                                            <br />The timer is stopped when:
                                            <br />- the recipient switches tabs
                                            <br />- the recipient switches windows
                                            <br />- the window is not active
                                            <br />- the window is active, but the screen lock is on
                                        </p>
                                    }
                                    light={true}
                                >
                                    <FontAwesomeIcon
                                        icon={faQuestion}
                                        className="custom-tag-icon mx-2"
                                    />
                                </CustomTooltip>
                            </span>
                        }
                    />
                </div>
            </div>
          </>
        )}
      </Col>
      <Col md={5}>
        <div className="d-flex language-span">
          <p className="me-3">Language:</p>
          <span className={styles.languageFlag}>
            <img
              src={`/assets/countries/${
                editLaserLinkItem?.language_flag?.locale
                  ?.toLowerCase()
                  .split("-")[1] ?? "en"
              }.png`}
            />
            {editLaserLinkItem?.language_flag?.name}
          </span>
        </div>
        
        {
          getDocFiles?.length > 1 && 
          <div className="mt-3 mb-3 d-flex landing-para">
            <p className="me-1">
            Landing page:
            </p>
            <span>
              <CustomTooltip
                title={`Please enter a page between 1 and ${
                  getDocFiles[0]?.num_pages ?? 0
                }`}
              >
                <input
                  className="edit-box-input"
                  type="number"
                  value={editLaserLinkItem?.landing_page ?? ""}
                  onChange={(e) =>
                    updateEditLaserLinkData({
                      ...editLaserLinkItem,
                      landing_page: e.target.value,
                    })
                  }
                />
              </CustomTooltip>
              / {getDocFiles[0]?.num_pages ?? 0}
            </span>
          </div>
        }
        <Form.Check
          className="laser-setting-popup"
          inline
          name="show_toolbar"
          type={'checkbox'}
          id={`show_toolbar`}
          value="show_toolbar"
          checked={!!editLaserLinkItem?.show_toolbar}
          onChange={element =>
              updateEditLaserLinkData({
                      ...editLaserLinkItem,
                      "show_toolbar": !!!editLaserLinkItem?.show_toolbar
                  }
              )
          }
          label={'Show toolbar'}
        />
        <Form.Check
          className="laser-setting-popup"
          inline
          name="show_thumbnail"
          type={'checkbox'}
          id={`show_thumbnail`}
          value="show_thumbnail"
          checked={!!editLaserLinkItem?.show_thumbnail}
          onChange={element =>
              updateEditLaserLinkData({
                      ...editLaserLinkItem,
                      "show_thumbnail": !!!editLaserLinkItem?.show_thumbnail
                  }
              )
          }
          label={'Show thumbnail navigation'}
        />
        <Form.Check
          className="laser-setting-popup"
          inline
          name="privacy_policy_consent"
          type={'checkbox'}
          id={`privacy_policy_consent`}
          value="privacy_policy_consent"
          checked={!!editLaserLinkItem?.privacy_policy_consent}
          onChange={element =>
              updateEditLaserLinkData({
                      ...editLaserLinkItem,
                      "privacy_policy_consent": !!!editLaserLinkItem?.privacy_policy_consent
                  }
              )
          }
          label={'Require consent'}
        />
                <Form.Check
                  className="laser-setting-popup"
                    inline
                    name="enable_download"
                    type={'checkbox'}
                    id={`enable_download`}
                    value="enable_download"
                    checked={!!editLaserLinkItem?.enable_download}
                    onChange={element =>
                        updateEditLaserLinkData({
                                ...editLaserLinkItem,
                                "enable_download": !!!editLaserLinkItem?.enable_download
                            }
                        )
                    }
                    label={'Enable download'}
                />
                <Form.Check
                  className="laser-setting-popup"
                    inline
                    name="has_optin"
                    type={'checkbox'}
                    id={`has_optin`}
                    value="has_optin"
                    checked={!!editLaserLinkItem?.has_optin}
                    onChange={element =>
                        updateEditLaserLinkData({
                                ...editLaserLinkItem,
                                "has_optin": !!!editLaserLinkItem?.has_optin
                            }
                        )
                    }
                    label={'Enable opt-in'}
                />
                <Form.Check
                  className="laser-setting-popup"
                    inline
                    name="disable_scroll"
                    type={'checkbox'}
                    id={`disable_scroll`}
                    value="disable_scroll"
                    checked={!!editLaserLinkItem?.disable_scroll}
                    onChange={element =>
                        updateEditLaserLinkData({
                                ...editLaserLinkItem,
                                "disable_scroll": !!!editLaserLinkItem?.disable_scroll
                            }
                        )
                    }
                    label={'Control scrolling'}
                />
                {
                    !!editLaserLinkItem?.disable_scroll &&
                    <>
                        <Form.Check
                          className="laser-setting-popup"
                            inline
                            name="disable_scroll"
                            type={'radio'}
                            id={`notify-kdf-radio-notify_when_page_cyoa`}
                            value="notify_when_page_cyoa"
                            checked={editLaserLinkItem?.disable_scroll_type == 'notify_when_page_cyoa'}
                            onChange={element => {
                                updateEditLaserLinkData({
                                        ...editLaserLinkItem,
                                        "disable_scroll_type": 'notify_when_page_cyoa'
                                    })
                            }
                            }
                            label={
                                <span className="cursor-pointer">
                                    CYOA to proceed
                                    <CustomTooltip
                                        placement="left"
                                        title={'This setting disables scrolling completely. Recipients will need to navigate using clickable hotspots and images that you place on each page. If you allow recipients to revisit pages, keep in mind that those clicks will skew your stats.'}
                                    >
                                        <FontAwesomeIcon
                                            icon={faQuestion}
                                            className="custom-tag-icon mx-2"
                                        />
                                    </CustomTooltip>
                                </span>
                            }
                        />
                        <Form.Check
                          className="laser-setting-popup"
                            inline
                            name="disable_scroll"
                            type={'radio'}
                            id={`notify-kdf-radio-after_seconds`}
                            value="after_seconds"
                            checked={editLaserLinkItem?.disable_scroll_type == 'after_seconds'}
                            onChange={element => {
                                updateEditLaserLinkData({
                                        ...editLaserLinkItem,
                                        "disable_scroll_type": 'after_seconds'
                                    })
                            }
                            }
                            label={
                                <span className="cursor-pointer">
                                    Delay for
                                    <CustomTooltip title={'Please enter the time in seconds'}>
                                        <input
                                            className="edit-box-input"
                                            type="number"
                                            value={editLaserLinkItem?.disable_settings?.disable_for ?? ''}
                                            // ref={receipientTypeNotify}
                                            name="disable_seconds"
                                            onChange={
                                                (e) =>
                                                    updateEditLaserLinkData(
                                                        {
                                                            ...editLaserLinkItem, "disable_settings": { ...editLaserLinkItem.disable_settings, "disable_for": e.target.value }
                                                        }
                                                    )
                                            }
                                        />
                                    </CustomTooltip>
                                    {editLaserLinkItem?.disable_settings?.disable_on ?? 'seconds'}
                                    <CustomTooltip
                                        placement="left"
                                        title={'Scrolling will be disabled for X seconds after the landing page loads so that recipients can not bypass the first page of your content.'}
                                    >
                                        <FontAwesomeIcon
                                            icon={faQuestion}
                                            className="custom-tag-icon mx-2"
                                        />
                                    </CustomTooltip>
                                </span>
                            }
                        />
                        <p className="cursor-pointer">
                            Recipient's Notification.
                            <CustomTooltip
                                placement="left"
                                title={'The message you enter here will be displayed if the recipient tries to scroll prematurely.'}
                            >
                                <FontAwesomeIcon
                                    icon={faQuestion}
                                    className="custom-tag-icon mx-2"
                                />
                            </CustomTooltip>
                        </p>
                        {
                            editLaserLinkItem?.disable_scroll_type == 'after_seconds' &&
                            <input
                                className="edit-box-input w-100"
                                type="text"
                                value={editLaserLinkItem?.disable_settings?.disable_message ?? ''}
                                onChange={
                                  (e) =>
                                    updateEditLaserLinkData(
                                      {
                                        ...editLaserLinkItem, "disable_settings": { ...editLaserLinkItem.disable_settings, "disable_message": e.target.value }
                                      }
                                    )
                                }
                            />
                        }
                        <Form.Check
                          className="laser-setting-popup"
                            inline
                            name="enable_transparent_bg"
                            type={'checkbox'}
                            id={`enable_transparent_bg`}
                            value="enable_transparent_bg"
                            checked={!!editLaserLinkItem?.enable_transparent_bg}
                            onChange={element =>
                                updateEditLaserLinkData({
                                        ...editLaserLinkItem,
                                        "enable_transparent_bg": !!!editLaserLinkItem?.enable_transparent_bg
                                    }
                                )
                            }
                            label={'Embed - No background'}
                        />
                        <Form.Check
                          className="laser-setting-popup"
                            inline
                            name="enable_shadow_box"
                            type={'checkbox'}
                            id={`enable_shadow_box`}
                            value="enable_shadow_box"
                            checked={!!editLaserLinkItem?.enable_shadow_box}
                            onChange={element =>
                                updateEditLaserLinkData({
                                        ...editLaserLinkItem,
                                        "enable_shadow_box": !!!editLaserLinkItem?.enable_shadow_box
                                    }
                                )
                            }
                            label={'Embed - No shadow'}
                        />
                    </>
                }
      </Col>
    </Row>
  );
};
export default LaserLINKSettingsTab;
