import React from "react"
const UniqueRecipientsPage = () => {
    return (
        <>
            <div className="header-content-main">
                <div className="glane-upper container row">
                    <div className="col-4 upper-one ">
                        <div className="d-flex">
                            <p className="hov-clr">LaserLINK:</p><img className="ms-1 me-1"
                                src="./assets/image/Kinetizine.png" />
                            <p className="hov-clr">nitin</p>
                        </div>
                        <p>LP=1 DS=3 Domain: powered-</p>
                        <p>by.kinetizine.com</p>
                    </div>
                    <div className="col-4 upper-two">
                        <div className="d-flex">
                            <p>KDF:
                                <p className="hov-clr">Test visits stats</p>
                            </p>
                        </div>
                        <p>Total Pages: 4</p>
                    </div>
                </div>

                <div className="visits-form mt-5 d-flex">
                    <div className="visits-one-data me-3 pe-3">
                        <input className="visits-input mb-3" placeholder="Email" />
                        <input className="visits-input" placeholder="Location:country,city,state,town" />

                        <div className="d-flex mt-3">
                            <button className="me-3 Apply-btn">Apply Filters</button>
                            <button className="reset-btn">Reset Filters</button>
                        </div>
                    </div>
                    <div className="visits-one-data me-3 pe-3">
                        <div className="d-flex align-items-center mt-2">
                            <p>At least </p><input className="edit-value-inp ms-1 me-1" value="2" />
                            <p>Pages viewed</p>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <p>At least </p><input className="edit-value-inp ms-1 me-1" value="2" />
                            <p>Pages viewed</p>
                        </div>
                        <div className="d-flex align-items-center mt-2 text-center">
                            <input className="edit-value-inp ms-1 me-1" value="2" />
                        </div>
                    </div>
                    <div className="visits-one-data me-3 pe-3">
                        <p>from date</p>
                        <div className="date-picker-container mb-3">
                            <input type="date" id="date" name="date" placeholder="From date"/>
                        </div>
                        <p>To date</p>
                        <div className="date-picker-container ">
                            <input type="date" id="date" name="date" placeholder="From date"/>
                        </div>
                    </div>
                    <div className="visits-one-data me-3 pe-3">
                        <input className="visits-input mb-3" placeholder="Recipients Name" />
                        <div className="d-flex align-items-center mt-2">
                            <p>Engagement Threshold:</p><input className="edit-value-inp ms-1 me-1" value="2" />
                            <p>Seconds</p>
                        </div>
                    </div>
                </div>


                <div className="visits-table mt-5">
                    <h6>1 visit matches the current filter settings:</h6>
                    <table className="visits-table-main mt-1">
                        <tr>
                            <th>
                                Visits
                            </th>
                            <th>
                                Recipient
                            </th>
                            <th>
                                Location
                            </th>
                            <th>
                                Device Info
                            </th>
                            <th>
                                Score
                            </th>
                            <th>
                                Viewed
                            </th>
                            <th>
                                Time
                            </th>
                            <th>
                                Top Page
                            </th>
                            <th>
                                Exit Page
                            </th>
                            <th>
                                Delete
                            </th>
                        </tr>
                        <tr>
                            <td>
                                2024-08-08 @ 12:33:37 IST
                            </td>
                            <td>
                                Nick <i className="fa-regular fa-copy ms-1"></i> <i className="fa-solid fa-pen ms-1"></i>
                            </td>
                            <td>
                                <img src="./assets/image/Kinetizine.png" alt=""/> Chandigarh, CH
                            </td>
                            <td>
                                <div className="d-flex table-imgs">
                                    <img src="./assets/image/chrome_888846.png" />
                                    <img src="./assets/image/penguin_8851407.png" />
                                    <img src="./assets/image/smart_13871763.png" />

                                </div>
                            </td>
                            <td>
                                0%
                            </td>
                            <td>
                                25%
                            </td>

                            <td>
                                --:00:16
                            </td>
                            <td>
                                1
                            </td>
                            <td>
                                1
                            </td>
                            <td>
                                <i className="fa-regular fa-trash-can"></i>
                            </td>

                        </tr>
                    </table>
                </div>
            </div>
        </>
    )
}
export default UniqueRecipientsPage