import React from "react";
import ModalLayout from "../ModalLayout";
import CommonModal from "../CommonModal";
const DeleteModal = ({ openDeleteModal, closeModal }) => {
    return (
        <CommonModal 
            title={"Delete KDF"} 
            modalStatus={openDeleteModal} 
            changeActiveStatus={closeModal}
            classList="header-profile-modal-wrapper"
            centered
        >
            <div>
                <p className="mb-2">Are you sure you want to delete this KDF? This action cannot be undone. </p>
                <div className="kdf-btn mt-3">
                    <button className="cancel-btn me-3" onClick={() => { closeModal(false) }}><a href="/kdfs">Cancel</a></button>
                    <button className="continue-btn">
                        Continue
                    </button>
                </div>
            </div>
        </CommonModal>
    )
}
export default DeleteModal