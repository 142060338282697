import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import LinkPage from "./Pages/Links/LinkPage";

function LazerLinks() {
    return (
        <>
            <Layout header={'Your LaserLINX'}>
                <LinkPage />
            </Layout>
        </>
    )
}
export default LazerLinks;
