import React, { useState } from 'react';
import '../../../../assets/stylesheets/homepage.css';

const Tooltip = ({ text, children }) => {
    const [visible, setVisible] = useState(false);
    const showTooltip = () => setVisible(true);
    const hideTooltip = () => setVisible(false);

    return (
        <div
            className="tooltip-container"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            {children}
            {visible && (
                <div className="tooltip-content">
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;