import React from "react";
import kz_logo from '../../../assets/images/kz_logo.png';
import { getTranslation } from "../../hooks/utils";

const ForgotPassword = ({ props, csrfToken }) => {
    return (
        <div className="login-warp-main">
            <form className="form-main" action="/users/password" method="post">
                <input type="hidden" name="authenticity_token" value={csrfToken} />
                <div className="kz-logo">
                    <img src={kz_logo} alt="KZ Logo" />
                </div>
                <div className="login-heading mb-4">
                    <h2>RECOVER YOUR PASSWORD</h2>
                </div>
                <div className="input-groups">
                    <div className="form-input">
                        <input
                            type="email"
                            name="user[email]"
                            placeholder="Email"
                            required
                        />
                        <input type="hidden" name="commit" value='Continue' />
                    </div>
                    <span className='form-errors'>
                    {getTranslation('errors.messages.not_found')}
                        {props?.messages?.alert === 'user_exist' || props?.messages?.alert === 'invalid_password' || props?.messages?.alert === 'not_found_in_database' && 
                        <>{getTranslation('devise.failure.incorrect_credentials')}</>
                        }
                    </span>
                    <div className="login-btn">
                        <button type="submit">
                            Continue
                        </button>
                    </div>
                    <div className="sign-p1">
                        <p className="mb-3"><a href="/users/login" >Log In</a></p>
                    </div>
                    <div className="sign-p1">
                        <p><a href="/signup">Sign Up</a></p>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ForgotPassword;
