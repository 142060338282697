import React, { useEffect, useRef, useState } from "react";
import { InputTags } from "react-bootstrap-tagsinput";
import CustomTooltip from "./Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
const CustomTags = ({updateValue, tags, handleCancelEvent = null, dataPlaceholder, addOnClass, hideCross = null, hideIcon = null, targetKey, limit = null, errorStatus}) => {
    const [errorStatustoRender, setErrorStatustoRender] = useState(false);
    const handleUpdateTags = (value) => {
        if(limit != null && value?.values?.length > limit){
            setErrorStatustoRender(true);
        }else {
            setErrorStatustoRender(false);
            let tagArray = tags?.map(tag => tag[targetKey ?? 'name']);
            // console.log('tagArray ', tagArray, value.values);
            
            if(tagArray?.length != value?.values?.length){
                updateValue(value.values);
                if(handleCancelEvent != null){
                    handleCancelEvent(true);
                }
            }
        }
    }
    // const customTagsRef = useRef(null);
    // const clickOutsideTagsEvent = (event) => {
    //     if(event.target.parentNode != customTagsRef.current.childNodes[0]){
    //         console.log('inside if');
    //         const inputValue = customTagsRef.current.childNodes[0].querySelector('input').value;
    //         let tagArray = tags?.map(tag => tag[targetKey ?? 'name']);
    //         handleUpdateTags({values:[...tagArray, inputValue]});
    //         document.removeEventListener('click', clickOutsideTagsEvent);
    //     }
    // }
    // useEffect(() => {
    //     document.addEventListener('click', clickOutsideTagsEvent)
    //     return () => {
    //         document.removeEventListener('click', clickOutsideTagsEvent);
    //     }
    // },[])
    // document.addEventListener('click', clickOutsideTagsEvent)
    return(
        <div className={`d-flex align-items-center ${addOnClass != undefined ? addOnClass : ''} ${(limit != null && tags?.length >= limit) ? 'tagError' : ''} ${errorStatus == true ? ' tag-alert ' : ''}`}>
            <InputTags 
                values={tags?.map(tag => tag[targetKey ?? 'name'])} 
                onTags={(value) => handleUpdateTags(value)}
                placeholder={dataPlaceholder}
            />
            <>
                {hideIcon == null && <CustomTooltip title={'You can add a tag by pressing enter, tab, delimiting with comma or clicking outside the tags area'}>
                    <FontAwesomeIcon icon={faQuestion} className="custom-tag-icon" />
                </CustomTooltip>}
                {hideCross == null && <FontAwesomeIcon 
                    icon={faRectangleXmark} 
                    onClick={() => handleCancelEvent(true)}
                    className="cancel mx-2 edit-icon"
                />}
            </>
        </div>
    );
}
export default CustomTags;