import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpDownLeftRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import './CommonModal.css';
const CommonModal = ({ title, children, footer, modalStatus, classList, changeActiveStatus, lightBox = false, ...rest }) => {
    // const modalRef = useRef(null);
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(modalStatus);
        return () => {
            setShow(false);
        }
    }, [modalStatus]);

    useEffect(() => {

        return () => {
            setShow(false);
        }
    }, []);

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            className={`${classList != undefined ? classList : ''} ${lightBox ? 'lightboxModal' : ''}`}
            {...rest}
        >
            {!lightBox && 
                <Modal.Header closeButton className="cursor-grab">
                    <Modal.Title>
                        {title ?? ''}
                    </Modal.Title>
                    <div className='d-flex'>
                        <FontAwesomeIcon onClick={() => changeActiveStatus(false)} icon={faXmark} className='au-modal-close-btn' />
                    </div>
                </Modal.Header>
            }
            <Modal.Body>
                {children}
            </Modal.Body>
            {!lightBox ? 
                <Modal.Footer>
                    {footer}
                </Modal.Footer> : 
                <Modal.Footer>
                    <FontAwesomeIcon 
                        onClick={() => changeActiveStatus(false)} 
                        icon={faXmark} 
                        className='au-modal-close-btn' 
                    />
                </Modal.Footer>
            }
        </Modal>
    )
}
export default React.memo(CommonModal);