import React, { useRef } from 'react';
import styles from './switch.module.css';
import { Form } from 'react-bootstrap';
const Switch = ({label, children, classList, addOnClass, forRef = null, formId, formLabel, ...rest}) => {
    const inputRef = useRef(null);
    let formID = formId ?? `custom-switch-${Math.floor(Math.random() * 1000)}`;
    return(
        <>
            <Form.Check
                type="switch"
                id={formID}
                label={formLabel ?? ''}
                ref={forRef ?? inputRef}
                className={`${classList != undefined ? classList.replace(/\s{2,}/g, ' ').split(' ').map(item => styles.item ?? '') : ''} ${addOnClass ?? ''}`}
                {...rest}
            />
        </>
    );
}
export default Switch;
