import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
const VideoToolbox = () => {
    return(
        <>
            <FontAwesomeIcon icon={faVideo} />
            Video
        </>
    );
}
export default VideoToolbox;