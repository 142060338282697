import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import user from "../../../../assets/images/user.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCircleInfo,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import Button from "../../Elements/Button";

import "./header.css";
import UserInfoContext from "../../../utis/userInfoContext";
import CommonModal from "../../Modals/CommonModal";
import Input from "../../Elements/Input";
import Select from "../../Elements/Select";
import utilsUploadFormData from "../../../utis/upoadFormData";
import apiGETRequest from "../../../utis/apiRequest";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import { networkService } from "../../config/axios";

const Header = ({ heading, currentUser, trueUser }) => {
  const [profileModalStaus, setProfileModalStaus] = useState(false);
  const [timeZoneData, setTimeZoneData] = useState({});
  const [step, setStep] = useState(1);
  const { profileData, setUserData, setUserProfileData } =
    useContext(UserInfoContext);
  const [userModalField, setUserModalField] = useState({});
  useEffect(() => {
    return () => {
      setStep(1);
    };
  }, []);
  const updatingUserProfile = async () => {
    let userData = await apiGETRequest(`edit.json`);
    if (userData) {
      setUserData(userData);
      let userProfileData = await apiGETRequest(
        `profiles/${userData?.id}.json`
      );
      setUserProfileData(userProfileData);
    }
  };
  const updateTimeZone = async () => {
    let getTimeZoneData = await apiGETRequest(`time-zones`);
    setTimeZoneData(getTimeZoneData);
  };
  useEffect(() => {
    updatingUserProfile();
    updateTimeZone();
  }, []);

  const handleOnchangeEvent = (element) => {
    let userModalFieldCopy = JSON.parse(JSON.stringify(userModalField));
    let valueToUpdate = userModalFieldCopy[`step${step}`].map((field) => {
      return field.name == element.target.name
        ? {
            ...field,
            value: element.target.value,
            error: (element.target.value.trim() == "") & field.required,
          }
        : { ...field };
    });
    setUserModalField({
      ...userModalFieldCopy,
      [`step${step}`]: valueToUpdate,
    });
  };
  const updateModalFields = () => {
    let valueToUpdate = {
      step1: [
        {
          type: "text",
          required: true,
          label: "First Name",
          name: "first_name",
          value: profileData.first_name,
        },
        {
          type: "text",
          required: true,
          label: "Last Name",
          name: "last_name",
          value: profileData.last_name,
        },
        {
          type: "text",
          required: false,
          label: "Privacy LInk",
          name: "privacy_link",
          value: profileData.privacy_link,
        },
        {
          type: "select",
          required: true,
          label: "Time Zone",
          name: "time_zone",
          value: profileData.time_zone,
        },
      ],
      step2: [
        {
          type: "text",
          required: true,
          label: "Company Name",
          name: "company_name",
          value: profileData.company_name,
        },
        {
          type: "text",
          required: true,
          label: "Country",
          name: "country",
          value: profileData.country,
        },
        {
          type: "text",
          required: false,
          label: "Direct Chat Link (Tawk.to)",
          name: "direct_chat_link",
          value: profileData.direct_chat_link,
        },
      ],
    };
    setUserModalField(valueToUpdate);
  };

  useEffect(() => {
    if (profileModalStaus) {
      updateModalFields();
      setStep(1);
    }
  }, [profileModalStaus]);

  const handleMoveToNext = async () => {
    let userModalFieldCopy = JSON.parse(JSON.stringify(userModalField));
    if (
      userModalFieldCopy[`step${step}`].filter((field) => field.error == true)
        ?.length == 0
    ) {
      if (step < 2) {
        setStep((prevState) => prevState + 1);
      } else {
        // let dataToUpload = Object.keys(userModalFieldCopy).map(fieldID => userModalFieldCopy[fieldID].map(fieldData => {return {[fieldData.name]:fieldData.value}}))
        let dataToUpload = Object.keys(userModalFieldCopy)
          .map((fieldID) => userModalFieldCopy[fieldID])
          .flat()
          .reduce((acc, curr) => {
            acc[`${curr.name}`] = curr.value;
            return acc;
          }, {});
        let sendingFormData = await utilsUploadFormData(
          `profiles/${profileData.id}.json`,
          { profile: dataToUpload, user_id: profileData.id },
          "PATCH"
        );
        updatingUserProfile();
        setProfileModalStaus(false);
      }
    }
  };
  const handleClearCookie = async () => {
    networkService("users/sign_out", "delete")
      .then((response) => {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error during sign out:", error);
      });
  };

  const handleStopImpersonate = async () => {
    const formData = new FormData();
    formData.append("_method", "post");
    formData.append("authenticity_token", csrfToken());
    try {
      const response = await fetch(`/admin/stop_impersonating`, {
        method: "POST",
        body: formData,
        cookie: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
      });
      if (response?.ok) {
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  return (
    <header className="main-header">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand>
            {heading?.toLowerCase() == "kdfs" ? (
              <>
                {" "}
                {heading}{" "}
                <Button
                  classList={"success"}
                  addonClass="d-inline-flex ml-4"
                  onClick={() => (window.location = "/kdfs/new")}
                  text={"Create New"}
                ></Button>
              </>
            ) : (
              <>{heading}</>
            )}
          </Navbar.Brand>
          <Nav
            className="my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <NavDropdown
              title={
                <>
                  <img className="user-imgs" src={user} alt="" />
                  <span className="mx-3 user-name-header">
                    {profileData?.name}{" "}
                  </span>
                </>
              }
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                className="profile-drop"
                onClick={() => setProfileModalStaus(true)}
              >
                <FontAwesomeIcon icon={faUser} className="" /> My Profile
              </NavDropdown.Item>
              <NavDropdown.Item className="profile-drop" href="/customizations">
                <FontAwesomeIcon icon={faCircleInfo} className="" />{" "}
                Customizations
              </NavDropdown.Item>
              <NavDropdown.Item
                className="profile-drop"
                onClick={handleClearCookie}
              >
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  className="active-icon"
                />{" "}
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
      {/* </Col>
            </Row> */}
      <CommonModal
        title={"My Settings"}
        modalStatus={profileModalStaus}
        changeActiveStatus={setProfileModalStaus}
        classList="header-profile-modal-wrapper"
        footer={
          <>
            {step == 1 ? (
              <Button
                classList={"success"}
                addonClass="d-inline-flex ml-4"
                onClick={handleMoveToNext}
                text={"Create New"}
              ></Button>
            ) : (
              <>
                <Button
                  classList={"success"}
                  addonClass="d-inline-flex ml-4"
                  onClick={() => setStep((prev) => prev - 1)}
                  text={"Back"}
                ></Button>
                <Button
                  classList={"success"}
                  addonClass="d-inline-flex ml-4"
                  onClick={handleMoveToNext}
                  text={"Save Changes"}
                ></Button>
              </>
            )}
          </>
        }
        centered
      >
        <form name="profileForm">
          {userModalField[`step${step}`] != undefined &&
            userModalField[`step${step}`].map((item, index) => (
              <div key={index}>
                {item.type == "select" ? (
                  <Select
                    classList=""
                    name={item.name}
                    required={item.required}
                    label={item.label}
                    optionList={Object.keys(timeZoneData).map((item) => {
                      return {
                        value: timeZoneData[item].id,
                        title: timeZoneData[item].name,
                      };
                    })}
                    onChange={(e) => handleOnchangeEvent(e)}
                    value={item.value}
                    errorMessageStatus={
                      item?.error != undefined ? item.error : false
                    }
                  />
                ) : (
                  <Input
                    classList=""
                    type={item.type}
                    placeholder={`Enter ${item.label}`}
                    name={item.name}
                    required={item.required}
                    label={item.label}
                    onChange={(e) => handleOnchangeEvent(e)}
                    value={item.value}
                    errorMessageStatus={
                      item?.error != undefined ? item.error : false
                    }
                  />
                )}
              </div>
            ))}
        </form>
      </CommonModal>
      {currentUser?.id !== trueUser?.id && (
        <div className="alert alert-warning" role="alert">
          You (<strong>{trueUser.name || trueUser.email}</strong>) are signed in
          as <strong>{currentUser.name || currentUser.email}</strong>.
          <a onClick={handleStopImpersonate}>To go back to admin click here</a>
        </div>
      )}
    </header>
  );
};

export default Header;
