import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import KdfListPage from "./Pages/Kdf/KdfListPage";
import '../../assets/stylesheets/editorPage.css'

function KdfPage(props) {
    return (
        <>
            <Layout header={'KDFs'}>
                <KdfListPage props={props} />
            </Layout>
        </>
    )
}
export default KdfPage;