import React from "react"
const AtaGlancePage = () => {
    return (
        <>
            <div className="header-content-main">
                <div className="glane-upper container row">
                    <div className="col-4 upper-one ">
                        <div className="d-flex">
                            <p className="hov-clr">LaserLINK:</p><img className="ms-1 me-1"
                                src="./assets/image/Kinetizine.png" />
                            <p className="hov-clr">nitin</p>
                        </div>
                        <p>LP=1 OI=3 Domain: delivered-</p>
                        <div className="d-flex align-items-center mt-2">
                            <p>Engagement Threshold: </p><input className="edit-value-inp ms-1 me-1" value="2" />
                            <p>seconds</p>
                        </div>
                    </div>
                    <div className="col-4 upper-two">
                        <div className="d-flex">
                            <p>KDF:
                                <p className="hov-clr">Demo 240822</p>
                            </p>
                        </div>
                        <p>Total Pages: 4</p>
                    </div>
                </div>
                <div className="glance-table col-5 mt-5">
                    <div className="overview-main">
                        <div className="overview-tab">
                            <p>Overview</p>
                        </div>
                    </div>
                    <div className="overview-main-bottom row">
                        <div className="d-flex t-first">
                            <p>Performance</p>
                            <p>Behavioral metrics</p>
                        </div>
                        <div className="d-flex t-second">
                            <div className="inner-glance">
                                <h3>3</h3>
                                <p>Visits</p>
                            </div>
                            <div className="inner-glance">
                                <h3>1</h3>
                                <p>Returning Viewers</p>
                            </div>
                        </div>
                        <div className="d-flex t-second">
                            <div className="inner-glance">
                                <h3>--:02:28</h3>
                                <p>Total Time</p>
                            </div>
                            <div className="inner-glance">
                                <h3>1</h3>
                                <p>Opt-ins</p>
                            </div>
                        </div>
                        <div className="d-flex t-second">
                            <div className="inner-glance">
                                <h3> 67%</h3>
                                <p>Viewed</p>
                            </div>
                            <div className="inner-glance">
                                <h3>0/0</h3>
                                <p>Viewed Video 100%</p>
                            </div>
                        </div>
                        <div className="d-flex t-second">
                            <div className="inner-glance">
                                <h3>3</h3>
                                <p>Most Visited Page</p>
                            </div>
                            <div className="inner-glance">
                                <h3>100%</h3>
                                <p>Score</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AtaGlancePage