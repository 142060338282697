import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext } from "react"
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext"
const LightBoxElement = ({ lightBoxPayload, elementName, getElementType, handleChange }) => {
    const { setOpenAddElement, setToolType } = useContext(EditorInfoContext)

    return (
        <div className="section-element">
            <h3>INCLUDE ELEMENT</h3>
            <label>
                <input
                    type="radio"
                    name="element"
                    value='video'
                    checked={getElementType(lightBoxPayload?.elements)?.element_type === 5 || elementName === 'video'
                    }
                    onChange={handleChange}
                />
                Video
            </label>
            <label>
                <input
                    type="radio"
                    name="element"
                    checked={getElementType(lightBoxPayload?.elements)?.element_type === 8 || elementName === 'audio'}
                    value='audio'
                    onChange={handleChange}
                />
                Audio
            </label>
            <label>
                <input
                    type="radio"
                    name="element"
                    onChange={handleChange}
                    checked={getElementType(lightBoxPayload?.elements)?.element_type === 7 || elementName === 'text'}
                    value='text'
                />
                Text
            </label>
            <label>
                <input
                    type="radio"
                    name="element"
                    value='html'
                    checked={getElementType(lightBoxPayload?.elements)?.element_type === 12 || elementName === 'html'}
                    onChange={handleChange}
                />
                HTML
            </label>
            <label>
                <input
                    type="radio"
                    name="element"
                    value='embed'
                    checked={getElementType(lightBoxPayload?.elements)?.element_type === 6 || elementName === 'embed'}
                    onChange={handleChange} />
                Embed URL
            </label>
            <label className="align-item-center">
                <input
                    type="radio"
                    name="element"
                    value='image'
                    onChange={handleChange}
                    checked={getElementType(lightBoxPayload?.elements)?.element_type === 4 || elementName === 'image'}
                />
                Image
                <FontAwesomeIcon
                    icon={faPlus}
                    className="ms-2 fs-6 success-green cursor-pointer"
                    onClick={() => {
                        setToolType('LightboxesToolbox')
                        setOpenAddElement(true)
                    }}
                />
            </label>
            {elementName === 'image' && <label className="mt-4">
                <input type="checkbox"
                    name="slide_show"
                    value={lightBoxPayload?.slide_show}
                    onChange={handleChange}
                />
                Slide show
            </label>}
        </div>
    )
}
export default LightBoxElement