import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
const EmbedToolbox = () => {
    return(
        <>
            <FontAwesomeIcon icon={faCode} />
            Embed
        </>
    );
}
export default EmbedToolbox;