import React from "react";

const SignupSuccess = () => {
    return (
        <>
            <div className="login-warp-main">
                <div className="form-main">
                    <div className="kz-logo">
                        <img src="./assets/image/kz_logo.png" />
                    </div>
                    <div className="login-heading">
                        <h2>THANK YOU</h2>
                    </div>
                    <div className="thankyou-para">
                        <div className="mb-4">
                            <p>
                                Thanks for signing up.
                            </p>
                            <p>
                                You're going to love this amazing service.
                            </p>
                        </div>
                        <div className="mb-4">
                            <p>
                                A confirmation link has been emailed to you.
                            </p>
                        </div>
                        <div className="mb-4">
                            <p>
                                Check your email, click on the included link,
                            </p>
                            <p>
                                and get ready to put your content to work!
                            </p>
                        </div>

                        <div className="ok-btn mb-4">
                            <button>OK</button>
                        </div>

                        <div className="resend-link">
                            <p>
                                Didn’t receive a link?
                            </p>
                            <a href="#">Resend</a>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default SignupSuccess