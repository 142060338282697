import React, { useEffect, useState } from "react"
import { useAnimate } from "../../../../hooks/useAnimate";

const AnimatedElements = ({ element, children }) => {
    const [visible, setVisible] = useState(false);
    const { animateClass } = useAnimate(element);

    useEffect(() => {
        const showDelayedDiv = () => {
            if (element?.sec_to_delay === 0) {
                setVisible(true);
            } else {
                const showTimeout = setTimeout(() => {
                    setVisible(true);
                    clearTimeout(showTimeout);
                }, element?.sec_to_delay * 1000);
            }
        };

        showDelayedDiv();

        return () => {
            setVisible(false);
        };
    }, []);

    return (
        <div className={element?.value?.animation ?
            `animate__animated animate__${animateClass?.animationName ? animateClass.animationName : element?.value?.animation} animate__${animateClass?.infinite} ${animateClass?.infinite}`
            : ''}
            style={{ background: `${element?.value?.bg_color}`, height: '100%'}}
        >
            {visible && children}
        </div>
    );
}
export default React.memo(AnimatedElements) 