import React from 'react';

const Image = ({ src, className, style, alt, ...props }) => {
  return (
    <img
        src={src}
        className={className}
        alt={alt}
        style={style}
        {...props}
    />
  )
}

export default React.memo(Image);
