import React from "react";
const AccessGateOptions = () => {
    return (
        <div className="laser-popup-pages">
            <div className="laser-top">
                <div className="laser-email-main mt-3 d-flex">
                    <p>Invitation code</p>
                    <div className="d-flex switchs ms-3">
                        <p>Yes</p>
                        <div className="ms-2 me-2">
                            <label className="switch" htmlFor="checkbox">
                                <input type="checkbox" id="checkbox" />
                                <div className="slider round"></div>
                            </label>
                        </div>
                        <p>No</p>
                    </div>
                    <input placeholder="Enter your Invitation code here" className="form-control stuffle-inp ms-5" />
                    <button className="stuffle">
                        <i className="fa-solid fa-shuffle"></i>
                    </button>
                </div>
                <div className="laser-email-main mt-3 d-flex">
                    <p>Expiration date</p>
                    <div className="d-flex switchs ms-3">
                        <p>Yes</p>
                        <div className="ms-2 me-2">
                            <label className="switch" htmlFor="checkbox4">
                                <input type="checkbox" id="checkbox4" />
                                <div className="slider round"></div>
                            </label>
                        </div>
                        <p>No</p>
                    </div>
                    <div className="visits-one-data me-3 pe-3 ms-5">
                        <p>from date</p>
                        <div className="date-picker-container mb-3">
                            <input type="date" id="date" name="date" placeholder="From date" />
                        </div>
                    </div>
                    <div className="d-flex align-items-center ms-3 laser-inp">
                        <input className="edit-value-inp ms-1 me-1" value="22" />
                        <p>:</p>
                        <input className="edit-value-inp ms-1 me-1" value="22" />
                    </div>
                    <div>
                        <input className="form-control" />
                    </div>
                </div>
                <div className="laser-email-main mt-3 d-flex">
                    <p>Enable opt-in</p>
                    <div className="d-flex switchs ms-3">
                        <p>Yes</p>
                        <div className="ms-2 me-2">
                            <label className="switch" htmlFor="checkbox2">
                                <input type="checkbox" id="checkbox2" />
                                <div className="slider round"></div>
                            </label>
                        </div>
                        <p>No</p>
                    </div>
                    <div className="ms-4 d-flex tel-center">
                        <p>Full Name :</p>
                        <div className="d-flex switchs ms-3">
                            <p>Yes</p>
                            <div className="ms-2 me-2">
                                <label className="switch" htmlFor="checkbox1">
                                    <input type="checkbox" id="checkbox1" />
                                    <div className="slider round"></div>
                                </label>
                            </div>
                            <p>No</p>
                        </div>
                    </div>
                    <div className="ms-4 d-flex tel-center mb-4">
                        <p>Tel nr: </p>
                        <div className="d-flex switchs ms-3">
                            <p>Yes</p>
                            <div className="ms-2 me-2">
                                <label className="switch" htmlFor="checkbox3">
                                    <input type="checkbox" id="checkbox3" />
                                    <div className="slider round"></div>
                                </label>
                            </div>
                            <p>No</p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="mt-3">
                    <p>
                        OPT-IN settings:
                    </p>
                </div>

                <div className="d-flex laser-left-flex mt-2">
                    <input type="radio" id="temp" name="link" value="" />
                    <label className="ms-2" htmlFor="vehicle1">Appear on 2nd viewed page</label>

                </div>
                <div className="d-flex mt-2">
                    <input type="radio" id="temp" name="link" value="" />
                    <div className="d-flex align-items-center ms-3 laser-inp">
                        <p>Appear on page: </p><input className="edit-value-inp ms-1 me-1" value="22" />
                        <p>/ 4 after </p>
                    </div>
                    <div className="d-flex align-items-center ms-3 laser-inp">
                        <input className="edit-value-inp ms-1 me-1" value="22" />
                        <p>sec</p>
                    </div>
                </div>

                <div className="permission-flex row">
                    <div className="col-5">
                        <div className="mt-3 permission-drop d-flex">
                            <div className="custom-select-container me-2  ali">
                                <select id="custom-select" className="custom-select">
                                    <option value="option1">Click here for alternative pre-defined text examples
                                    </option>
                                    <option value="option2"> option 1</option>
                                    <option value="option3"> option 2</option>
                                </select>
                            </div>
                            <i className="fa-solid fa-pen-to-square"></i>
                        </div>

                        <div className="laser-option-main d-flex mt-4">
                            <div className="laser-options me-3">
                                <a>
                                    Initial
                                </a>
                            </div>
                            <div className="laser-options me-3">
                                <a>
                                    Subsequent
                                </a>
                            </div>

                        </div>
                        <div className="temp-check mt-3 mb-2">
                            <input type="checkbox" id="temp" name="temp" value=" " />
                            <label htmlFor="vehicle1"> This info will only be shared with Jawahar from Box</label>
                            <div className="d-flex align-items-center mt-3 laser-inp">
                                <p>Enter button text: </p><input className="edit-value-inp ms-1 me-1" value="OK" />
                            </div>
                        </div>
                    </div>

                    <div className="col-5">
                        <div>
                            <p>
                                Preview
                            </p>
                        </div>
                        <div className="permission-top">
                            <p>Your permission is required to continue</p>
                        </div>
                        <div className="permission-para">

                            <div>
                                <p>
                                    To continue reading, please provide your:
                                </p>

                            </div>
                            <div className="d-flex mb-3 mt-2">
                                <input className="form-control" placeholder="First name" />
                                <input className="form-control ms-2" placeholder="Last name" />
                            </div>
                            <div>
                                <input className="form-control" placeholder="Email" />
                            </div>
                            <div className="permission-paragraph mt-2 mb-2">
                                <p>
                                    This info will only be shared with Jawahar from Box
                                </p>
                                <p>
                                    You consent to us processing your personal data.
                                </p>

                                <p>
                                    Unsubscribe anytime. See our<span className="privacy"><a href="#"> Privacy
                                        Notice</a></span>
                                </p>
                            </div>

                            <div className="ok-btn">
                                <button>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AccessGateOptions