import React, { useContext, useEffect, useState } from "react"
import CustomTags from "../../../Elements/CustomTags"
import { csrfToken } from "../../../../utis/csrfTokenUtils"
import { EditorInfoContext } from "../../../../utis/userInfoContext"
import { networkService } from "../../../config/axios"

const TagEditorSection = ({ docFileId, docId, docFiles, currentPage }) => {
    const [docTags, setDocTags] = useState({})
    const { pageNumber } = useContext(EditorInfoContext)
    const [isChecked, setIsChecked] = useState({
        monitor: 0,
        all_tag: 0
    });

    //add tags to targeted page api call
    const handleAddTagsWithPage = async (tags) => {
        const payload = {
            authenticity_token: csrfToken(),
            doc_id: docId,
            docs_file_id: docFileId,
            page: pageNumber,
            tags_doc_files: tags
        }

        let pageList = await networkService(`docs/page-tags`, 'POST', payload)
        if (pageList.status == 200) {
            // toast("Wow so easy!")
        }
    }

    //add tags api
    const handeAddTags = async (value) => {
        const ids = [];
        let tagsList = await networkService(`tags.json`, 'POST', { 'name': value[value.length - 1] })
        if (tagsList?.status === 201) {
            ids.push(...docTags.tags[pageNumber].map(item => item.id))
            ids.push(tagsList?.data?.id);
            handleAddTagsWithPage(ids)
        }
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setIsChecked({
            ...isChecked, [name]: checked ? 1 : 0
        });
    };

    useEffect(() => {
        const handleTagSection = async () => {
            const data = {
                authenticity_token: csrfToken(),
                doc_id: docId,
                docs_file_id: docFileId,
                page: pageNumber,
                monitor: isChecked?.monitor,
                all_tag: isChecked?.all_tag,
            }
            await networkService(`docs/engagement-pages`, 'POST', data);
        }
        if (isChecked?.monitor !== undefined || isChecked?.all_tag !== undefined) {
            handleTagSection();
        }
    }, [isChecked]);

    useEffect(() => {
        setDocTags(docFiles?.find((elem) => elem?.id == docFileId))
    }, [docId])

    return (
        <div className="tag-section me-2">
            <CustomTags
                updateValue={(value) => handeAddTags(value)}
                tags={docTags?.tags ? docTags?.tags[pageNumber] : []}
            />
            <div className="toggle-group">
                <label>Include in engagement stats</label>
                <label className="switch">
                    <input
                        type="checkbox"
                        name="all_tag"
                        value={isChecked?.all_tag}
                        checked={!!(isChecked?.all_tag || docTags?.all_page_tags?.includes(currentPage))}
                        onChange={handleCheckboxChange}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
            <div className="toggle-group">
                <label>Apply to all language editions</label>
                <label className="switch">
                    <input
                        type="checkbox"
                        name="monitor"
                        value={isChecked?.monitor}
                        checked={!!(isChecked?.monitor || docTags?.engagement_pages?.includes(currentPage))}
                        onChange={handleCheckboxChange}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
        </div>
    )
}
export default TagEditorSection