import React from "react";
import Draggable from "react-draggable";

const DraggableElement = ({
  position,
  bounds,
  disabled,
  children,
  canvasRef,
  setResizeDimensions,
  resizableDimensions,
  element
}) => {
  const handleDrag = (e, data) => {
    if (!canvasRef) return;
    const canvasRect = canvasRef.getBoundingClientRect();
    let newX = data.x;
    let newY = data.y;

    if (newX < 0) newX = 0;
    if (newX + element.geometry.width > canvasRect.width) {
      newX = canvasRect.width - element.geometry.width;
    }
    if (newY < 0) newY = 0;
    if (newY + element.geometry.height > canvasRect.height) {
      newY = canvasRect.height - element.geometry.height;
    }
    setResizeDimensions(prevDimensions => {
      const dimensions = prevDimensions[element.id];
      return {
        ...prevDimensions,
        [element.id]: {
          ...dimensions,
          top: resizableDimensions.top + data.y,
          left: resizableDimensions.left + data.x
        }
      }
    });

    return { x: newX, y: newY };
  };

  return (
    <Draggable
      position={position}
      onDrag={handleDrag}
      bounds={element?.geometry?.keep_within_page ? bounds : false}
      disabled={disabled}
    >
      {children}
    </Draggable>
  );
};

export default DraggableElement;
