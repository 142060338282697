import React, { useRef } from 'react';
import '../input.css';
import { Form } from 'react-bootstrap';

const TextArea = ({classList,required = false, errorMessage, errorMessageStatus, placeholder, children, label, forRef = null, ...restProps}) => {
    const inputRef = useRef(null);
    let formID = `${{...restProps}?.name}-${Math.floor(Math.random() * 1000)}`
    return(
        <div className={`${classList != undefined ? classList : ''}`}>
            {label && <label className="control-label" htmlFor={formID}>{ required && '*' } {label}</label>}
            <Form.Control
                as="textarea"
                placeholder={placeholder ?? ''}
                style={{ height: '100px' }}
                id={formID}
                ref={forRef ?? inputRef}
                {...restProps}
            />
            {children}
            <div className='input-messages'>
                {errorMessage}
            </div>
        </div>
    )
}
export default TextArea;