import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import AdminPotentialBotsPage from "./Pages/Admin/AdminPotentialBotsPage";

function AdminPotentialBots() {
    return (
        <>
            <Layout>
                <AdminPotentialBotsPage />
            </Layout>
        </>
    )
}
export default AdminPotentialBots;


