import React,{ useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './TermsAndPolicyModal.css';
const TermsAndPolicyModal = ({title, children, footer, modalStatus, modalActiveStatus}) => {
    // const modalRef = useRef(null);
    const [show,setShow] = useState(false);
    useEffect(() => {
        setShow(modalStatus);
        return () => {
            setShow(false);
        }
    },[modalStatus]);
    return( 
       <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
                <FontAwesomeIcon onClick={() => modalActiveStatus(false)} icon={faXmark} className='au-modal-close-btn'/>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>{footer}</Modal.Footer>                
        </Modal>
    )
}
export default TermsAndPolicyModal;