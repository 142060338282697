import React from 'react';
import { useDropzone } from 'react-dropzone';

const DragDropComponent = ({ setFile }) => {
    const { getRootProps, getInputProps, acceptedFiles, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'application/pdf': [] // Only accept PDF files
        },
        onDrop: (acceptedFiles) => {
            // Handle file drop
            setFile(acceptedFiles)
        }
    });
    const files = acceptedFiles.map(file => (
        <li key={file.path}>{file.path} - {file.size} bytes</li>
    ));

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <ul>{files}</ul>
            <div className="add-pdf-file">
                <span>
                    Drag file here, or 
                </span>
                <span>
                    <button type="file" className="file-btn ms-2">
                        <i className="fa-regular fa-file me-2"></i>Choose file
                    </button>
                </span>
            </div>
        </div>
    );
};

export default DragDropComponent;
