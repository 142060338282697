import React from "react"

const LightBoxTrigger = ({ lightBoxPayload, handleChange }) => {
    return (
        <>
            <div className="section-trigger">
                <h3>TRIGGER</h3>
                <label>
                    <input
                        type="radio"
                        name="display"
                        value="click"
                        onChange={handleChange}
                        checked={lightBoxPayload?.display === 'click'}
                    />
                    When a related hotspot or image is clicked
                </label>
                <label>
                    <input
                        type="radio"
                        name="display"
                        value='page'
                        checked={lightBoxPayload?.display === 'page'}
                        onChange={handleChange}
                    />
                    <span>After</span>
                    <input
                        type="number"
                        name="show_on_page"
                        value={lightBoxPayload?.show_on_page ? lightBoxPayload?.show_on_page : ''}
                        onChange={handleChange}
                        className="input-small"
                    />
                    <span>sec on page:</span>
                    <input
                        type="number"
                        name="show_after"
                        value={lightBoxPayload?.show_after ? lightBoxPayload?.show_after : ''}
                        onChange={handleChange}
                        className="input-small" />
                </label>
                <label>
                    <input
                        type="radio"
                        name="display"
                        checked={lightBoxPayload?.display === 'doc'}
                        value='doc'
                        onChange={handleChange}
                    />
                    On entry
                </label>
                <label>
                    <input
                        type="radio"
                        name="display"
                        value='onexit'
                        checked={lightBoxPayload?.display === 'onexit'}
                        onChange={handleChange}
                    />
                    On exit intent
                </label>
                <label>
                    <input
                        type="radio"
                        name="display"
                        checked={lightBoxPayload?.display === 'on_hide_reveal'}
                        value='on_hide_reveal'
                        onChange={handleChange}
                    />
                    On Hide/Reveal Action
                </label>
                <label>

                </label>
                <div className="toggle-container mt-5">
                    <label>High Priority Element: </label>
                    <input type="checkbox"
                        id="toggle-set"
                        className="toggle-input"
                        name="priority"
                        value={lightBoxPayload?.priority}
                        checked={lightBoxPayload?.priority}
                        onChange={handleChange}
                    />
                    <label htmlFor="toggle-set" className="toggle-btn"></label>
                </div>
            </div>
        </>
    )
}
export default LightBoxTrigger