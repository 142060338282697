import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import AdminOrgPage from "./Pages/Admin/AdminOrgPage";

function AdminOrganisation() {
    return (
        <>
            <Layout>
                <AdminOrgPage />
            </Layout>
        </>
    )
}
export default AdminOrganisation;


