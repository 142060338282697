import React from 'react';
import { Form } from 'react-bootstrap';

const CustomInput = ({
    value,
    onChange,
    placeholder,
    name,
    error,
    required,
    type,
    ...props
}) => {
    return (
        <Form.Group>
            <Form.Control
                placeholder={placeholder}
                value={value}
                name={name}
                onChange={onChange}
                required={required}
                type={type || 'text'}  // Default to 'text' if no type is provided
                isInvalid={!!error}
                {...props}
            />
            {error && <Form.Text className="text-danger">{error}</Form.Text>}
        </Form.Group>
    );
};

export default CustomInput;
