import React, { useEffect, useState } from "react";
import Breadcrumb from "../../CommonComponents/BreadCrumb/BreadCrumb";
import apiGETRequest from "../../../utis/apiRequest";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import customAPIRequest from "../../../utis/customAPIRequest";
import CommonModal from "../../Modals/CommonModal";
import Button from "../../Elements/Button";

function buildQuery(obj, prefix) {
    const queryString = Object.keys(obj).map(key => {
      const value = obj[key];
      const fullKey = prefix ? `${prefix}[${key}]` : key;
  
      if (value !== null && typeof value === 'object') {
        return buildQuery(value, fullKey);
      } else {
        return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
      }
    });
  
    return queryString.join('&');
}

const Users = () => {
    const [users, setUsers] = useState({});
    const [refetch, setRefetch] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const getUsers = async () => {
        const params = {
            start: 0,
            length: 10,
            draw: 1,
            search: { value: '', regex: false },
            _: Date.now()
        };
        const queryString = buildQuery(params);
        const users = await apiGETRequest(`admin/users_listing?${queryString}`);
        setUsers({...users});
    }

    const handleDelete = async () => {
        try {
            const response = await customAPIRequest(`admin/users/${selectedUser.id}.json`, 'DELETE', {});
            if (response.status == 200) {
                setSelectedUser(null);
                setDeleteModal(false);
                setRefetch(true);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    const impersonateUser = async (id) => {
        const formData = new FormData();
        formData.append('_method', 'post');
        formData.append('authenticity_token', csrfToken());
        try {
            const response = await fetch(`/admin/impersonate/${id}`, {
                method: 'POST',
                body: formData,
                cookie: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
            });
            if (response?.ok) {
                window.location.href = '/';
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    useEffect(() => {
        if(refetch) {
            getUsers();
            setRefetch(false);
        }
    }, [refetch]);

    return (
        <>
            <Breadcrumb string={'Admin/Users'} />
            <div className="container">
                <div className="show-select1 d-flex ">
                    <div className="d-flex align-items-center">
                        <p>Show</p>
                        <div className="custom-select-container me-2 ms-2 ali">
                            {/* <!-- <label htmlFor="custom-select">Choose an option:</label> --> */}
                            <select id="custom-select" className="custom-select">
                                <option value="option1"> 10</option>
                                <option value="option2"> 25</option>
                                <option value="option3"> 50</option>
                                <option value="option4"> 100</option>
                            </select>
                        </div>
                        <p>entries</p>
                        <div className="custom-select-container me-2 ms-4 ali">
                            {/* <!-- <label htmlFor="custom-select">Choose an option:</label> --> */}
                            <select id="custom-select" className="select-action">
                                <option value="option1"> Select Action </option>
                                <option value="option2"> Delete Seleted</option>
                                <option value="option3"> Resend Signup Verification</option>
                            </select>
                        </div>
                    </div>

                    <div className="Search-inp">
                        <div>
                            <label>
                                Search :
                            </label>
                            <input placeholder="Search..." />
                        </div>
                    </div>
                </div>
                <div className="top-parent">
                    <table className="Subscription-table mt-4">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th className="hov-clr">Last Login</th>
                                <th className="hov-clr">Country</th>
                                <th className="hov-clr">PP Acceptance on</th>
                                <th className="hov-clr">ToS Acceptance on</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.data?.map((user) => (
                                <tr key={user.id}>
                                    <td>
                                        <input type="checkbox" />
                                    </td>
                                    <td>
                                        {user.name}
                                    </td>
                                    <td>
                                        {user.email}
                                    </td>
                                    <td className="hov-clr">
                                        {user.last_login}
                                    </td>
                                    <td className="hov-clr">
                                        {user.country}
                                    </td>
                                    <td className="hov-clr">
                                        {user.pp_acceptance_on}
                                    </td>
                                    <td className="hov-clr">
                                        {user.tos_acceptance_on}
                                    </td>
                                    <td className="hov-clr users-icons">
                                        <span onClick={() => impersonateUser(user.id)}><i className="fa-solid fa-user-group me-2"></i></span>
                                        <a onClick={() => {
                                            setDeleteModal(true);
                                            setSelectedUser(user);
                                        }}><i className="fa-solid fa-circle user-active"></i></a>
                                    </td>
                               </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="showing-main d-flex justify-content-between mt-3">
                    <div className="showing-para">
                        <p>Showing 1 to 10 of 3,837 entries</p>
                    </div>
                    <div>
                        <button className="previous-btn me-2">Previous</button>
                        <span className="count-btn me-2">1</span>
                        <span className="count-btn me-2">2</span>
                        <span className="count-btn me-2">3</span>
                        <span className="count-btn me-2">4</span>
                        <span className="count-btn me-2">5</span>
                        <span className="count-btn me-2">...</span>
                        <span className="count-btn me-2">384</span>
                        <button className="previous-btn">Next</button>
                    </div>
                </div>
            </div>
            <CommonModal 
                title="Delete User"
                modalStatus={deleteModal}
                changeActiveStatus={() => {setDeleteModal(false)}}
                centered
                classList={'upload-kdf-modal-wrapper alert-modal'}
                footer={
                    <div className="d-flex m-auto">
                        <Button text={'No'} classList={'alert delete-btn'} addonClass={'my-2 mx-2'} onClick={() => {setDeleteModal(false)}} />
                        <Button text={'Yes'} classList={'success delete-btn'} addonClass={'my-2 mx-2'} onClick={handleDelete} />
                    </div>
                }
            >
                <p style={{fontSize:'14px'}}>
                    Are you sure you want to delete {selectedUser?.name || selectedUser?.email}
                </p>
            </CommonModal>
        </>
    )
}
export default Users;
