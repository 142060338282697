import React from "react";
const PopupOptions = () => {
    return (
        <>
            <div className="Enable-select">
                <div className="enable-first">
                    <div className="d-flex">
                        <input className="me-2" type="checkbox" />
                        <p>Enable EnterPop</p>
                    </div>

                    <div className="d-flex align-items-center for-space">
                        <div>
                            On entry <i className="fa-solid fa-eye ms-3"></i>
                        </div>
                        <div>
                            <div className="custom-select-container mb-2   ali">
                                <div className="d-flex mb-2">
                                    <input className="me-2" type="checkbox" />
                                    <p> Set as default</p>
                                </div>
                                <select id="custom-select" className="custom-select select-width">
                                    <option value="option1">Hello
                                    </option>
                                    <option value="option2"> option 1</option>
                                    <option value="option3"> option 2</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center for-space">
                        <div>
                            Device
                        </div>
                        <div>
                            <div className="custom-select-container mb-2 ms-5 ali">
                                <select id="custom-select" className="custom-select select-width">
                                    <option value="option1">On All Devices
                                    </option>
                                    <option value="option2"> option 1</option>
                                    <option value="option3"> option 2</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center  for-space">
                        <div>
                            Frequency
                        </div>
                        <div>
                            <div className="custom-select-container mb-2 ms-4 ali">
                                <select id="custom-select" className="custom-select select-width">
                                    <option value="option1">Each Visit
                                    </option>
                                    <option value="option2"> option 1</option>
                                    <option value="option3"> option 2</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="Enable-select">
                <div className="enable-first">
                    <div className="d-flex">
                        <input className="me-2" type="checkbox" />
                        <p> Enable TimedPop   </p>
                    </div>

                    <div className="d-flex align-items-center for-space">
                        <div>
                            Timed
                        </div>
                        <div>
                            <div className="custom-select-container mb-2  ms-5 ali">
                                <div className="d-flex mb-2">
                                    <input className="me-2" type="checkbox" />
                                    <p> Set as default</p>
                                </div>
                                <select id="custom-select" className="custom-select select-width">
                                    <option value="option1">Select  a Timepop Lightbox
                                    </option>
                                    <option value="option2"> option 1</option>
                                    <option value="option3"> option 2</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center for-space">
                        <div>
                            Device
                        </div>
                        <div>
                            <div className="custom-select-container mb-2 ms-5 ali">
                                <select id="custom-select" className="custom-select select-width">
                                    <option value="option1">On All Devices
                                    </option>
                                    <option value="option2"> option 1</option>
                                    <option value="option3"> option 2</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center for-space mb-2">
                        <div>
                            After
                        </div>
                        <div>
                            <div className="d-flex align-items-center ms-3 laser-inp">
                                <input className="edit-value-inp ms-1 me-1" value="22" />
                                <p>seconds on page</p>
                                <input className="edit-value-inp ms-1 me-1" value="22" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center  for-space">
                        <div>
                            Frequency
                        </div>
                        <div>
                            <div className="custom-select-container mb-2 ms-4 ali">
                                <select id="custom-select" className="custom-select select-width">
                                    <option value="option1">Once
                                    </option>
                                    <option value="option2"> option 1</option>
                                    <option value="option3"> option 2</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="Enable-select">
                <div className="enable-first">
                    <div className="d-flex">
                        <input className="me-2" type="checkbox" />
                        <p> Enable ExitPop                            </p>
                    </div>

                    <div className="d-flex align-items-center for-space">
                        <div>
                            On exit intent
                        </div>
                        <div>
                            <div className="custom-select-container mb-2   ali">
                                <div className="d-flex mb-2">
                                    <input className="me-2" type="checkbox" />
                                    <p> Set as default</p>
                                </div>
                                <select id="custom-select" className="custom-select select-width">
                                    <option value="option1">Select an Exitpop Lightbox
                                    </option>
                                    <option value="option2"> option 1</option>
                                    <option value="option3"> option 2</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center for-space">
                        <div>
                            Device
                        </div>
                        <div>
                            <div className="custom-select-container mb-2 ms-5 ali">
                                <select id="custom-select" className="custom-select select-width">
                                    <option value="option1">On All Devices
                                    </option>
                                    <option value="option2"> option 1</option>
                                    <option value="option3"> option 2</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center  for-space">
                        <div>
                            On scroll up

                        </div>
                        <div>
                            <div className="custom-select-container mb-2 ms-4 ali">
                                <select id="custom-select" className="custom-select select-width">
                                    <option value="option1">On desktop once
                                    </option>
                                    <option value="option2"> option 1</option>
                                    <option value="option3"> option 2</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
export default PopupOptions;