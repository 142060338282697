import React, { useEffect, useState } from 'react';
import './GifSections.css'
import apiGETRequest from '../../../../../../../../utis/apiRequest';

const TenorSection = ({ tool, handleSelectGifs }) => {
    const [tenorData, setTenorData] = useState([])
    const [search, setSearch] = useState("")

    useEffect(() => {
        const fetchGifsTenor = async () => {
            try {
                const gifList = await apiGETRequest(`editor/get-gifs-tenor/${search !== '' ? search : 'excited'}`);
                setTenorData(gifList?.data)
            } catch (error) {
                console.error('Error fetching tenors:', error);
            }
        };
        fetchGifsTenor();
    }, [search])

    return (
        <div className='GIFs-main'>
            <div className="Gif-inp mb-2">
                <input
                    placeholder="Search GIFs via Tenor"
                    onChange={(e) => setSearch(e?.target?.value)}
                    value={search}
                />
            </div>
            <div className='gifs-items'>
                <div className='row flex-wrap'>
                    {
                        tool?.gif_type && tool?.gif_type === 'tenor' && tool?.value?.gif_link ?
                            <img
                                src={tool?.value?.gif_link}
                                alt='gif link'
                                style={{ height: "200px", width: "200px" }}
                            /> :
                            tenorData?.length > 0 && tenorData?.map((gif, index) => {
                                return (
                                    <div
                                        className='col-4'
                                        key={index}
                                        onClick={() => handleSelectGifs('tenor', gif)}>
                                        <img style={{ height: "200px", width: "200px" }} src={gif} alt='gif link' />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
        </div>
    );
};

export default TenorSection;