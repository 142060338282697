import React, { useState } from "react"
import LaserLinxSettings from "./LaserLinxSettings"
import AccessGateOptions from "./AccessGateOptions"
import PopupOptions from "./PopupOptions"
import ModalLayout from "../ModalLayout"
const CreateLaserLinksModal = ({ OpenLaserModal, closeModal }) => {
    const [currentTab, setCurrentTab] = useState('laserSeting')
    const LaserLinxBody = () => {
        return (
            <>
                <div className="laser-main">
                    <div className="laser-popup-pages">
                        <div className="laser-option-main d-flex">
                            <div className="laser-options me-3" >
                                <button onClick={() => setCurrentTab('laserSeting')}>
                                    LaserLINK Settings
                                </button>
                            </div>
                            <div className="laser-options me-3" >
                                <button onClick={() => setCurrentTab('accessGate')}>
                                    Access Gate Options
                                </button>
                            </div>
                            <div className="laser-options">
                                <button onClick={() => setCurrentTab('popup')}>
                                    Popup Options
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {currentTab == 'laserSeting' ?
                    <LaserLinxSettings /> :
                    currentTab == 'accessGate' ?
                        <AccessGateOptions /> :
                        currentTab == 'popup' && <PopupOptions />}
                <div className="laser-link-btn mt-2">
                    <div className="lower-link d-flex">
                        <p>https://delivered-by.kinetizine.com/MTcyMzQ1NDEyMA/en-US </p><i className="fa-regular fa-copy ms-2 "></i>
                    </div>
                    <div className="laser-btn">
                        <button>Embed</button>
                        <button>Save Changes</button>
                    </div>
                </div>
            </>
        )
    }
    return (
        <ModalLayout
            openModal={OpenLaserModal}
            title={<input className="form-control" value="nitin" />}
            children={<LaserLinxBody />}
            closeModal={closeModal}
            modalWidth={'900px'}
        />
    )
}
export default CreateLaserLinksModal