import React, { useState, useRef, useContext, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import TagEditorSection from "../TagEditorSection";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import useCanvasHook from "../../CanvasSection/CanvasHook";
import PdfElement from "../pdfEditorElements/PdfElement";

const PdfViewer = ({ pdfFile, zoom, docFileId, docId, editortype, docFiles }) => {
    const {
        editEnabled,
        setOpenAddElement,
        rectangles,
        geo,
        elements,
        setRectangles,
        setActiveRectangleIndex,
        setGeo,
        setElements,
        pageNumber,
        setPageNumber,
    } = useContext(EditorInfoContext);
    const { draw, normalizeDimensions, createDiv } = useCanvasHook();
    const [resizeDimensions, setResizeDimensions] = useState({});
    const containerRef = useRef(null);
    const canvasRefs = useRef([]);
    const [numPages, setNumPages] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const isScrolling = useRef(false);
    const isManualPageChange = useRef(false);
    const debounceTimeout = useRef(null);
    const [startCoords, setStartCoords] = useState(null);
    const [actionElement, setActionElement] = useState({
        targetElement: 0,
        visibility: true,
    });

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setRectangles(new Array(numPages).fill([]));
    };

    const handleMouseDown = (event, index) => {
        const rect = canvasRefs.current[index].getBoundingClientRect();

        setIsDrawing(true);
        const startCoords = {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top,
        };
        setStartCoords(startCoords);

        const newRectangles = [...rectangles];
        newRectangles[index] = [
            ...newRectangles[index],
            { startCoords, currentCoords: startCoords, media: null },
        ];

        setRectangles(newRectangles);
        setActiveRectangleIndex(newRectangles[index].length - 1);
    };

    const handleMouseMove = (event, index) => {
        if (!isDrawing) return;

        const rect = canvasRefs.current[index].getBoundingClientRect();
        const currentX = event.clientX - rect.left;
        const currentY = event.clientY - rect.top;

        const newRectangles = [...rectangles];
        const currentRectangles = newRectangles[index];
        const lastRectangle = currentRectangles[currentRectangles.length - 1];

        lastRectangle.currentCoords = { x: currentX, y: currentY };
        setRectangles(newRectangles);

        draw(index, canvasRefs);
    };

    const handleMouseUp = (e, index) => {
        if (!isDrawing) return;
        setIsDrawing(false);
        setOpenAddElement(true);

        rectangles?.filter(
            (elem, index) =>
                elem?.length > 0 &&
                elem?.map((element, idx) => {
                    setGeo({
                        ...geo,
                        left: element?.startCoords?.x,
                        top: element?.startCoords?.y,
                        width: element?.currentCoords?.x - element?.startCoords?.x,
                        height: element?.currentCoords?.y - element?.startCoords?.y,
                        page: index + 1,
                    });
                })
        );

        const canvas = canvasRefs?.current[index];
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const width = x - startCoords.x;
        const height = y - startCoords.y;

        const newRect = { x: startCoords.x, y: startCoords.y, width, height };
        createDiv(newRect, index, canvasRefs);

        // Clear the canvas
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
    };

    const arrayOfElements = Object.values(
        Object.entries(elements).length !== 0 && elements
    );

    const handleAction = (element) => {
        const { desiredaction } = element?.dropdown_data;
        if (element?.dropdown_data) {
            setActionElement({
                ...actionElement,
                targetElement: element?.dropdown_data?.thisElement,
                visibility:
                    desiredaction === "hide"
                        ? false
                        : desiredaction === "reveal"
                            ? true
                            : !actionElement?.visibility,
            });
        }
    };

    const onScroll = () => {
        if (isManualPageChange.current) {
            clearTimeout(debounceTimeout.current);
            debounceTimeout.current = setTimeout(() => {
                isManualPageChange.current = false;
            }, 30);
            return;
        }
        const wrapper = containerRef.current;
        const pageHeight = wrapper.scrollHeight / numPages;
        const scrollPosition = wrapper.scrollTop + wrapper.clientHeight / 2;
        const newPage = Math.ceil(scrollPosition / pageHeight);
        if (newPage !== pageNumber && newPage > 0 && newPage <= numPages) {
            isScrolling.current = true;
            setPageNumber(newPage);
            setTimeout(() => {
                isScrolling.current = false;
            }, 10);
        }
        clearTimeout(debounceTimeout.current);
    };

    useEffect(() => {
        const wrapper = containerRef.current;
        if (wrapper && numPages > 1) {
            wrapper.addEventListener("scroll", onScroll);
        }

        return () => {
            if (wrapper) {
                wrapper.removeEventListener("scroll", onScroll);
            }
        };
    }, [numPages, pageNumber]);

    useEffect(() => {
        if (!isScrolling.current) {
            const pageElement = containerRef.current?.querySelector(
                `.react-pdf__Page[data-page-number="${pageNumber}"]`
            );

            if (pageElement) {
                isManualPageChange.current = true;
                pageElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [pageNumber]);

    useEffect(() => {
        setElements(window.objInit?.elements);
    }, []);

    return (
        <div
            className="parent_doc_wrapper"
            style={{ height: "100vh", overflowY: "scroll", marginTop: "20px" }}
            ref={containerRef}
        >
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                    <div
                        className="d-flex mb-2"
                        key={index + 1}
                        style={{ ...(editortype !== 'editor' && { display: 'flex', justifyContent: 'center' }) }}
                    >
                        {editortype !== 'publish' &&
                            <TagEditorSection
                                docFileId={docFileId}
                                docId={docId}
                                docFiles={docFiles}
                                currentPage={index + 1}
                            />}

                        <div
                            style={{
                                position: "relative",
                                cursor: `${editEnabled ? "crosshair" : "default"}`,
                                // overflow: "hidden"
                            }}
                        >
                            <Page
                                id={`'pageContainer'${+index + 1}`}
                                pageNumber={index + 1}
                                scale={parseFloat(zoom?.value)}
                                onLoadSuccess={({ width, height }) => {
                                    let canvas = canvasRefs.current[index];
                                    canvas.width = width;
                                    canvas.height = height;
                                }}
                                inputRef={(ref) => {
                                    if (ref && pageNumber === index + 1) {
                                        ref.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                            />
                            <canvas
                                ref={(el) => (canvasRefs.current[index] = el)}
                                id={index + 1}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    zIndex: 2,
                                    pointerEvents: "auto",
                                }}
                                onMouseDown={(e) => {
                                    editEnabled &&
                                        (handleMouseDown(e, index),
                                            setActiveRectangleIndex({
                                                pageIndex: index,
                                                rectIndex: rectangles[index].length,
                                            }));
                                }}
                                onMouseMove={(e) => editEnabled && handleMouseMove(e, index)}
                                onMouseUp={(e) => editEnabled && handleMouseUp(e, index)}
                            />

                            {arrayOfElements &&
                                arrayOfElements?.map((element) => {
                                    let dimensions = {
                                        top: normalizeDimensions(
                                            element?.geometry?.top,
                                            1,
                                            zoom?.value
                                        ),
                                        left: normalizeDimensions(
                                            element?.geometry?.left,
                                            1,
                                            zoom?.value
                                        ),
                                        height: normalizeDimensions(
                                            element?.geometry?.height,
                                            1,
                                            zoom?.value
                                        ),
                                        width: normalizeDimensions(
                                            element?.geometry?.width,
                                            1,
                                            zoom?.value
                                        ),
                                    };
                                    return (
                                        !!element?.geometry?.top && !!element?.geometry?.left && !!element?.geometry?.height && !!element?.geometry?.top &&
                                        index + 1 === element?.page && (
                                            <div
                                                key={element?.id}
                                                style={{
                                                    position: "absolute",
                                                    userSelect: 'none',
                                                    zIndex: 99,
                                                    top: `${resizeDimensions[element.id]?.top || dimensions.top}px`,
                                                    left: `${resizeDimensions[element.id]?.left || dimensions.left}px`,
                                                    width: `${resizeDimensions[element.id]?.width || dimensions.width}px`,
                                                    height: `${resizeDimensions[element.id]?.height || dimensions.height}px`,
                                                    ...(Number(element?.id) ===
                                                        Number(actionElement?.targetElement) && {
                                                        visibility: `${actionElement?.visibility ? "visible" : "hidden"}`,
                                                    }),
                                                    transform: `rotate(${element?.rotate ?? 0}deg) scaleX(${element?.flip ?? 1})`
                                                }}
                                                onClick={() => element?.dropdown_data && handleAction(element)}
                                            >
                                                <PdfElement
                                                    element={element}
                                                    canvasRef={canvasRefs?.current[index]}
                                                    zoom={zoom}
                                                    dimensions={dimensions}
                                                    setPageNumber={setPageNumber}
                                                    editortype={editortype}
                                                    setResizeDimensions={setResizeDimensions}
                                                    resizeDimensions={resizeDimensions}
                                                />
                                            </div>
                                        )
                                    );
                                })}
                        </div>
                    </div>
                ))
                }
            </Document >
        </div >
    );
};

export default PdfViewer;
