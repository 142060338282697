import React, { useState } from "react"
import Input from "../../Elements/Input";
import Button from "../../Elements/Button";
import { Col, Container, Row } from "react-bootstrap";
import { csrfToken } from "../../../utis/csrfTokenUtils";

const CreateNewKdf = ({ props }) => {
    const [formDocTitle, setFormDocTitle] = useState('');
    return (
        <>
            <Container>
                <Row>
                    <Col md={4}>
                        <form action="/kdfs" className="new-kdf" method="post">
                            <input type='hidden' name='authenticity_token' value={csrfToken()} />
                            <input type='hidden' name="doc[user_id]" value={props?.user_id} />
                            <Input 
                                classList=''
                                type='text'
                                placeholder='Title...' 
                                name='doc[title]' 
                                required={true}
                                label='Enter a title for your new KDF:'
                                onChange={(e) => setFormDocTitle(e.target.value)}
                                value={formDocTitle}
                            />
                            <div className="text-end">
                                <Button classList={'alert'} addonClass="d-inline-flex mx-2" text={'Cancel'} />
                                <Button classList={formDocTitle == '' ? 'disabled' : 'success'} disabled={formDocTitle == ''} addonClass="d-inline-flex mx-2" text={`Continue`} type="submit" />
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default CreateNewKdf