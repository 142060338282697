import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";


const ToolBoxHeading = () => {
    return (
        <div className='editorToolbox__header d-flex align-items-center justify-content-between'>
            <div>
                <p>
                    Editor Toolbox
                </p>
            </div>
            <div>
                <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            </div>
        </div>
    )
}
export default ToolBoxHeading