import React, { useEffect, useState } from "react"
import ModalLayout from "./ModalLayout"
import DragDropComponent from "../CommonComponents/DragDropComponent/DragDropComponent"
import CommonModal from "./CommonModal";
import './UploadKdfModal.css';
import { quality_data } from "../../hooks/utils";
import apiGETRequest from "../../utis/apiRequest";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import Select from "../Elements/Select";
import Button from "../Elements/Button";
import { csrfToken } from "../../utis/csrfTokenUtils";
const UploadKdfModal = ({ openUploadModal, closeModal, userId, docId, refreshPDFList, selectedFileID = null }) => {
    const [progressCount, setProgressCount] = useState(0);
    const [file, setFile] = useState('');
    const [payload, setPayload] = useState(
        {
            language: '',
            quality: '',
        }
    );

    const [languages, setLanguages] = useState([]);
    // const [languageSelected, setLanguageSelected] = useState(''); 
    const handleSelect = ((e) => {
        setPayload(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    })

    const UploadKdf = async () => {
        setProgressCount(10);
        const formData = new FormData();
        formData.append('authenticity_token', csrfToken());
        formData.append('docs_file[doc_id]', docId);
        formData.append('docs_file[user_id]', userId);
        formData.append('docs_file[file_cache]', '');
        formData.append('docs_file[language]', payload.language != '' ? payload.language : 'en-US');
        formData.append('docs_file[quality]', payload.quality);
        formData.append('docs_file[file]', file[0]);
        if(selectedFileID != null) {
            formData.append('_method', 'patch');
        }

        try {
            setProgressCount(50);
            const response = await fetch(selectedFileID != null ? `/docs/${docId}/files/${selectedFileID}.json` : `/docs/${docId}/files.json`, {
                method: 'POST',
                body: formData,
                cookie: {
                    'X-CSRF-Token': csrfToken(),
                },
            });
            if (response.ok) {
                setProgressCount(100);
                closeModal(false);
                refreshPDFList(true);
                // window.location.reload();
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setProgressCount(0);
        }
    }
    const handleOnchangeEvent = (e) => {
        setPayload(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }


    // const [modalOpenStatus, setModalOpenStatus] = useState(false);
    useEffect(() => {
        // console.log('openUploadModal ', openUploadModal)
        // setModalOpenStatus(openUploadModal);
        // if(openUploadModal == true){
            const fetchLanguages = async () => {
                try {
                    const languages = await apiGETRequest(`docs/${docId}/languages.json`);
                    const languageArray = Object.values(languages);
                    setLanguages(languageArray);
                    setPayload({'language':'en-US', 'quality': quality_data()[0].value ?? ''})
                } catch (error) {
                    // console.error('Error fetching languages:', error);
                }
            };
            fetchLanguages();
        // }
    }, []);


    // const UploadKDFBody = () => {
    //     return (

    //     )
    // }

    return (
        <>

            {/* <UploadKDFBody /> */}

            <div className="domain-main">
                {!file ?
                    <DragDropComponent setFile={setFile} /> :
                    <Container >
                        <Row>
                            <Col md={4}>
                                <p>
                                    {file[0]?.path}
                                </p>
                                <p>
                                    {(file[0]?.size / 1024)?.toFixed(2)} KB
                                </p>
                            </Col>
                            <Col md={4}>
                                <Select
                                    classList=''
                                    name={'language'}
                                    required={true}
                                    onChange={(e) => handleOnchangeEvent(e)}
                                    defaultValue={payload.language}
                                    optionList={
                                        languages?.map(lan => {
                                            return {
                                                'value': lan.locale,
                                                'title': lan.name
                                            }
                                        })
                                    }
                                // errorMessageStatus={item?.error != undefined ? item.error : false}
                                />
                            </Col>
                            <Col md={4}>
                                <Select
                                    classList=''
                                    name={'quality'}
                                    required={true}
                                    onChange={(e) => handleOnchangeEvent(e)}
                                    defaultValue={payload.quality}
                                    optionList={
                                        quality_data()?.map(quality => {
                                            return {
                                                'value': quality.value,
                                                'title': quality.name
                                            }
                                        })
                                    }
                                // errorMessageStatus={item?.error != undefined ? item.error : false}
                                />
                            </Col>
                        </Row>
                        <Col className="text-center">
                            {progressCount > 0 && <ProgressBar className={`my-4`} now={progressCount} label={`${progressCount}%`} striped variant="success" />}
                            <Button text={'Upload'} classList="success" addonClass="mb-4 px-4" onClick={UploadKdf} />
                        </Col>
                    </Container>
                }
            </div>
        </>
    )
};
export default React.memo(UploadKdfModal)