import React, { useState } from 'react';
import kz_logo from '../../../../assets/images/kz_logo.png';
import SupportModal from '../../Modals/SupportModal';
import { Navbar, Nav, Container, Accordion } from 'react-bootstrap';


const SideBar = () => {
  const [openSupport, setOpenSupport] = useState(false);
  return (
    <>
      {openSupport && <SupportModal openSupport={openSupport} setOpenSupport={setOpenSupport} />}
        <Navbar expand="lg" className="bg-body-tertiary p-0 d-block">
          <Container fluid className='bg-white p-0'>
              <Navbar.Brand className='m-auto cursor-pointer'><img src={kz_logo} alt="kz_logo" className='img-fluid'/></Navbar.Brand>
          </Container>
          <Container className='p-0 m-0'>
            <Nav defaultActiveKey="/home" className="flex-column w-100 sidebar-nav">
              <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header  className='admin-sidebar'>Admin</Accordion.Header>
                  <Accordion.Body className='p-0'>
                    <Nav.Link href="/admin/orgs">Organizations</Nav.Link>
                    <Nav.Link href="/admin/users"> Users</Nav.Link>
                    <Nav.Link href="/admin/subscriptions">Subscriptions</Nav.Link>
                    <Nav.Link href="/admin/potential_bots">Potential Bots</Nav.Link>
                    <Nav.Link href="/admin/customization_log">Customization Logs</Nav.Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Nav.Link href="/kdfs">KDFs</Nav.Link>
              <Nav.Link href="/recipients"> Recipients</Nav.Link>
              <Nav.Link href="/links"> LaserLINX</Nav.Link>
              <Nav.Link> Support</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  };

export default SideBar;
