import React from "react"
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout"
import KdfSettings from "./Pages/Kdf/EditKdf/KdfSettings"

const KdfSettingsPage = (props) => {
    return (
        <>
            <Layout header={'KDF settings'}>
                <KdfSettings docs={props} />
            </Layout>
        </>
    )
}
export default KdfSettingsPage