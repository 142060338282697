import { default as Axios } from 'axios';
import { csrfToken } from '../../utis/csrfTokenUtils';

const axios = Axios.create({
    baseURL: '/'
});

export const networkService = (url, method, data) => {
    try {
        const headers = {
            'X-CSRF-Token': csrfToken(),
            'Content-Type': 'application/json'
        };
        if (data) {
            data = JSON.stringify({ ...data, 'authenticity_token': csrfToken() });
        }
        return axios({
            url: url,
            method: method,
            headers: headers,
            ...(data && { data: data }),
        });
    } catch (error) {
        console.error('Error during sign out:', error);
    }
};