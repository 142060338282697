import { faEye, faPencil, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { networkService } from "../../../../../../config/axios";
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext";
import ViewLightBox from "./ViewLightBox";

const LightboxListing = ({ docFileId, setKey, setEditLightBox, resetLightboxState }) => {
    const { setOpenRemoveModal, lightBoxList, setLightBoxList } = useContext(EditorInfoContext)

    const [loading, setLoading] = useState(false);
    const [showLightBox, setShowLightBox] = useState(null)


    useEffect(() => {
        const fetchLightBoxList = async () => {
            setLoading(true)
            try {
                let list = await networkService(`docs/all-lightboxes/${docFileId}.json?by_trigger=no`, 'GET');
                const arrayOfLightbox = Object.values(
                    Object.entries(list?.data).length !== 0 && list?.data
                );
                setLightBoxList(arrayOfLightbox);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching lightbox list:", error);
                setLoading(false);
            }
        };
        fetchLightBoxList()
    }, []);

    return (
        <>
            <ViewLightBox setShowLightBox={setShowLightBox} showLightBox={showLightBox} />
            <div className="content-box">
                <table className="info-table">
                    <thead>
                        <tr>
                            <th><FontAwesomeIcon
                                className="success-green fs-4 cursor-pointer"
                                icon={faPlus}
                                onClick={() => {
                                    resetLightboxState()
                                    setKey('setting')
                                }}
                            />
                            </th>
                            <th>Lightbox Name</th>
                            <th>Trigger</th>
                            <th>Reveal After</th>
                            <th>on Page</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr>
                                <td colSpan="6">
                                    <div className="spinner-wrapper">
                                        <div className="spinner-grow text-secondary spinner-text" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            :
                            lightBoxList.length > 0 ? lightBoxList?.map((lightbox, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>
                                            <FontAwesomeIcon
                                                className="success-green cursor-pointer"
                                                icon={faPencil}
                                                onClick={() => {
                                                    setEditLightBox(lightbox)
                                                    setKey('setting')
                                                }}
                                            /></td>
                                        <td>{lightbox?.name ?? '---'}</td>
                                        <td>{lightbox?.display}Pop</td>
                                        <td>{lightbox?.show_after ?? '---'}</td>
                                        <td>{lightbox?.show_on_page ?? '---'}</td>
                                        <td>
                                            <div className="d-flex">
                                                <FontAwesomeIcon
                                                    className="me-3 cursor-pointer"
                                                    icon={faEye}
                                                    onClick={() => setShowLightBox(lightbox?.elements)}
                                                />
                                                <FontAwesomeIcon
                                                    className="me-2 cancel cursor-pointer"
                                                    icon={faTrashCan}
                                                    onClick={() => {
                                                        setOpenRemoveModal(true)
                                                        setEditLightBox(lightbox)
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : <div>No LightBox available</div>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default LightboxListing;