import React, { useContext, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import LightBoxSetting from "./LightBoxSetting"
import LightboxListing from "./LightBoxListing"
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext"

const LightBoxSection = ({ docFileId, docId }) => {
    const { setEditLightBox } = useContext(EditorInfoContext);

    const [key, setKey] = useState('list');

    const resetLightboxState = () => {
        setEditLightBox({})
    }

    const handleSelect = (selectedKey) => {
        if (selectedKey === 'setting') {
            resetLightboxState()
        }
        setKey(selectedKey);
    };


    return (
        <div className={key === 'list' ? "ps-4" : ''}>
            {key === 'setting' && <div className="lightbox-setting-para">
                <p>
                    Configure the parameters of a lightbox below so that they trigger appropriately for your viewers.
                    Lightboxes can trigger when viewers click on an image or hotspot. They can also trigger after a given
                    period of time spent viewing the KDF or on a specific page of your doc.
                </p>
                <p>
                    Please republish this KDF after modifying a lightbox
                </p>
            </div>}
            <Tabs
                activeKey={key}
                onSelect={handleSelect}
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="list" title={'List'}>
                    <LightboxListing
                        docFileId={docFileId}
                        setKey={setKey}
                        resetLightboxState={resetLightboxState}
                        setEditLightBox={setEditLightBox}
                    />
                </Tab>
                <Tab eventKey="setting" title={'Settings'}>
                    <LightBoxSetting
                        docFileId={docFileId}
                        docId={docId}
                    />
                </Tab>
            </Tabs>
        </div>
    )
}
export default LightBoxSection