import React from "react"

const FilterButtons = () => {
    return (
        <div className="d-flex">
            <button className="btn btn-info margin-left-15 reset">
                Apply Filters
            </button>
            <button className="reset-btn">
                Reset Filters
            </button>

        </div>
    )
}
export default FilterButtons