import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './dropDown.css';
const DropDown = ({title, optionList, customClassList, classList, varient}) => {
    return(
        <Dropdown className={customClassList.trim().split(' ').map(item => `custom-dropdown-${item}`).join(' ') +' '+ classList}>
            <Dropdown.Toggle varient={varient}>
            {title}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    optionList.map((item, index) => <Dropdown.Item key={index} href={item.url}>{item.title}</Dropdown.Item>)
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}
export default DropDown;