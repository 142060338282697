import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="panel-footer text-center ng-scope" data-ng-controller="PolicyLauncher as policyLauncher">
        On <span id="ppAcceptanceDate">2024-08-19 at @ 11:25:02</span> you accepted our <a data-ng-click="policyLauncher.launchModal('terms', undefined, true);" href="">Terms of Service</a>.
        <div>
          Copyright © 2024 Box Ventures LLC. All rights reserved.
          <a className="privacy" target="_blank" href="">Privacy</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
