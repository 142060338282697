import React from "react"
import LightBoxSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/lightBoxSection/LightBoxSection"
import ElementModal from "../../Pages/Kdf/EditKdf/elementModal/ElementModal"

const LightBoxModal = ({ openLightboxModal, docFileId, docId ,setOpenLightBox}) => {
    return (
        <ElementModal
            openModal={openLightboxModal}
            heading={'Lightbox options'}
            handleCloseModal={setOpenLightBox}
            z_index={'101'}
        >
            <LightBoxSection
                docFileId={docFileId}
                docId={docId}
            />
        </ElementModal>
    )
}
export default LightBoxModal