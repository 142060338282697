import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import AdminUsersPage from "./Pages/Admin/AdminUsersPage";

function AdminUsers() {
    return (
        <>
            <Layout>
                <AdminUsersPage />
            </Layout>
        </>
    )
}
export default AdminUsers;


