import React, { useContext, useState } from "react";
import { EditorInfoContext } from "../../../../utis/userInfoContext";

function useCanvasHook() {
    const { rectangles } = useContext(EditorInfoContext)

    const draw = (index, canvasRefs) => {
        const ctx = canvasRefs.current[index].getContext('2d');
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        // rectangles[index].forEach(rectangle => {
        //     drawRectangle(ctx, rectangle);
        // });
        rectangles[index].forEach((rectangle, i) => {
            if (i === rectangles[index].length - 1) {
                // Highlight only the last rectangle
                drawRectangle(ctx, rectangle, true);
            } else {
                drawRectangle(ctx, rectangle, false);
            }
        });
    };

    const drawRectangle = (ctx, rectangle, isHighlighted) => {
        if (!rectangle) return;

        const { startCoords, currentCoords } = rectangle;
        if (isHighlighted) {
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 2;
            ctx.strokeRect(
                startCoords.x,
                startCoords.y,
                currentCoords.x - startCoords.x,
                currentCoords.y - startCoords.y
            );
        }
    };

    const normalizeDimensions = (val, currentScale, desiredScale) => {
        if (undefined === desiredScale) {
            desiredScale = 1;
        }
        return Math.round(val * desiredScale / currentScale);
    };

    const createDiv = (rectangle, index, canvasRefs) => {
        const newDiv = document.createElement('div');
        newDiv.id = 'createdDivId';
        newDiv.style.position = 'absolute';
        newDiv.style.width = `${rectangle.width}px`;
        newDiv.style.height = `${rectangle.height}px`;
        // newDiv.style.backgroundColor = 'rgba(0, 255, 0, 0.5)';
        newDiv.style.border = '2px solid red';
        newDiv.style.zIndex = 99;

        // Set the left and top based on startCoords
        newDiv.style.left = `${rectangle.x}px`;
        newDiv.style.top = `${rectangle.y}px`;

        // Remove drag events to prevent dragging
        newDiv.draggable = false;

        // Append the new div to the parent element
        canvasRefs.current[index].parentElement.appendChild(newDiv);

        return newDiv;
    };

    return {
        draw,
        normalizeDimensions,
        createDiv
    }
}
export default useCanvasHook