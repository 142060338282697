import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFont } from '@fortawesome/free-solid-svg-icons';
const TextToolbox = () => {
    return(
        <>
            <FontAwesomeIcon icon={faFont} />
            Text
        </>
    );
}
export default TextToolbox;