const translations = {
    devise: {
      confirmations: {
        confirmed: "Your email address has been successfully confirmed. Please fill in your email and password to login",
        send_instructions: "send_instructions",
        send_instructions1: "Please check you email in a few minutes and click on the link we sent you to confirm your sign up.",
        send_paranoid_instructions: "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes.",
        thank_you: "Thank You",
        ok: "OK"
      },
      failure: {
        already_authenticated: "",
        inactive: "Your account is not activated yet.",
        invalid: "invalid_password",
        invalid1: "Password incorrect",
        locked: "locked",
        locked1_html: 'Your account is locked.',
        unconfirmed_account_html: 'Please verify your email address and check your inbox or spam folder for a message from <a href="https://support.boxonline.com">support@kzine.com</a>',
        last_attempt: "last_attempt",
        last_attempt1: "You have one more attempt before your account is locked.",
        not_found_in_database: "not_found_in_database",
        not_found_in_database1: "Could not find an account with that email address.",
        timeout: "Your session expired. Please login again to continue.",
        unauthenticated: "You need to login or sign up before continuing.",
        unconfirmed: "Please click on the link in the email we just sent you.",
        already_signup: "Your email already exist, please try login",
        unverified_account_tooltip: "Your account is unverified",
        activate_message: "Please activate your kinetizine account by clicking the link we emailed you.",
        check_inbox: "Please check your inbox",
        enter_password: "Please enter your password again",
        incorrect_credentials: "Invalid credentials. Please try again."
      },
      display_device_settings: {
        all_devices: 'On all devices',
        desktops: 'On desktops',
        mobile: 'On mobile'
      },
      enter_frequency_settings: {
        once: 'Once',
        each_visit: 'Each visit'
      },
      display_scroll_settings: {
        // all_devices_once: 'On all devices once',
        // all_devices_perpetually: 'On all devices perpetually',
        desktops_once: 'On desktops once',
        desktops_perpetually: 'On desktops perpetually',
        // mobile_once: 'On mobile once',
        // mobile_perpetually: 'On mobile perpetually',
        not_scroll: 'Off' ,
      },
      mailer: {
        confirmation_instructions: {
          subject: "Confirmation needed for your sign up to Kinetizine"
        },
        reset_password_instructions: {
          subject: "Reset password instructions"
        },
        unlock_instructions: {
          subject: "Security Alert - Your kZine account has been locked"
        },
        email_changed: {
          subject: "Email Changed"
        },
        password_change: {
          subject: "Password Changed"
        }
      },
      omniauth_callbacks: {
        failure: "Could not authenticate you from %{kind} because \"%{reason}\".",
        success: "Successfully authenticated from %{kind} account."
      },
      passwords: {
        no_token: "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        send_instructions: "",
        send_paranoid_instructions: "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        updated: "Your password has been changed successfully. You are now signed in.",
        updated_not_active: "Your password has been changed successfully."
      },
      registrations: {
        destroyed: "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
        signed_up: "Welcome! You have signed up successfully.",
        signed_up_but_inactive: "You have signed up successfully. However, we could not log you in because your account is not yet activated.",
        signed_up_but_locked: "You have signed up successfully. However, we could not log you in because your account is locked.",
        old_signed_up_but_unconfirmed: "Thank you for signing up to Kinetizine.<br>A confirmation link has been sent to your email.<br>Please follow the link to activate your account.",
        signed_up_but_unconfirmed: "Thanks for signing up.<br>You're going to love this amazing service.<br><br>A confirmation link has been emailed to you.<br><br>Check your email, click on the included link, and get ready to put your content to work!",
        update_needs_confirmation: "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
        updated: "Your account has been updated successfully.",
        invalid_recapcha: 'Invalid captcha code, Please try again!'
      },
      sessions: {
        signed_in: "Logged in successfully.",
        signed_out: "signed_out",
        signed_out1: "Logged out successfully.",
        already_signed_out: "Logged out successfully."
      },
      unlocks: {
        send_instructions: "send_unlock_instructions",
        send_instructions1: "You will receive an email with instructions for how to unlock your account in a few minutes.",
        send_paranoid_instructions: "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        unlocked: "Your account has been unlocked successfully. Please login in to continue.",
        link_not_received: "Didn’t receive a link?"
      },
      headers: {
        resend_confirmation_instructions: 'Resend confirmation instructions',
        resend_unlock_instructions: 'Resend unlock instructions',
        recover_password: 'recover your password'
      },
      log_in: 'Log In',
      log_in_header: 'Log Into your Account',
      more_options: 'Options',
      logo_texts: {
        try_kz_free: 'Try Kinetizine Free',
        no_card: 'No credit card required.',
        cancel_anytime: 'Cancel anytime.',
      }
    },
    errors: {
      messages: {
        already_confirmed: "was already confirmed, please try signing in instead",
        confirmation_period_expired: "needs to be confirmed within %{period}, please request a new one",
        expired: "has expired, please request a new one",
        not_found: "This email is not associated to an account",
        not_locked: "was not locked",
        not_saved: {
          one: "1 error prohibited this %{resource} from being saved:",
          other: "%{count} errors prohibited this %{resource} from being saved:"
        }
      }
    }
  };
  
  export default translations;
  