import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

const ImageToolbox = () => {

    return (
        <div>
            <FontAwesomeIcon icon={faImage} />
            Image
        </div>
    );
}
export default ImageToolbox;