import React from "react";
import Header from "./Header/Header";
import SideBar from "./Sidebar/SideBar";
import Footer from "./Footer/Footer";

const Layout = ({ header, children }) => {
    // console.log('children ', children);
    
    return (
        <div>
            <div className="app-layout test">
                <div>
                    <SideBar />
                </div>
                <div className="right-wrapper">
                    <Header heading={header}/>
                    <main>{children}</main>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;