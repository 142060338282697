import React from "react"
import { Tab, Tabs } from "react-bootstrap"
import Giphy_logo from '../../../../../../../../assets/images/Giphy logo.png';
import tenor_logo from '../../../../../../../../assets/images/tenor_logo.svg';
import GifSection from "./gifSections/GifSection"
import Button from '../../../../../../Elements/Button'
import TenorSection from "./gifSections/TenorSection";

const ImageSection = ({ tool, handleSelectGifs, handleImageUpload, uploadElement, editElement, errorState }) => {
    const getContentType = (media) => media?.substring(6)?.toUpperCase();
    const getContentSize = (media) => (media / 1024)?.toFixed(2)

    //image tab content
    const ImageTab = ({ handleImageUpload, uploadElement, editElement }) => {
        return (
            <>
                {
                    !tool?.file && !editElement?.file_url ?
                        <div className="upload-file-btn"
                        >Select File
                            <input
                                type="file"
                                id="file-input"
                                accept="image/*"
                                onChange={(e) => handleImageUpload(e, 'upload')} />
                        </div> :
                        <div className="file-data-main">
                            <div className="file-data">
                                <img
                                    src={tool?.file ? tool?.file && URL.createObjectURL(tool?.file) : editElement?.file_url}
                                />
                            </div>
                            <div className="file-info">
                                <p>File Name : <span>{tool?.file?.name ?? editElement?.file_details?.name}</span></p>
                                <p>Kind : <span>{getContentType(tool?.file?.type ?? editElement?.file_details?.content_type)} image</span></p>
                                <p>Size : <span>{getContentSize(tool?.file?.size ?? editElement?.file_details?.file_size)} KB</span></p>
                            </div>
                            {
                                !editElement?.file_url && <div className="d-flex">
                                    <Button
                                        text={<><i className="fa-solid fa-upload" />Upload</>}
                                        classList={'success'}
                                        addonClass={'me-2'}
                                        onClick={(e) => uploadElement(e, null, 'image')}
                                    />
                                    <Button
                                        text={<><i className="fa-regular fa-circle-xmark"></i> Cancel</>}
                                        classList={'alert'}
                                        onClick={(e) => {
                                            handleImageUpload(e, 'cancel')
                                        }}
                                    >
                                    </Button>
                                </div>
                            }
                        </div>
                }
                <p id="file-name-display" className="file-name-display"></p>
            </>
        )
    }

    return (
        <>
            <Tabs
                defaultActiveKey={editElement.gif_type === "sticker" ? "gifs" : editElement.gif_type === "tenor" ? "tenor" : "image"}
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="image" title={'Local Files'}>
                    <ImageTab
                        handleImageUpload={handleImageUpload}
                        uploadElement={uploadElement}
                        editElement={editElement}
                    />
                </Tab>
                <Tab eventKey="gifs" title={<img src={Giphy_logo} alt='Giphy logo' height='20px' />}>
                    <GifSection
                        tool={tool} handleSelectGifs={handleSelectGifs}
                    />
                </Tab>
                <Tab eventKey="tenor" title={<img src={tenor_logo} alt='tenor logo' height='20px' />}>
                    <TenorSection
                        tool={tool} handleSelectGifs={handleSelectGifs}
                    />
                </Tab>
            </Tabs>
            <p className="text-danger">{errorState?.imgErr}</p>
        </>
    )
}
export default ImageSection