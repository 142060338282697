import React from "react"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor,
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo
} from 'ckeditor5';
import 'ckeditor5/dist/ckeditor5.css';

function TextEditorSection({ handleTextChange, html_text, errorState }) {
    return (
        <>
            <CKEditor
                editor={ClassicEditor}
                config={{
                    toolbar: [
                        'undo', 'redo', '|',
                        'heading', '|', 'bold', 'italic', '|',
                        'link', 'insertTable', 'mediaEmbed', '|',
                        'bulletedList', 'numberedList', 'indent', 'outdent'
                    ],
                    plugins: [
                        Bold,
                        Essentials,
                        Heading,
                        Indent,
                        IndentBlock,
                        Italic,
                        Link,
                        List,
                        MediaEmbed,
                        Paragraph,
                        Table,
                        Undo
                    ],
                }}
                data={html_text}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    handleTextChange(event, data)
                }}
            />
            {errorState?.textErr && <p className="text-danger">{errorState?.textErr}</p>}
        </>
    );
}

export default TextEditorSection;
