import React, { useEffect } from 'react';
import $ from 'jquery';
import 'datatables.net';
const Customizations = ({customization}) => {
    useEffect(() => {
        // Initialize DataTable
        const table = $('#MyTable').DataTable({
            pageLength: 10, // Set the default number of rows to 10
            initComplete: function () {
                this.api().columns().every(function () {
                    const column = this;
                    const select = $('<select><option value=""></option></select>')
                        .appendTo($(column.footer()).empty())
                        .on('change', function () {
                            const val = $.fn.dataTable.util.escapeRegex($(this).val());
                            column
                                .search(val ? '^' + val + '$' : '', true, false)
                                .draw();
                        });
                    column.data().unique().sort().each(function (d) {
                        select.append('<option value="' + d + '">' + d + '</option>');
                    });
                });
            }
        });

        // Cleanup DataTable on unmount
        return () => {
            table.destroy(true);
        };
    }, []);
    return(
        <>
            <div className="d-flex justify-content-between margin-left-10 margin-right-10">
                <span style={{fontWeight: 800,fontSize: '30px'}}>Consent Modal Change Requests</span>
                <a className="btn btn-success text-center margin-left-10 margin-bottom-10 hidden-xs" href="#">Request a Change</a>
                </div>

                <table id="MyTable" className="table table-striped table-bordered table-sm" width="100%">
                    <thead>
                    <tr>
                        <th className="th-sm">User Name
                        </th>
                        <th className="th-sm">Message
                        </th>
                        <th className="th-sm">Status
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            customization?.length > 0 &&
                            customization.map(
                                item =>
                                <tr>
                                    <td>{item.user.name}</td>
                                    <td>{item.message}</td>
                                    <td className="d-flex">
                                        {item.approved ? <p className="margin-right-5">Approved</p> : <p className="margin-right-5" style={{color: '#fe974b'}}>Rejected</p>}
                                        <a className="cursor-pointer nowrap">Edit</a>
                                    </td>
                                </tr>
                            )                            
                        }
                    </tbody>
                </table>
                <style>
                {`
                    div#MyTable_paginate a {
                        float: left;
                        padding: 8px 16px;
                        text-decoration: none;
                    }
                    div#MyTable_paginate a.current {
                        background-color: #A1C1E0;
                        color: white;
                    }
                    div#MyTable_paginate a:hover:not(.active) {
                        background-color: #ddd;
                    }
                    div#MyTable_wrapper {
                        padding: 8px 16px;
                    }
                `}
            </style>
        </>
    );
}
export default Customizations;