// import React, { useEffect, useState } from 'react';
import { csrfToken } from './csrfTokenUtils';
const utilsUploadFormData = async (url, data, methodGet) => {
    // const [data, setData] = useState({loading:true});
    try{
        const myHeaders = new Headers();
        myHeaders.append("Cookie", csrfToken());
        myHeaders.append("Content-Type", "application/json");
        let requestData = await fetch(`/${url}`, {
            method:methodGet,
            headers:myHeaders,
            body:JSON.stringify({...data, 'authenticity_token':csrfToken()}),
        }).then(res => res.json()).catch(error => error);
        return {data:requestData}
    }catch(error) {
        return {error:error}
    }
}
export default utilsUploadFormData;