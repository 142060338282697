import React, { useRef, useState } from "react";
import ElementIcons from "./ElementIcons";
import AnimatedElements from "./AnimtedElement";
import ResizableComponent from "./ResizableComponent";
import DraggableElement from "../../../../CommonComponents/Draggable/DraggableElement";
import Element from "./Element";
import { getObjectById } from "../../../../../hooks/utils";
import ViewLightBox from "../elementModal/elementSections/lightBoxSection/ViewLightBox";

const PdfElement = ({
  canvasRef,
  element,
  zoom,
  dimensions,
  setPageNumber,
  editortype,
  setResizeDimensions,
  resizeDimensions,
}) => {
  const resizableDimensions = {
    top: resizeDimensions[element.id]?.top || dimensions?.top,
    left: resizeDimensions[element.id]?.left || dimensions?.left,
    height: resizeDimensions[element.id]?.height || dimensions?.height,
    width: resizeDimensions[element.id]?.width || dimensions?.width,
  };

  const [lgtObj, setLgtObj] = useState(null)
  const previousDimensions = useRef(dimensions);

  const bounds = {
    top: -resizableDimensions?.top,
    left: -resizableDimensions?.left,
    right:
      canvasRef?.width -
      (resizableDimensions?.left + resizableDimensions?.width),
    bottom:
      canvasRef?.height -
      (resizableDimensions?.top + resizableDimensions?.height),
  };

  const handleClick = (element) => {
    if (element?.value?.page_link) {
      setPageNumber(Number(element?.value?.page_link));
    } else if (element?.value?.url) {
      const inputValue = element?.value?.url;
      if (inputValue && inputValue.includes("@")) {
        window.location.href = `mailto:${inputValue}`;
      } else {
        window.open(inputValue, "_blank");
      }
    } else if (element?.value?.light_box) {
      setLgtObj(getObjectById(element?.value?.light_box)?.elements)
    }
  };
    // console.log('resizableDimensions', resizableDimensions, canvasRef?.width);
    const clips = {
      topClip: Math.max(0, resizableDimensions?.top),
      leftClip: Math.max(0, resizableDimensions?.left),
      rightClip: Math.max(
        0,
        canvasRef?.width - (resizableDimensions?.left + resizableDimensions?.width)
      ),
      bottomClip: Math.max(
        0,
        canvasRef?.height - (resizableDimensions?.top + resizableDimensions?.height)
      ),
    }

  return (
    <>
      <ViewLightBox setShowLightBox={setLgtObj} showLightBox={lgtObj} />
      <DraggableElement
        position={null}
        dimensions={dimensions}
        bounds={bounds}
        element={element}
        canvasRef={canvasRef}
        resizableDimensions={resizableDimensions}
        setResizeDimensions={setResizeDimensions}
        disabled={editortype !== "editor"}
      >
        <div
          className="handle resizable"
          onClick={() => handleClick(element)}
        >
          <>
            {editortype === "editor" ? (
              <ResizableComponent
                element={element}
                dimensions={dimensions}
                resizableDimensions={resizableDimensions}
                resizeDimensions={resizeDimensions}
                setResizeDimensions={setResizeDimensions}
                keepProportions={element?.geometry?.keep_proportions ?? true}
              >
                {editortype === "editor" && (
                  <ElementIcons
                    element={element}
                    resizableDimensions={resizableDimensions}
                    setResizeDimensions={setResizeDimensions}
                    previousDimensions={previousDimensions}
                  />
                )}
                {element?.value?.animation ? (
                  <AnimatedElements element={element}>
                    <Element
                      element={element}
                      dimensions={dimensions}
                      resizeDimensions={resizeDimensions}
                      zoom={zoom}
                    />
                  </AnimatedElements>
                ) : (
                  <Element
                    element={element}
                    dimensions={dimensions}
                    resizeDimensions={resizeDimensions}
                    zoom={zoom}
                  />
                )}
              </ResizableComponent>
            ) : (
              <div
                style={{
                  width: `${dimensions?.width}px`,
                  height: `${dimensions?.height}px`,
                  position: "relative",
                }}
              >
                {element?.value?.animation ? (
                  <AnimatedElements element={element}>
                    <Element
                      element={element}
                      dimensions={dimensions}
                      resizeDimensions={resizeDimensions}
                      zoom={zoom}
                    />
                  </AnimatedElements>
                ) : (
                  <Element
                    element={element}
                    dimensions={dimensions}
                    resizeDimensions={resizeDimensions}
                    zoom={zoom}
                  />
                )}
              </div>
            )}
          </>
        </div>
      </DraggableElement>
    </>
  );
};
export default React.memo(PdfElement);
