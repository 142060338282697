import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import AdminCustomisationLogPage from "./Pages/Admin/AdminCustomisationLogPage";

function AdminCustomisationLog() {
    return (
        <>
            <Layout>
                <AdminCustomisationLogPage />
            </Layout>
        </>
    )
}
export default AdminCustomisationLog;


