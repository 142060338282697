import React, { useState } from 'react';
import kz_logo from '../../../assets/images/kz_logo.png';


const Signup = ({ props, csrfToken }) => {

  return (
    <form className="login-warp-main" action="/signup" method="post">
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <div className="form-main">
        <div className="kz-logo">
          <img src={kz_logo} />
        </div>
        <div className="Signup-heading mb-4">
          <h2>Try Kinetizine Free</h2>
          <div className="mt-3"><p>No credit card required. Cancel anytime.</p></div>
        </div>
        <div className="input-groups">
          <div className="form-input">
            <input type="email" name='user[email]' placeholder="Email" />
          </div>
          <div className="form-input">
            <input type="password" name='user[password]' placeholder="Password (6 characters minimum)" />
          </div>
          <input type="hidden" name="user[terms_accepted]" value={true} />
          <input type="hidden" name="commit" value='Create My Accoun' />

          <div className="login-btn">
            <button type='submit'>
              Create My Account »
            </button>
          </div>
          <div className="sign-p">
            <p>By continuing, you agree to the<a id="a-term">Terms of Service</a></p>
          </div>
          <div className="sign-p">
            <p>Already have an account?<a href="/users/login" className="text-decoration-underline"> Log In</a></p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Signup;