import React from "react"
import CommonModal from "../CommonModal"
import Button from "../../Elements/Button"

const KdfActionModal = ({ openKdfModal, closeModal }) => {
    return (
        <CommonModal
            modalStatus={openKdfModal}
            changeActiveStatus={closeModal}
            classList="header-profile-modal-wrapper"
            centered
        >
            <div>
                <p>
                    In order to link one KDF to another and track the recipient's journey through multiple KDFs:
                </p>
                <p>
                    1. In a different tab, locate the target KDF you wish to send recipients to from within this KDF.
                </p>
                <p>
                    2. Create a new link for your target KDF and name it something like 'K2K Your Link Title'.
                </p>
                <p>
                    You can actually name it anything you wish... but, we have found the above naming convention helpful.
                </p>
                <p>
                    3. Define that new link to be a 'KDF to KDF Link' using the checkbox in the Link Settings popup.
                </p>
                <p>
                    4. Save your new K2K Link (no need to copy it) and return to this tab in the Editor.
                </p>
                <p>
                    5. Enter the target KDF Title and the existing K2K Link Titles related to your target KDF should automatically appear in the dropdown.
                </p>
                <p>
                    6. Select your new K2K Link and hit the SAVE button.
                </p>
            </div>
            <div className="text-end">
                <Button
                    classList={'success'}
                    onClick={() => closeModal(false)}
                    text={"Ok"} />
            </div>
        </CommonModal>

    )
}
export default KdfActionModal