import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import AddBotPage from "./Pages/Admin/AddBotsPage";

function AddAdminBot() {
    return (
        <>
            <Layout>
                <AddBotPage />
            </Layout>
        </>
    )
}
export default AddAdminBot;