import React from "react";
import Breadcrumb from "../../CommonComponents/BreadCrumb/BreadCrumb";

const AddBotPage = () => {
    return (
        <>
            <Breadcrumb string={'Admin/Potential Bots/Add'} />
            <div className="logs-form">
                <form>
                    <div className="form-group">
                        <label className="mb-1" htmlFor="ip">IP Address</label>
                        <div>
                            <textarea id="ip" name="ip" className="ip" rows="3"></textarea>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="back-sub">
                            <button className="back-btn me-4">Back</button>
                            <button className="create-btn">Create Subscription</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default AddBotPage
