import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./LightBoxSection.css";
import CommonModal from "../../../../../../Modals/CommonModal";
import Element from "../../../../../../Elements/KDFElements/Element";
import ImageSlider from "./ImageSlider";

const ViewLightBox = ({ showLightBox, setShowLightBox }) => {
    function hasMultipleValues(obj) {
        return Object.keys(obj).length > 1;
    }

    const arrayOfImageElements = useMemo(() => {
        return showLightBox && Object.keys(showLightBox).length !== 0
            ? Object.values(showLightBox)
            : [];
    }, [showLightBox]);

    return (
        <>
            <CommonModal
                modalStatus={showLightBox != null}
                changeActiveStatus={(status) => setShowLightBox(null)}
                centered
                lightBox={true}
            >
                {!!showLightBox && !hasMultipleValues(showLightBox) ?
                    <Element elements={showLightBox ?? {}} /> :
                    <ImageSlider arrayOfImageElements={arrayOfImageElements} showIcons={false} />}
            </CommonModal>
        </>
    );
};
export default ViewLightBox;