import React from "react"
const AuthModalLayout = ({ title, children, closeModal, footerContent }) => {
    return (
        <div className="auth-modal">
            <div className="auth-modal-body">
                <div className="auth-modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="terms-main">
                        {children}
                    </div>
                    <div className="modal-footer">
                        {/* {footerContent} */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AuthModalLayout