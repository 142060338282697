
import React from "react"

const TransparencySection = ({ tool, handleChange }) => {
    return (
        <div className="transparency-section d-flex">
            <div className="">
                <p>Transparency</p>
            </div>
            <div className="range-slider ">
                <input
                    id="slider_input"
                    className="cursor-pointer"
                    type="range"
                    min="0"
                    max="100"
                    name='transparency'
                    value={tool?.transparency ? Number(tool?.transparency) * 100 : 100}
                    onChange={handleChange}
                />
            </div>
        </div>
    )
}
export default TransparencySection