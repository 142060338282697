import React from "react"
const PositionSection = ({ tool, handleChange }) => {
    return (
        <>
            <div className="position-section d-flex align-items-center justify-content-between mt-3">
                <label>POSITION</label>
                <div className="d-flex">
                    <div className="me-3">
                        Left: <input
                            type="number"
                            name="geometry.left"
                            value={tool?.geometry?.left}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        Top:
                        <input
                            type="number"
                            name="geometry.top"
                            value={tool?.geometry?.top}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="size-section d-flex justify-content-between">
                <div>
                    Constrain Proportions <input
                        type="checkbox"
                        name='geometry.keep_proportions'
                        value={tool?.geometry?.keep_proportions}
                        checked={tool?.geometry?.keep_proportions}
                        onChange={handleChange}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <div>
                        Width:
                        <input
                            type="number"
                            name="geometry.width"
                            value={tool?.geometry?.width}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        Height:
                        <input
                            type="number"
                            name="geometry.height"
                            value={tool?.geometry?.height}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        
        </>
    )
}
export default PositionSection