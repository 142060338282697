import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
const LightboxesToolbox = () => {
    return(
        <>
            <FontAwesomeIcon icon={faImages} />
            Lightboxes
        </>
    );
}
export default LightboxesToolbox