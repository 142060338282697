import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import RecepientsPage from "./Pages/Recipients/RecepientsPage";

function Recepients() {
    return (
        <>
            <Layout header={'Recipients'}>
                <RecepientsPage />
            </Layout>
        </>
    )
}
export default Recepients;