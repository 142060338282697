import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import AdminSubscriptionsPage from "./Pages/Admin/AdminSubscriptionsPage";

function AdminSubscriptions() {
    return (
        <>
            <Layout>
                <AdminSubscriptionsPage />
            </Layout>
        </>
    )
}
export default AdminSubscriptions;


