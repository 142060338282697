import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
const HotspotToolbox = () => {
    return(
        <>
            <FontAwesomeIcon icon={faExpand} />
            Hotspot
        </>
    );
}
export default HotspotToolbox