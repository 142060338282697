import translations from "../common/Translations";

export const getTranslation = (path) => {
    const keys = path.split('.');
    let value = translations;
    keys.forEach(key => {
        value = value[key];
    });
    return value || '';
};

export function quality_data() {
    return [
        { name: "FASTEST LOADING", value: 'better2' },
        { name: "GOOD IMAGE QUALITY", value: 'good' },
        { name: "BEST IMAGE QUALITY", value: 'best1' },
        { name: "BYPASS IMAGE OPTIMIZATION", value: 'bypass' }
    ]
}

export function animations() {
    return {
        animations: {
            attention_seekers: ["bounce", "flash", "pulse", "rubberBand", "shake", "swing", "tada", , "wobble", "jello"],
            bouncing_entrances: ["bounceIn", "bounceInDown", "bounceInLeft", "bounceInRight", "bounceInUp"],
            fading_entrances: ["fadeIn", "fadeInDown", "fadeInDownBig", "fadeInLeft", "fadeInLeftBig", "fadeInRight", "fadeInRightBig", "fadeInUp", "fadeInUpBig"],
            flippers: ["flip", "flipInX", "flipInY"],
            lightspeed: ["lightSpeedIn"],
            rotating_entrances: ["rotateIn", "rotateInDownLeft", "rotateInDownRight", "rotateInUpLeft", "rotateInUpRight",],
            sliding_entrances: ["slideInUp", "slideInDown", "slideInLeft", "slideInRight",],
            zoom_entrances: ["zoomIn", "zoomInDown", "zoomInLeft", "zoomInRight", "zoomInUp",],
            specials: ["hinge", "rollIn",]
        }
    }
}
export function disappear() {
    return {
        disappear: {
            bouncing_exits: ["bounceOut", "bounceOutDown", "bounceOutLeft", "bounceOutRight", "bounceOutUp"],
            fading_exits: ["fadeOut", "fadeOutDown", "fadeOutDownBig", "fadeOutLeft", "fadeOutLeftBig", "fadeOutRight", "fadeOutRightBig", "fadeOutUp", "fadeOutUpBig"],
            flippers: ["flipOutX", "flipOutY"],
            lightspeed: ["lightSpeedOut"],
            rotating_exits: ["rotateOut", "rotateOutDownLeft", "rotateOutDownRight", "rotateOutUpLeft", "rotateOutUpRight"],
            sliding_exits: ["slideOutUp", "slideOutDown", "slideOutLeft", "slideOutRight"],
            zoom_exits: ["zoomOut", "zoomOutDown", "zoomOutLeft", "zoomOutRight", "zoomOutUp"],
            specials: ["rollOut"]
        }
    }
}

export function entranceLoop() {
    return {
        entrance_loop: ["loop5Seconds", "loop10Seconds", "loopAnimation"]
    }
}

export const getLanguageCode = (locale) => {
    return locale.includes('-') ? locale.split('-')[0] : locale;
}

export const getObjectById = (targetId) => {
    let lightBoxes = window?.objInit?.lightBoxes
    if (lightBoxes.click) {
        for (let key in lightBoxes.click) {
            if (lightBoxes.click[key].id == targetId) {
                return lightBoxes.click[key];
            }
        }
    }

    if (lightBoxes.doc) {
        for (let key in lightBoxes.doc) {
            if (lightBoxes.doc[key].id == targetId) {
                return lightBoxes.doc[key];
            }
        }
    }

    return null;
}
