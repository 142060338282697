import React, { useCallback, useState } from "react";
import '../../../assets/stylesheets/modal.css'
import CommonModal from "./CommonModal";
import { csrfToken } from "../../utis/csrfTokenUtils";
const SupportModal = ({ setOpenSupport, openSupport }) => {
    const [feedback, setFeedback] = useState({
        category: 0,
        did_text: "",
        expected_text: "",
        happened_text: ""
    })

    const handleFieldsChange = (e) => {
        const { name, value } = e.target;
        // setFeedback({
        //     ...feedback,
        //     [name]: value,
        // });
    };

    const handeSendFeedback = async () => {
        const data = {
            authenticity_token: csrfToken(),
            feedback: {
                ...feedback,
                url: window.location.href,
            }
        }
        const resposne = await networkService(`feedbacks.json`, 'POST', data)
        if (resposne?.status === 200) {
            setOpenSupport(false)
        }
    }

    const categories = {
        1: 'Bug Reprt',
        2: 'Enhancement Request',
        3: 'Design / Ease of Use',
        4: 'Efficiency / Workflow Issue',
        5: 'Compatibilty (Software / Hardware )',
        6: 'Connectivity Isse',
        7: 'Domains',
        8: 'Other',
        9: 'Weather Accuracy',
        10: 'User Error',
        11: 'Content Issue',
        12: 'Artwork',
        13: 'Feature Request',
        14: 'User Experience'
    }

    const categoriesArray = Object.entries(categories).map(([key, name]) => ({
        value: Number(key),
        name,
    }));

    const SupportModalBody = () => {
        return (
            <form >
                <div className="row ">
                    <div className=" reason col-6 mb-2 dropdown">
                        <select
                            style={{
                                height: "35px",
                                border: '1px solid #555555'
                            }}
                            name="category"
                            value={feedback.category}
                            onChange={handleFieldsChange}
                        >
                            <option value="animation-type">*Reason</option>
                            {
                                categoriesArray?.map((elem) => {
                                    return (
                                        <option key={elem?.value} value={elem?.value}>{elem?.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-sm-12 mb-2">
                        <div className="text-ared-inp">
                            <label className="mb-2">* What I did</label>
                            <textarea
                                name='did_text'
                                // value={feedback.did_text}
                                onChange={(e) => handleFieldsChange(e)}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 mb-2 ">
                        <div className="text-ared-inp">
                            <label className="mb-2">* What I expected</label>
                            <textarea
                                name='expected_text'
                                value={feedback.expected_text}
                                onChange={(e) => handleFieldsChange(e)}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="text-ared-inp">
                            <label className="mb-2">* What happened</label>
                            <textarea
                                name='happened_text'
                                value={feedback.happened_text}
                                onChange={(e) => handleFieldsChange(e)}
                            />
                        </div>
                    </div>

                </div>
                <div className=" mb-2 notes">
                    <p className="mb-0">* Note: Please include links to screen shots or video using <a href="#">Monosnap</a> or <a
                        href="">Loom</a>.</p>
                </div>
                <div className="send-btn">
                    <button
                        onClick={() => handeSendFeedback()}
                    >Send
                    </button>
                </div>
            </form>
        )
    }
    return (
        <CommonModal
            title={'Send Feedback'}
            modalStatus={openSupport}
            changeActiveStatus={setOpenSupport}
            classList="header-profile-modal-wrapper"
            centered
        >
            <SupportModalBody />
        </CommonModal>
    )
}

export default SupportModal;