import React from 'react';
import { Button as BButton } from 'react-bootstrap'; 
import './button.css'
const Button = ({classList,text,addonClass,children = null,...restProps}) => {
    if(children) return {children};
    return(
        <div className={`${classList?.split(' ').map(item => `custom-button-${item}`).join(' ')} ${addonClass != undefined ? addonClass : ''}`}>
            <BButton {...restProps}>
            {/* {classList?.includes('processing') ? <span style={{zIndex:2,position:'relative'}}>{text}</span> : {text}} */}
            {text}
            </BButton>
        </div>
    )
}
export default Button;