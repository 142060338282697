import React from "react";
import MainBody from "./MainBody";
import 'animate.css';

function AppContainer(props) {
  return (
    <MainBody {...props} />
  );
}

export default AppContainer;
