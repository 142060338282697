import React from "react";
import '../../assets/stylesheets/homepage.css'
import Layout from "./Layout/Layout";
import CreateNewKdf from "./Pages/Kdf/CreateNewKdf";

function CreateKdfPage(props) {
    return (
        <>
            <Layout header={'Create New'}>
                <CreateNewKdf props={props}/>
            </Layout>
        </>
    )
}
export default CreateKdfPage;