import React from "react";
import Breadcrumb from "../../CommonComponents/BreadCrumb/BreadCrumb";
const AdminSubscriptionsPage = () => {
    return (
        <>
            <Breadcrumb string={'Admin/Subscriptions'} />
            <div className="container">
                <div className="show-select1 d-flex ">
                    <div className="d-flex align-items-center">
                        <p>Show</p>
                        <div className="custom-select-container me-2 ms-2 ali">
                            {/* <!-- <label htmlFor="custom-select">Choose an option:</label> --> */}
                            <select id="custom-select" className="custom-select">
                                <option value="option1"> 10</option>
                                <option value="option2"> 25</option>
                                <option value="option3"> 50</option>
                                <option value="option4"> 100</option>
                            </select>
                        </div>
                        <p>entries</p>
                    </div>

                    <div className="Search-inp">
                        <div>
                            <label>
                                Search :
                            </label>
                            <input placeholder="Search..." />
                        </div>
                    </div>
                </div>
                <div className="top-parent">
                    <table className="Subscription-table mt-4">
                        <tr>
                            <th>Name</th>
                            <th>Period</th>
                            <th>Name</th>
                            <th>Amount (Discount)</th>
                            <th>Payment URL</th>
                            <th>Modified by</th>
                            <th><button className="add-sub"><a href="/admin/subscriptions/new">Add Subscription</a></button></th>
                        </tr>
                        <tr>
                            <td>nitin</td>
                            <td>nitin</td>
                            <td>nitin</td>
                            <td>nitin</td>
                            <td>nitin</td>
                            <td>nitin</td>
                            <td>nitin</td>
                        </tr>
                        <tr>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                        </tr>
                        <tr>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                        </tr>
                        <tr>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                        </tr>
                        <tr>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                        </tr>
                        <tr>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                            <td>example</td>
                        </tr>
                    </table>
                </div>
                <div className="showing-main d-flex justify-content-between mt-3 mb-5">
                    <div className="showing-para">
                        <p>Showing 0 to 0 of 0 entries</p>
                    </div>
                    <div>
                        <button className="previous-btn me-2">Previous</button>
                        <button className="previous-btn">Next</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminSubscriptionsPage;
