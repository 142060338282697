import React, { useEffect, useState } from 'react';
import './GifSections.css'
import apiGETRequest from '../../../../../../../../utis/apiRequest';
import Button from '../../../../../../../Elements/Button';

const GifSection = ({ tool, handleSelectGifs }) => {
    const [gifData, setGifData] = useState([])
    const [search, setSearch] = useState("")
    const [isGif, setIsGif] = useState(false)

    const handleGifChange = (status) => {
        setIsGif(status)
    }
    useEffect(() => {
        const fetchGifsTenor = async () => {
            try {

                const gifList = await apiGETRequest(`editor/get-gifs/${search !== '' ? search : 'excited'}/${isGif}`);
                setGifData(gifList?.data)
            } catch (error) {
                console.error('Error fetching gifs:', error);
            }
        };
        fetchGifsTenor();
    }, [search, isGif])

    return (
        <div className='GIFs-main'>
            <div className="Gif-inp mb-2 d-flex justify-content-between align-items-center">
                <input
                    placeholder="Search GIFs via GIPHY"
                    onChange={(e) => setSearch(e?.target?.value)}
                    value={search}
                />
                <Button
                    classList="primary"
                    text="Stickers"
                    addonClass="px-2"
                    onClick={()=>handleGifChange(false)}
                />
                <Button
                    classList="primary"
                    text="GIFs"
                    addonClass="px-2"
                    onClick={()=>handleGifChange(true)}
                />
            </div>
            <div className='gifs-items'>
                <div className='row flex-wrap'>
                    {
                        tool?.gif_type && tool?.gif_type === 'sticker' && tool?.value?.gif_link ?
                            <img
                                src={tool?.value?.gif_link}
                                alt='gif link'
                                style={{ height: "200px", width: "200px" }}
                            /> :
                            gifData?.length > 0 && gifData?.map((gif, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='col-4'
                                        onClick={() => handleSelectGifs('sticker', gif)}>
                                        <img style={{ height: "200px", width: "200px" }} src={gif} alt='gif link' />
                                    </div>
                                )
                            })
                    }

                </div>
            </div>
        </div>
    );
};

export default GifSection;