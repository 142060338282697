import React from "react";
const LaserLinxSettings = () => {
    return (
        <>
            <div className="links-main row">
                <div className="left-links col-6">
                    <div className="create-links d-flex">
                        <div className="links-img">
                            <img src="./assets/image/0.jpg" />
                        </div>
                        <div className="selects-left-main ms-3">
                            <div>
                                <input className="mb-4" type="radio" id="agency" name="organisation" value="agency" />
                                <label htmlFor="agency">Create an individual LaserLINK</label>
                            </div>
                            <div>
                                <input className="mb-4" type="radio" id="agency" name="organisation" value="agency" />
                                <label htmlFor="agency">Create an individual LaserLINK</label>
                            </div>
                            <div>
                                <input className="form-control" placeholder="campaign..." />
                            </div>
                            <div className="temp-check mt-3 mb-2">
                                <input type="checkbox" id="temp" name="temp" value=" " />
                                <label htmlFor="vehicle1"> As Template</label>
                            </div>
                            <div>
                                <input type="checkbox" id="temp" name="link" value="" />
                                <label htmlFor="vehicle1"> KDF to KDF Link</label>
                            </div>
                        </div>
                    </div>
                    <div className="inputs-domain mt-3">
                        <div className="d-flex mb-2 domain-center">
                            <label htmlFor="domain"> Domain</label>
                            <input type="" id="temp" className="form-control ms-2" name="link" value="" />
                        </div>
                        <div className="d-flex mb-2 domain-center">
                            <label htmlFor="tags"> Tags</label><i className="fa-regular fa-circle-question ms-2"></i>
                            <input type="" id="temp" className="form-control ms-2" placeholder="Add a new tag..."
                                name="link" value="" />
                        </div>
                        <div className="d-flex domain-center">
                            <label htmlFor="hook"> Hook</label><i className="fa-regular fa-circle-question ms-2"></i>
                            <input type="" id="temp" className="form-control ms-2" name="link"
                                value="I'm sharing this with you because you deserve to choose your own adventur" />
                        </div>
                    </div>
                    <div className="laser-email-main mt-3 d-flex">
                        <p>Send email notification</p>
                        <div className="d-flex switchs ms-3">
                            <p>Yes</p>
                            <div className="ms-2 me-2">
                                <label className="switch" htmlFor="checkbox">
                                    <input type="checkbox" id="checkbox" />
                                    <div className="slider round"></div>
                                </label>
                            </div>
                            <p>No</p>

                        </div>
                    </div>
                    <div>
                        <div className="d-flex email-lasers">
                            <p>
                                To
                            </p>
                            <div className="to-mail ms-3 mt-3">
                                <p className="mail-name">
                                    nitin@gmail.com
                                </p>
                                <input className="form-control" />
                            </div>

                        </div>
                        <div>
                            <p className="mt-3">
                                when the recipient:
                            </p>
                        </div>
                        <div className="d-flex laser-left-flex mt-2">
                            <input type="radio" id="temp" name="link" value="" />
                            <label className="ms-2" htmlFor="vehicle1">views this KDF for more than </label>
                            <div className="d-flex align-items-center ms-3 laser-inp">
                                <p>At least </p><input className="edit-value-inp ms-1 me-1" value="22" />
                                <p>Pages viewed</p>
                            </div>
                        </div>
                        <div className="d-flex mt-2">
                            <input type="radio" id="temp" name="link" value="" />
                            <div className="d-flex align-items-center ms-3 laser-inp">
                                <p>At least </p><input className="edit-value-inp ms-1 me-1" value="22" />
                                <p>for more than</p>
                            </div>
                            <div className="d-flex align-items-center ms-3 laser-inp">
                                <input className="edit-value-inp ms-1 me-1" value="22" />
                                <p>seconds </p>
                                <i className="fa-regular fa-circle-question ms-2"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Right-links col-5">
                    <div className="d-flex mb-2 domain-center">
                        <label htmlFor="domain"> Language:</label>
                        <input type="" id="temp" className="form-control ms-2" name="link" value="" />
                    </div>
                    <div className="d-flex align-items-center laser-inp">
                        <p>Landing page:</p><input className="edit-value-inp ms-1 me-1" value="1" />
                        <p>/4</p>
                    </div>
                    <div className="temp-check mt-3 mb-2">
                        <input type="checkbox" id="temp" name="temp" value=" " />
                        <label htmlFor="vehicle1">Show toolbar</label>
                    </div>
                    <div className="temp-check mt-3 mb-2">
                        <input type="checkbox" id="temp" name="temp" value=" " />
                        <label htmlFor="vehicle1">Show thumbnail navigation</label>
                    </div>
                    <div className="temp-check mt-3 mb-2">
                        <input type="checkbox" id="temp" name="temp" value=" " />
                        <label htmlFor="vehicle1"> Require consent</label>
                    </div>
                    <div className="temp-check mt-3 mb-2">
                        <input type="checkbox" id="temp" name="temp" value=" " />
                        <label htmlFor="vehicle1"> Enable download</label>
                    </div>
                    <div className="temp-check mt-3 mb-2">
                        <input type="checkbox" id="temp" name="temp" value=" " />
                        <label htmlFor="vehicle1"> Enable opt-in</label>
                    </div>
                    <div className="temp-check mt-3 mb-2">
                        <input type="checkbox" id="temp" name="temp" value=" " />
                        <label htmlFor="vehicle1"> Control scrolling</label>
                    </div>
                    <div className="temp-check mt-3 mb-2">
                        <input type="checkbox" id="temp" name="temp" value=" " />
                        <label htmlFor="vehicle1"> Embed - No background</label>
                    </div>
                    <div className="temp-check mt-3 mb-2">
                        <input type="checkbox" id="temp" name="temp" value=" " />
                        <label htmlFor="vehicle1"> Embed - No shadow</label>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LaserLinxSettings