import React from "react"

const DataPerPage = () => {
    return (
        <>
            <div className="d-flex align-items-center">
                <p>Show</p>
                <div className="custom-select-container me-2 ms-2 ali">
                    <select id="custom-select" className="custom-select">
                        <option value="option1"> 10</option>
                        <option value="option2"> 25</option>
                        <option value="option3"> 50</option>
                        <option value="option4"> 100</option>
                    </select>
                </div>
                <p>entries</p>
            </div>
        </>
    )
}
export default DataPerPage