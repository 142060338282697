import React from 'react';
import Draggable from 'react-draggable'

const DraggableComponent = ({ header, children }) => {
    const handleDrag = (e, data) => {
        const maxWidth = window.innerWidth - 380;
        const minWidth = 0;
        if (data.x < minWidth) {
            data.x = minWidth;
        } else if (data.x > maxWidth) {
            data.x = maxWidth;
        }
        return { x: data.x, y: data.y };
    };

    return (
        <Draggable
            axis="x"
            handle=".handle"
            defaultPosition={{ x: 0, y: 0 }}
            onDrag={handleDrag}
            position={null}
            grid={[25, 25]}
            scale={1}
        >
            <div className='editor-popup'>
                <div className="handle">
                    {header}
                </div>
                <div style={{ cursor: 'auto' }}>
                    {children}
                </div>
            </div>
        </Draggable >

    );
};

export default DraggableComponent;
