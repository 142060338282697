import React from "react"
const AddSubscriptionPage = () => {
    return (
        <>
            <div className="Customization">
                <div className="Customization-logs">
                    <h6>Admin<span className="logs ms-2">/ Subscriptions / Add</span></h6>
                </div>
            </div>
            <div className="logs-form">
                <form>
                    <div className="form-group">
                        <label className="mb-1" htmlFor="name">*Name</label>
                        <input type="text" className="form-control" id="name"/>
                    </div>
                    <div className="form-group">
                        <label className="mb-1" htmlFor="description">Description</label>
                        <input type="text" className="form-control" id="description"/>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="mb-1" htmlFor="thrive">*Thrive product id</label>
                                <input type="text" className="form-control" id="thrive"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="mb-1" htmlFor="thrive">*Period</label>
                            <div className="period-dropdown form-control">
                                <select id="cars" name="cars">
                                    <option value="none">&nbsp; None</option>
                                    <option value="monthly">&nbsp; Monthly</option>
                                    <option value="yearly">&nbsp; Yearly</option>
                                    <option value="quarterly">&nbsp; Quarterly</option>
                                    <option value="every 6 months">&nbsp; Every 6 months</option>
                                    <option value="weekly">&nbsp; Weekly</option>
                                    <option value="every 2 weeks">&nbsp; Every 2 weeks</option>
                                    <option value="daily">&nbsp; Daily</option>
                                </select>
                                <i className="fa-solid fa-angle-down"></i>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4">
                                <label className="mb-1" htmlFor="amount">*Amount</label>
                                <input type="text" className="form-control" id="amount"/>
                            </div>
                            <div className="col-md-4">
                                <label className="mb-1" htmlFor="currency">*Currency</label>
                                <input type="text" className="form-control" id="currency"/>
                            </div>
                            <div className="col-md-4">
                                <label className="mb-1" htmlFor="discount">*Discount</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="discount"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="mb-1" htmlFor="pay">*Payment url</label>
                        <input type="text" className="form-control" id="pay"/>
                    </div>
                    <div className="form-group">
                        <div className="back-sub">
                            <button className="back-btn me-4">Back</button>
                            <button className="create-btn">Create Subscription</button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}
export default AddSubscriptionPage