import React from "react";
import styles from './loadingScreen.module.css';
const LoadingScreen = () => {
    return(
        <div className={styles.loadingWrapper}>
            <div className={styles.loadingItem}>
                <img src="/assets/download.svg" />
            </div>
        </div>
    );
}
export default LoadingScreen;