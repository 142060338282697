// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".loadingScreen-module__loadingWrapper___10m9N {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background: #00000054;\n    z-index: 999;\n    overflow: hidden;\n}\n.loadingScreen-module__loadingWrapper___10m9N img {\n    width: 200px;\n    height: 200px;\n}\n.loadingScreen-module__loadingItem___2AlOn {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    text-align: center;\n    justify-content: center;\n}", "",{"version":3,"sources":["loadingScreen.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,qBAAqB;IACrB,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;AAC3B","file":"loadingScreen.module.css","sourcesContent":[".loadingWrapper {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background: #00000054;\n    z-index: 999;\n    overflow: hidden;\n}\n.loadingWrapper img {\n    width: 200px;\n    height: 200px;\n}\n.loadingItem {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    text-align: center;\n    justify-content: center;\n}"]}]);
// Exports
exports.locals = {
	"loadingWrapper": "loadingScreen-module__loadingWrapper___10m9N",
	"loadingItem": "loadingScreen-module__loadingItem___2AlOn"
};
module.exports = exports;
