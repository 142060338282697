import React, { useContext, useEffect, useState } from "react";
import {
  faArrowsUpDownLeftRight,
  faCircleXmark,
  faCopy,
  faFloppyDisk,
  faPencil,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import { networkService } from "../../../../config/axios";

const ElementIcons = ({ element, resizableDimensions, previousDimensions, setResizeDimensions }) => {
  const { setOpenAddElement, setEditElement, setOpenRemoveModal, setToolType } =
    useContext(EditorInfoContext);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if(previousDimensions.current) {
        const isUpdated =
            resizableDimensions?.top !== previousDimensions.current?.top ||
            resizableDimensions?.left !== previousDimensions.current?.left ||
            resizableDimensions?.width !== previousDimensions.current?.width ||
            resizableDimensions?.height !== previousDimensions.current?.height;
        setUpdated(isUpdated);
    }
  }, [Object.values(resizableDimensions || {})]);

  const handleCancel = () => {
    setResizeDimensions(prevDimensions => {
        return {
            ...prevDimensions,
            [element.id]: {
                ...prevDimensions[element.id],
                ...previousDimensions.current
            }
        }
    })
  }

  const handleSave = () => {
    const data = {
      id: element.id,
      rotate: element.rotate,
      flip: element.flip,
      doc_id: element.doc_id,
      tags: element.tags || "keep",
      geometry: {
        ...resizableDimensions,
        hide_beyond_page_edges: element.geometry.hide_beyond_page_edges,
        keep_proportions: element.geometry.keep_proportions,
        keep_within_page: element.geometry.keep_within_page,
      },
    };

    const url = `/docs/elements/${element?.id}.json`;
    networkService(url, "PATCH", data)
      .then((response) => {
        if(response.status = 200) {
            previousDimensions.current = {...resizableDimensions}
            setUpdated(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="action-icons-top d-flex">
        {updated ? (
          <>
            <FontAwesomeIcon icon={faCircleXmark} className="cancel" onClick={handleCancel} />
            <FontAwesomeIcon
              icon={faFloppyDisk}
              className="success-green"
              onClick={handleSave}
            />
          </>
        ) : (
          <>
            <FontAwesomeIcon
              icon={faTrashCan}
              className="cancel"
              onClick={(e) => {
                e.stopPropagation();
                setEditElement(element);
                setOpenRemoveModal(true);
              }}
            />
            <FontAwesomeIcon
              className="success-green"
              icon={faPencil}
              onClick={(e) => {
                e.stopPropagation();
                setEditElement(element);
                setOpenAddElement(true);
              }}
            />
            <FontAwesomeIcon
              icon={faCopy}
              className=""
              onClick={(e) => {
                e.stopPropagation();
                setToolType("Clone");
                setEditElement(element);
                setOpenAddElement(true);
              }}
            />
          </>
        )}
      </div>
      <div className="action-icons-bottom">
        <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
      </div>
    </>
  );
};

export default ElementIcons;
