import React, { useEffect, useState } from "react"
import DraggableComponent from "../../../CommonComponents/Draggable/DraggableComponent"
import '../../../../../assets/stylesheets/editorPage.css'
import EditorHeader from "./editorHeader/EditorHeader"
import { EditorInfoContext } from "../../../../utis/userInfoContext";
import AddElementModal from "../../../Modals/EditorModals/AddElementModal";
import ToolBoxHeading from "./editorToolbox/toolBox/ToolBoxHeading";
import EditorToolbox from "./editorToolbox/EditorToolbox"
import Viewer from "./pdfViewer/Viewer";
import PdfSidebar from "./editorSideBar/PdfSidebar";
import DeleteElementModal from "../../../Modals/EditorModals/DeleteElementModal";
import LightBoxModal from "../../../Modals/EditorModals/LightBoxModal";

const EditKdfPage = ({ editortype, ...props }) => {
    const [openSideSection, setOpenSideSection] = useState(false)
    const [zoomIndex, setZoomIndex] = useState({
        index: 3,
        value: '1'
    })
    const [docFiles, setDocFiles] = useState({})

    // edit page context states
    const [editEnabled, setEditEnabled] = useState(false);
    const [openAddElement, setOpenAddElement] = useState(false);
    const [toolType, setToolType] = useState('')
    const [rectangles, setRectangles] = useState([]);
    const [activeRectangleIndex, setActiveRectangleIndex] = useState(null);
    const [geo, setGeo] = useState(
        {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            keep_proportions: true,
            keep_within_page: true,
            page: 1
        }
    )
    const [editElement, setEditElement] = useState({})
    const [openRemoveModal, setOpenRemoveModal] = useState(false)
    const [elements, setElements] = useState({})
    const [pageNumber, setPageNumber] = useState(1)
    const [lightBoxList, setLightBoxList] = useState([])
    const [openLightBox, setOpenLightBox] = useState(false)
    const [editLightBox, setEditLightBox] = useState({})
    const [imageElement, setImageElement] = useState({})

    useEffect(() => {
        setDocFiles(window?.objInit?.doc_files)
    }, [])

    const { pdfFile, pdfImages, doc } = props;

    return (
        <EditorInfoContext.Provider value={{
            editEnabled: editEnabled,
            openAddElement: openAddElement,
            rectangles: rectangles,
            activeRectangleIndex: activeRectangleIndex,
            geo: geo,
            toolType,
            elements: elements,
            editElement: editElement,
            pageNumber: pageNumber,
            openRemoveModal,
            editLightBox,
            lightBoxList,
            imageElement,
            openLightBox,
            setOpenLightBox,
            setImageElement,
            setLightBoxList,
            setEditElement,
            setRectangles,
            setOpenAddElement,
            setEditEnabled,
            setActiveRectangleIndex,
            setGeo,
            setToolType,
            setElements,
            setOpenRemoveModal,
            setPageNumber,
            setEditLightBox
        }}>

            {openRemoveModal &&
                <DeleteElementModal
                    openDeleteModal={openRemoveModal}
                    closeModal={setOpenRemoveModal}
                    elemId={toolType === 'LightboxesToolbox' ? editLightBox?.id : editElement?.id}
                />
            }
            {/* addElementModal */}
            {openAddElement &&
                <AddElementModal
                    openAddElementModal={openAddElement}
                    closeModal={setOpenAddElement}
                    docData={pdfFile}
                    docFiles={docFiles}
                    totalPages={pdfFile?.num_pages}
                />
            }
            {/* lightBoxSection */}
            {
                openLightBox &&
                <LightBoxModal
                    openLightboxModal={openLightBox}
                    docFileId={pdfFile?.id}
                    docId={doc?.id}
                    setOpenLightBox={setOpenLightBox}
                />
            }

            <div className="viewer-bg">
                {/* editor page header */}
                <EditorHeader
                    pdfFile={pdfFile}
                    setOpenSideSection={setOpenSideSection}
                    openSideSection={openSideSection}
                    setZoomIndex={setZoomIndex}
                    zoomIndex={zoomIndex}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    docFiles={docFiles}
                />
                <div className="editor-content">
                    {/* sidebar section  */}
                    {openSideSection &&
                        <PdfSidebar
                            pdfImages={pdfImages}
                            setPageNumber={setPageNumber}
                            pageNumber={pageNumber}
                        />
                    }
                    {/* handle the pdf view component */}
                    {pdfFile &&
                        <Viewer
                            zoom={zoomIndex}
                            url={`${window.location.origin.toString()}${pdfFile?.file?.url}`}
                            docFileId={pdfFile?.id}
                            docId={doc?.id}
                            editortype={editortype}
                            docFiles={docFiles}
                        />
                    }

                    {/* hanlded the edit toolbox */}
                    {
                        editortype === 'editor' &&
                        <DraggableComponent header={<ToolBoxHeading />}>
                            <EditorToolbox doc={doc} pdfFile={pdfFile} />
                        </DraggableComponent>
                    }

                </div>
            </div>
        </EditorInfoContext.Provider >
    )
}
export default EditKdfPage