import React from "react"
const UniqueRecipientsPage = () => {
    return (
        <>
            <div className="header-content-main">
                <div className="glane-upper container row">
                    <div className="col-4 upper-one ">
                        <div className="d-flex">
                            <p className="hov-clr">LaserLINK:</p><img className="ms-1 me-1"
                                src="./assets/image/Kinetizine.png" />
                            <p className="hov-clr">nitin</p>
                        </div>
                        <p>LP=1 DS=3 Domain: powered-</p>
                        <p>by.kinetizine.com</p>
                    </div>
                    <div className="col-4 upper-two">
                        <div className="d-flex">
                            <p>KDF:
                                <p className="hov-clr">Demo 240822</p>
                            </p>
                        </div>
                        <p>Total Pages: 4</p>
                    </div>
                </div>

                <div className="visits-form mt-5 d-flex">
                    <div className="visits-one-data me-3 pe-3">
                        <input className="visits-input mb-3" placeholder="Email" />
                        <input className="visits-input" placeholder="Location:country,city,state,town" />

                        <div className="d-flex mt-3">
                            <button className="me-3 Apply-btn">Apply Filters</button>
                            <button className="reset-btn">Reset Filters</button>
                        </div>
                    </div>
                    <div className="visits-one-data me-3 pe-3">
                        <div className="d-flex align-items-center mt-2">
                            <p>At least </p><input className="edit-value-inp ms-1 me-1" value="2" />
                            <p>Pages viewed</p>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <p>At least </p><input className="edit-value-inp ms-1 me-1" value="2" />
                            <p>Mins </p><input className="edit-value-inp ms-1 me-1" value="2" />
                        </div>

                    </div>
                    <div className="visits-one-data me-3 pe-3">
                        <p>from date</p>
                        <div className="date-picker-container mb-3">
                            <input type="date" id="date" name="date" placeholder="From date" />
                        </div>
                        <p>To date</p>
                        <div className="date-picker-container ">
                            <input type="date" id="date" name="date" placeholder="From date" />
                        </div>

                    </div>
                    <div className="visits-one-data me-3 pe-3">
                        <div className="dropdown-duration ms-2">
                            <button className="dropdown-btn-duration1"><i
                                className="fa-solid fa-arrow-up me-3"></i>First name , Last name <span
                                    className="arrow-down-duration">▼</span></button>
                            <div className="dropdown-content-duration">
                                <a href="#">Duration <i className="fa-solid fa-arrow-down ms-1"></i></a>
                                <a href="#">Duration 2 <i className="fa-solid fa-arrow-up ms-1"></i></a>
                                <div className="divider-duration"></div>
                                <a href="#">Page 1 to 4 <i className="fa-solid fa-arrow-down ms-1"></i></a>
                                <a href="#">Page 4 to 1 <i className="fa-solid fa-arrow-up ms-1"></i></a>

                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <p>Engagement Threshold:</p><input className="edit-value-inp ms-1 me-1" value="2" />
                            <p>Seconds</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default UniqueRecipientsPage