import React from "react"
const PageByPage = () =>{
    return (
        <>
          <div className="header-content-main">
                <div className="glane-upper container row">
                    <div className="col-4 upper-one ">
                        <div className="d-flex">
                            <p className="hov-clr">LaserLINK:</p><img className="ms-1 me-1"
                                src="./assets/image/Kinetizine.png" />
                            <p className="hov-clr">nitin</p>
                        </div>
                        <p>LP=1 OI=3 Domain: delivered-</p>
                        <div className="d-flex align-items-center mt-2">
                            <p>Engagement Threshold: </p><input className="edit-value-inp ms-1 me-1" value="2" />
                            <p>seconds</p>
                        </div>
                    </div>
                    <div className="col-4 upper-two">
                        <div className="d-flex">
                            <p>KDF:
                            <p className="hov-clr">Demo 240822</p>
                            </p>
                        </div>
                        <p>Total Pages: 4</p>
                    </div>
                </div>

                <div className="lower-glance container">
                    <div className="col-5 lower-content mt-4">
                        <div className="d-flex align-items-center">
                            <p>Page: </p><input className="edit-value-inp ms-1 me-1" value="2" />
                            <p>/4</p>
                            <p className="ms-3">Sort by:</p>
                            <div className="dropdown-duration ms-2">
                                <button className="dropdown-btn-duration">Dropdown <i
                                        className="fa-solid fa-arrow-down ms-1"></i><span
                                        className="arrow-down-duration">▼</span></button>
                                <div className="dropdown-content-duration">
                                    <a href="#">Duration <i className="fa-solid fa-arrow-down ms-1"></i></a>
                                    <a href="#">Duration 2 <i className="fa-solid fa-arrow-up ms-1"></i></a>
                                    <div className="divider-duration"></div>
                                    <a href="#">Page 1 to 4 <i className="fa-solid fa-arrow-down ms-1"></i></a>
                                    <a href="#">Page 4 to 1 <i className="fa-solid fa-arrow-up ms-1"></i></a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-coloums">

                        
                        <div className=" score p-4 col-5">
                            <div className="score mb-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5>Page views</h5>
                                    <img src="./assets/image/green-medal.svg" />
                                </div>

                                <progress id="file" value="50" max="100"> 50% </progress>
                                <p>This page was viewed 2 out of 2 times</p>
                            </div>

                            <div className="score mb-2">
                                <h5>Exit page</h5>
                                <progress id="file" value="50" max="100"> 50% </progress>
                                <p>2 out of 2 people exited on this page</p>
                            </div>
                            <div className="score mb-2">
                                <h5>Duration</h5>
                                <progress id="file" value="50" max="100">50%</progress>

                                <p>--:00:42 / --:00:49</p>
                            </div>
                            <div className="duration-lower-table mt-3">
                                <h6>Interactive Elements Activated</h6>
                                <p className="element">Element 405398</p>
                                <p className="element">Element 405398</p>
                                <p className="element">Element 405398</p>
                                <p className="element">Element 405398</p>

                            </div>
                        </div>

                        <div className="  col-5">
                            <div><p className="mb-2">page preview</p></div>
                            <div className="side-duration-img duration-img">
                                <img src="./assets/image/download (1).png" />
                            <div className="pagenition mt-3"><p>1/4</p></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default PageByPage