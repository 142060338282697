// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".KdfListingPage-module__listingContainer___2B_1G {\n    max-width: 800px;\n    margin: 30px auto 0px;\n}", "",{"version":3,"sources":["KdfListingPage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,qBAAqB;AACzB","file":"KdfListingPage.module.css","sourcesContent":[".listingContainer {\n    max-width: 800px;\n    margin: 30px auto 0px;\n}"]}]);
// Exports
exports.locals = {
	"listingContainer": "KdfListingPage-module__listingContainer___2B_1G"
};
module.exports = exports;
