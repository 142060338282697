import React from "react"
import ModalLayout from "./ModalLayout"

const AddOrganisation = ({ openOrgModal, setOpenOrgModal }) => {
    const AddOrganisationBody = () => {
        return (
            <>
                <div className="row org-name-inp">
                    <div className="col-sm-12 mb-3">
                        <div className="text-area-inp">
                            <label className="mb-2">Organization name</label>
                            <input className="org-inp org-search" placeholder="Organization name" />
                        </div>
                    </div>

                </div>
                <div className="radio-btn">
                    <div className="row">
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="agency" name="organisation" value="agency" />
                            <label htmlFor="agency">Agency B2B</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="B2C" name="organisation" value="B2C" />
                            <label htmlFor="B2C">Agency B2C</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="B2C" name="organisation" value="real" />
                            <label htmlFor="real">Real Estate</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="consumer" name="organisation" value="consumer" />
                            <label htmlFor="consumer">Consumer Goods</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="financial" name="organisation" value="financial" />
                            <label htmlFor="financial">Financial</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="health" name="organisation" value="health" />
                            <label htmlFor="health">Health Care</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="automotive" name="organisation" value="automotive" />
                            <label htmlFor="automotive">Automotive</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="services" name="organisation" value="services" />
                            <label htmlFor="services">Services</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="technology" name="organisation" value="technology" />
                            <label htmlFor="technology">Technology</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="law" name="organisation" value="law" />
                            <label htmlFor="law">Law firms</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="pharma" name="organisation" value="pharma" />
                            <label htmlFor="pharma">Pharma</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="alcohol" name="organisation" value="alcohol" />
                            <label htmlFor="alcohol">Alcohol</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="direct" name="organisation" value="direct" />
                            <label htmlFor="direct">Direct Mail</label>
                        </div>
                        <div className="col-md-6 d-flex align items center mb-3">
                            <input type="radio" id="consulting" name="organisation" value="consulting" />
                            <label htmlFor="consulting">Consulting</label>
                        </div>
                    </div>

                </div>
                <div className="org-fields">
                    <form action="">
                        <div className="row">
                            <div className="col-md-6 d-flex align items center mb-3">
                                <div className="form-group">
                                    <label className="mb-1" htmlFor="trial">Trial Period (in days)</label>
                                    <input type="text" className="form-control" id="trial" />
                                </div>
                            </div>
                            <div className="col-md-6 d-flex align items center mb-3">
                                <div className="form-group">
                                    <label className="mb-1" htmlFor="payment">Payment threshold (in days)</label>
                                    <input type="text" className="form-control place-color " id="payment"
                                        placeholder="Payment threshold (in days)"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 d-flex align items center mb-3">
                                <div className="form-group">
                                    <label className="mb-1" htmlFor="lic">Number of licenses</label>
                                    <input type="text" className="form-control place-color " id="lic" placeholder="Number of licenses" />
                                </div>
                            </div>
                            <div className="col-md-6 d-flex align items center mb-3">
                                <div className="form-group">
                                    <label className="mb-1" htmlFor="lic-th">Licenses threshold</label>
                                    <input type="text" className="form-control place-color" id="lic-th" placeholder="Licenses threshold" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="Continue-btn mt-2">
                    <button>Save Changes</button>
                </div>
            </>
        )
    }
    return (
        <ModalLayout
            openModal={openOrgModal}
            title={'Organization settings'}
            children={<AddOrganisationBody />}
            closeModal={setOpenOrgModal}
            modalWidth={'600px'}
        />
    )
}
export default AddOrganisation
